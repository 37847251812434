import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';
import { ContentWithNavigation } from 'layouts/wrappers/ContentWithNavigationWrapper';

import { PartnersTable } from 'features/Partners';
import { PartnersZoneNavigation } from 'features/PartnersZone';

export const PartnersPage: React.FC = () => (
	<Layout
		header={<Header />}
		main={
			<ContentWithNavigation
				tabsNavigation={<PartnersZoneNavigation />}
				content={<PartnersTable />}
			/>
		}
	/>
);
