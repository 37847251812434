import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { rootReducer } from './reducer/rootReducer';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epic/rootEpic';

const epicMiddleware = createEpicMiddleware();

const AppStore = configureStore({
	reducer: rootReducer(),
	middleware: [...getDefaultMiddleware(), epicMiddleware],
	devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});

epicMiddleware.run(rootEpic);

export type State = ReturnType<typeof AppStore.getState>;
export type AppDispatch = typeof AppStore.dispatch;
export default AppStore;
