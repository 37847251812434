export interface HeadCell {
	id: string;
	numeric: boolean;
	disablePadding: boolean;
	label: React.ReactNode;
}

export type OrderType = OrderValues;

export enum OrderValues {
	Asc = 'asc',
	Desc = 'desc',
}

export type ObjectValType = string | number | boolean | string[] | {};

export const RowsPerPageOptions = [10, 25, 50];
