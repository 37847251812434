import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuList from '@mui/material/MenuList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { selectLocale } from 'features/LanguageSelector';
import { UserStateInterface, setUser } from 'features/UserCreateEdit';
import { http } from 'core/ApiClient';
import { Error } from 'core/types';
import { Routes } from 'types/types';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { UserViewEditModal } from './UserViewEditModal';
import { ProgressBox } from 'components/ProgressIndicator';
import { PartnersListAPI, setPartnerCompanyNames } from 'features/Partners';
import { ListPaginationConfig } from 'core/constants';
import { UserDeleteModal } from 'features/Users';

interface Props {
	userId: string;
	handleUserDelete: (userId: string) => void;
	handleUsersRefetch: (value: boolean) => void;
	isPrimary: boolean;
}

export const UsersTableMoreActions: React.FC<Props> = ({
	userId,
	handleUserDelete,
	handleUsersRefetch,
	isPrimary,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [apiError, setApiError] = useState<Record<string, string> | null>();
	const [loading, setLoading] = useState<boolean>(false);
	const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);

	const handleUserDeleteModalOpen = () => setIsUserDeleteModalOpen(true);
	const handleUserDeleteModalClose = () => setIsUserDeleteModalOpen(false);

	const open = Boolean(anchorEl);
	const dispatch = useDispatch();

	const [openModal, setOpenModal] = useState(false);
	const locale = useSelector(selectLocale);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleModalOpen = async (id: string | null) => {
		handleClose();
		setOpenModal(true);
		setLoading(true);
		handleUsersRefetch(false);

		const fetchUser = async (fetchUserId: string | null) => {
			if (!fetchUserId) return;
			await http
				.get<UserStateInterface>(`${Routes.Users}/${fetchUserId}`)
				.then((res) => {
					dispatch(setUser(res));
				});
		};

		const fetchPartners = async () => {
			await http
				.get<PartnersListAPI>(
					`${Routes.Partners}?groups[]=select&itemsPerPage=10`,
					ListPaginationConfig
				)
				.then((res) => {
					dispatch(setPartnerCompanyNames(res.data));
				});
		};

		if (id) {
			await fetchPartners()
				.then(() =>
					fetchUser(id).then(() => {
						setLoading(false);
					})
				)
				.catch((e: Error) => {
					setApiError(
						resolveApiErrors(e.cause.violations, {}, locale).globalErrors
					);
					setLoading(false);
				});
		} else {
			await fetchPartners()
				.then(() => {
					setLoading(false);
				})
				.catch((e: Error) => {
					setApiError(
						resolveApiErrors(e.cause.violations, {}, locale).globalErrors
					);
					setLoading(false);
				});
		}
	};

	if (apiError && !loading) {
		return (
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={apiError && !loading}
			/>
		);
	}

	return (
		<>
			<IconButton
				aria-label="more"
				id="actions-button"
				aria-controls={open ? 'menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="menu"
				MenuListProps={{
					'aria-labelledby': 'actions-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuList>
					<MenuItem onClick={() => void handleModalOpen(userId)}>
						<ListItemIcon>
							<EditIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage id="my.company.view.edit.users.table.more.actions.edit" />
						</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleClose();
							handleUserDeleteModalOpen();
						}}
					>
						<ListItemIcon>
							<DeleteForeverIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage id="my.company.view.edit.users.table.more.actions.delete" />
						</ListItemText>
					</MenuItem>
				</MenuList>
			</Menu>
			<UserDeleteModal
				userId={userId}
				isOpen={isUserDeleteModalOpen}
				handleClose={handleUserDeleteModalClose}
				onDelete={(id) => handleUserDelete(id)}
				isPrimary={isPrimary}
			/>
			{loading ? (
				<ProgressBox />
			) : apiError && !loading ? (
				<ApiErrorsSnackbar
					messages={apiError}
					type="error"
					isOpen={apiError && !loading}
				/>
			) : (
				<UserViewEditModal
					isOpen={openModal}
					handleClose={() => {
						setOpenModal(false);
						handleUsersRefetch(true);
					}}
				/>
			)}
		</>
	);
};
