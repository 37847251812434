import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';

import { ContentWithNavigation } from 'layouts/wrappers/ContentWithNavigationWrapper';
import { PartnersZoneNavigation } from 'features/PartnersZone';

export const AdminsDashboardPage: React.FC = () => (
	<Layout
		header={<Header />}
		main={
			<ContentWithNavigation
				tabsNavigation={<PartnersZoneNavigation />}
				content={<div>OOPS.... Dashboard maintenance</div>}
			/>
		}
	/>
);
