import { FormattedMessage } from 'react-intl';
import { HeadCell } from 'components/Table';

export const editRoute = '/partner-edit';

export const partnersTableHeadCells: HeadCell[] = [
	// {
	// 	id: 'id',
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: <FormattedMessage id="partners.table.head.cell.label.id" />,
	// },
	{
		id: 'partnerName',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.table.head.cell.label.partner.name" />
		),
	},
	{
		id: 'website',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partners.table.head.cell.label.website" />,
	},
	{
		id: 'contactPerson',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.table.head.cell.label.contact.person" />
		),
	},
	{
		id: 'contactEmail',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.table.head.cell.label.contact.email" />
		),
	},
	{
		id: 'numberOfPurchases',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.table.head.cell.label.number.of.purchases" />
		),
	},
	{
		id: 'createdAt',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partners.table.head.cell.label.created.at" />,
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: true,
		label: '',
	},
];
