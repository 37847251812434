import * as React from 'react';
import { useFormikContext, useField } from 'formik';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { IntlShape } from 'react-intl';
import { getTranslatedState } from 'core/helper';
export type Option = {
	id: string;
	name: string;
};

interface Props {
	name: string;
	value: string;
	helperText?: string;
	onChange: (value: string | null) => void;
	options: string[];
	label: string;
	intl: IntlShape;
}

export const StateSimpleSelect: React.FC<Props> = ({
	name,
	helperText,
	onChange,
	value,
	options,
	label,
	intl,
	...rest
}) => {
	const formikProps = useFormikContext();

	const [, meta] = useField(name);

	const error = !!(meta && meta.touched && meta.error);
	const helperT = error ? meta.error : helperText;

	return (
		<Box>
			<FormControl fullWidth>
				<Select
					error={error}
					size="small"
					displayEmpty
					id="demo-simple-select"
					value={value}
					inputProps={{ 'aria-label': 'Without label' }}
					renderValue={(selected) => {
						if (!selected) {
							return <>{label}</>;
						}
						return getTranslatedState(selected, intl);
					}}
					onChange={(val) => {
						onChange(val.target.value);
						formikProps.setFieldValue(name, val.target.value);
					}}
					{...rest}
				>
					{Object.values(options).map((option) => (
						<MenuItem key={option} value={option}>
							{getTranslatedState(option, intl)}
						</MenuItem>
					))}
				</Select>
				{helperT && <FormHelperText>{helperT}</FormHelperText>}
			</FormControl>
		</Box>
	);
};
