import type { State } from 'redux-store/Store';

export const selectPartner = (state: State) => state.partner.partner;
export const selectCommissionType = (state: State) =>
	state.partner.partner.commission.type;
export const selectCountry = (state: State) =>
	state.partner.partner.address.country;
export const selectAuthenticationToken = (state: State) =>
	state.partner.authenticationToken;
export const selectBankCountry = (state: State) =>
	state.partner.partner.bankDetails.country;
export const selectPartnerId = (state: State) => state.partner.partner.id;
