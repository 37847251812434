import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertProps } from '../types';

export const SnackbarAlert: React.FC<AlertProps> = ({
	isOpen,
	type,
	message,
	handleClose,
}) => (
	<Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
		<Alert severity={type} sx={{ width: '100%' }}>
			{message}
		</Alert>
	</Snackbar>
);
