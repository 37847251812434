import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from 'types';

const useStyles = makeStyles((theme: Theme) => {
	return {
		card: {
			boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
			position: 'relative',
			border: `1px solid ${theme.palette.primary.main}`,
			padding: theme.spacing(1, 2),
			width: '100%',
			margin: theme.spacing(2),
		},
		title: {
			padding: theme.spacing(1),
			margin: theme.spacing(1, 0),
		},

		detail: {
			padding: theme.spacing(1),
			margin: theme.spacing(2, 0, 2, 0),
		},

		boxicon: {
			position: 'absolute',
			zIndex: 1,
			top: -29,
			left: 12,
		},
	};
});

interface Props {
	title: React.ReactElement;
	iconPerformance: JSX.Element;
	statsNode?: React.ReactNode;
	navigateTitle?: React.ReactElement;
	link?: PageRoutes;
}

export const StyledCard: React.FC<Props> = ({
	iconPerformance,
	statsNode,
	navigateTitle,
	title,
	link,
}) => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Paper className={classes.card}>
			<Grid container>
				<Grid className={classes.boxicon}>
					<Avatar
						sx={{
							backgroundColor: 'primary.main',
							height: 56,
							width: 56,
						}}
					>
						{iconPerformance}
					</Avatar>
				</Grid>

				<Grid container className={classes.title} justifyContent="center">
					<Typography variant="h4">{title}</Typography>
				</Grid>
			</Grid>
			<Grid container>
				{statsNode && (
					<Grid container justifyContent="center" className={classes.detail}>
						<Typography variant="h6" align="left">
							{statsNode}
						</Typography>
					</Grid>
				)}
			</Grid>
			{link && (
				<>
					<Divider style={{ color: '#000' }} />
					<Grid container>
						<Grid item xs textAlign="end" className={classes.title}>
							<Typography>
								<Button
									endIcon={<ArrowForwardIcon />}
									variant="text"
									color="primary"
									onClick={() => navigate(`/${link}`)}
								>
									{navigateTitle}
								</Button>
							</Typography>
						</Grid>
					</Grid>
				</>
			)}
		</Paper>
	);
};
