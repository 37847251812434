import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { SnackbarAlertBox } from '../types';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

export const ApiErrorsSnackbar: React.FC<SnackbarAlertBox> = ({
	title,
	messages,
	type,
	isOpen,
	handleClose,
}) => (
	<Snackbar autoHideDuration={15000} open={isOpen} onClose={handleClose}>
		<Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
			{title && <AlertTitle>{title}</AlertTitle>}
			{messages &&
				!!Object.keys(messages).length &&
				Object.keys(messages).map((key) => (
					<Box key={key}>{messages[key]}</Box>
				))}
		</Alert>
	</Snackbar>
);
