import secureLocalStorage from 'react-secure-storage';
import jwt_decode from 'jwt-decode';

import { JwtToken, JwtTokenType } from 'core/types';
import { ROLE } from 'types/types';

export const getDecodedJwtToken = (): JwtTokenType => {
	const tokenDecoder = (): JwtTokenType | null => {
		try {
			const userToken = secureLocalStorage.getItem(JwtToken.Token);

			return userToken ? jwt_decode(userToken.toString()) : null;
		} catch (e) {
			return null;
		}
	};
	const decodedToken = tokenDecoder();

	return {
		exp: decodedToken?.exp || null,
		iat: decodedToken?.iat || null,
		roles: decodedToken?.roles || null,
		username: decodedToken?.username || null,
	};
};

export const useAuthenticated = (exp: number | null): boolean => {
	const now = Math.round(Date.now() / 1000);

	if (!exp) {
		return false;
	} else {
		return exp > now;
	}
};

export const getLastUrlItem = (url: string): string =>
	url ? url.substring(url.lastIndexOf('/') + 1) : '';

export const isUserHasAccess = (
	userRoles: Array<ROLE> | null,
	requiredRoles: Array<ROLE>,
	strict?: boolean
): boolean => {
	if (!userRoles) return false;
	if (strict) {
		return requiredRoles.every((rol) =>
			userRoles.every((userRol) => userRol === rol)
		);
	} else {
		return requiredRoles.some((rol) =>
			userRoles.some((userRol) => userRol === rol)
		);
	}
};

export const isTabHasErrors = (
	fieldsInTab: string[],
	errors: Record<string, string>,
	touchedFields: Record<string, boolean>
): boolean => {
	if (!errors) return false;
	let hasError = false;
	fieldsInTab.map((fieldName: string) => {
		if (touchedFields[fieldName] && errors[fieldName]) {
			hasError = true;
			return;
		}
	});
	return hasError;
};

const getSearchParams = (values: Record<string, string>) => {
	return Object.keys(values)
		.filter((key) => values[key] !== '')
		.reduce((obj, key) => {
			return Object.assign(obj, {
				[key]: values[key],
			});
		}, {});
};

const fieldsMapper = (
	searchObj: Record<string, string>,
	fields: Record<string, string>
) => {
	return Object.keys(searchObj).reduce((obj, key) => {
		return Object.assign(obj, {
			[fields[key]]: searchObj[key],
		});
	}, {});
};

export const getOrderByUrl = (
	orderBy: string,
	order: string,
	fields: Record<string, string>
) => {
	if (!orderBy || !order) return '';

	return `&order[${fields[order]}]=${orderBy}`;
};

export const getSearchFiltersUrl = (
	filters: Record<string, string>,
	fields: Record<string, string>
) => {
	const searchParams = getSearchParams(filters);

	return Object.keys(searchParams).length
		? `&${new URLSearchParams(fieldsMapper(searchParams, fields)).toString()}`
		: '';
};

export const getFetchParams = (
	filters: Record<string, string>,
	order: string,
	orderBy: string,
	page: number,
	itemsPerPage: number,
	fields: Record<string, string>
) => {
	const activeFilters = getSearchFiltersUrl(filters, fields);
	const activeOrder = getOrderByUrl(orderBy, order, fields);

	return `?itemsPerPage=${itemsPerPage}&page=${page}${activeFilters}${activeOrder}`;
};
