import { ClaimsTableFilterFieldsProps, ClaimsTableFilterFields } from './types';
import { FilterType } from 'components/Filters';
import { IntlShape } from 'react-intl';

export const generateFilters = (
	values: ClaimsTableFilterFieldsProps,
	intl: IntlShape
): FilterType<ClaimsTableFilterFields>[] => [
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.claims.head.cell.label.status',
					}),
				}
			),
			fieldName: ClaimsTableFilterFields.state,
			defaultValue: values.state,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.claims.head.cell.label.skyKnight',
					}),
				}
			),
			fieldName: ClaimsTableFilterFields.purchaseCode,
			defaultValue: values.purchaseCode,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.claims.head.cell.label.disrupted.departure',
					}),
				}
			),
			fieldName: ClaimsTableFilterFields.departureAirport,
			defaultValue: values.departureAirport,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.claims.head.cell.label.disrupted.arrival',
					}),
				}
			),
			fieldName: ClaimsTableFilterFields.arrivalAirport,
			defaultValue: values.arrivalAirport,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.claims.head.cell.label.disrupted.details',
					}),
				}
			),
			fieldName: ClaimsTableFilterFields.details,
			defaultValue: values.details,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.claims.head.cell.label.customer',
					}),
				}
			),
			fieldName: ClaimsTableFilterFields.fullName,
			defaultValue: values.fullName,
		},
	},
	{
		type: 'Date',
		dateFilter: {
			startFieldName: ClaimsTableFilterFields.createdAtStart,
			endFieldName: ClaimsTableFilterFields.createdAtEnd,
			startLabel: intl.formatMessage({
				id: 'filters.cell.label.start.date',
			}),
			endLabel: intl.formatMessage({
				id: 'filters.cell.label.end.date',
			}),
			startDefaultValue: values.createdAtStart,
			endDefaultValue: values.createdAtEnd,
		},
	},
	{ type: 'Empty' },
];
