import React from 'react';
import { useField, FieldProps } from 'formik';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import type { OverridableStringUnion } from '@mui/types';
import IconButton from '@mui/material/IconButton';

interface Props {
	name: string;
	helperText?: string;
	endIcon: JSX.Element;
	handleIconClick?: () => void;
	size?: OverridableStringUnion<'small' | 'medium'>;
}

export const FormTextFieldWithClickableIcon: React.FC<Props & FieldProps> = ({
	name,
	helperText,
	endIcon,
	handleIconClick,
	size = 'small',
	...otherProps
}) => {
	const [field, meta] = useField(name);

	const configTextField = {
		fullWidth: true,
		...field,
		...otherProps,
	};

	const error = !!(meta && meta.touched && meta.error);
	const helperT = error ? meta.error : helperText;

	return (
		<TextField
			error={error}
			helperText={helperT}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={handleIconClick}>{endIcon}</IconButton>
					</InputAdornment>
				),
			}}
			size={size}
			{...configTextField}
		/>
	);
};
