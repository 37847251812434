import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ProgressBox } from 'components/ProgressIndicator';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Routes, ROLE } from 'types/types';
import { useFetch } from 'core/hooks';

import { PageRoutes } from 'types';
import { PurchaseTabs } from './PurchaseTabs';
import { PurchaseEntity } from 'features/Purchases';
import { ApiErrorsSnackbar } from 'features/ApiErrors';
import { ShowForRole } from 'utils/ShowForRole';
import { setPurchaseEntity } from '../reducer/purchaseReducer';

export const PurchaseView: React.FC = () => {
	const dispatch = useDispatch();
	const { purchaseId } = useParams();

	const purchase = useFetch<PurchaseEntity>(
		`${Routes.Purchases}/${purchaseId || ''}`
	);

	useEffect(() => {
		if (purchase.data) {
			dispatch(setPurchaseEntity(purchase.data));
		}
	}, [purchase.isLoading]);

	if (!purchase.isLoading && purchase.error) {
		return (
			<ApiErrorsSnackbar
				messages={purchase.error}
				type="error"
				isOpen={!purchase.isLoading && !!purchase.error}
			/>
		);
	}

	return (
		<ShowForRole roles={[ROLE.Admin, ROLE.User]}>
			{purchase.isLoading ? (
				<ProgressBox />
			) : (
				<Box m={2} sx={{ background: 'white' }}>
					<Box p={2}>
						<Link to={`/${PageRoutes.Purchases}`}>
							<Button startIcon={<ArrowBackIcon fontSize="small" />}>
								<FormattedMessage id="navigation.back.to.purchases.list" />
							</Button>
						</Link>
					</Box>
					<PurchaseTabs />
				</Box>
			)}
		</ShowForRole>
	);
};
