import type { State } from 'redux-store/Store';

export const selectPagination = (state: State) => state.claims.pagination;
export const selectCurrentPage = (state: State) =>
	state.claims.pagination.currentPage;
export const selectItemsPerPage = (state: State) =>
	state.claims.pagination.itemsPerPage;
export const selectTotalItems = (state: State) =>
	state.claims.pagination.totalItems;
export const selectFilterValues = (state: State) => state.claims.filters;
