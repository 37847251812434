import {
	createSlice,
	createEntityAdapter,
	PayloadAction,
} from '@reduxjs/toolkit';
import { RowsPerPageOptions } from 'components/Table';

import {
	CountryStateInterface,
	CountryAttribute,
	CountriesPagination,
} from '../types';

export const countryAdapter = createEntityAdapter<CountryStateInterface>();

export const countrySlice = createSlice({
	name: 'country',
	initialState: countryAdapter.getInitialState({
		pagination: {
			currentPage: 1,
			itemsPerPage: RowsPerPageOptions[0],
			totalItems: 1,
		},
	}),
	reducers: {
		setCountries: (state, action: PayloadAction<CountryAttribute[]>) => {
			const countries = action.payload.map((country: CountryAttribute) => ({
				id: country.attributes.code,
				title: country.attributes.title,
				phoneCode: country.attributes.phoneCode,
			}));
			countryAdapter.setAll(state, countries);
		},
		addCountries: (state, action: PayloadAction<CountryAttribute[]>) => {
			const countries = action.payload.map((country: CountryAttribute) => ({
				id: country.attributes.code,
				title: country.attributes.title,
				phoneCode: country.attributes.phoneCode,
			}));
			countryAdapter.addMany(state, countries);
		},
		setPagination: (state, action: PayloadAction<CountriesPagination>) => {
			state.pagination.currentPage = action.payload.currentPage;
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
			state.pagination.totalItems = action.payload.totalItems;
		},
		setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
			state.pagination.currentPage = action.payload.currentPage;
		},
	},
});

export const { setCountries, addCountries, setPagination, setCurrentPage } =
	countrySlice.actions;

export default countrySlice.reducer;
