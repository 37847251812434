import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { PageRoutes } from 'types';
import { ClaimTabs } from './ClaimTabs';
import { useFetch } from 'core/hooks';
import { ClaimEntity } from 'features/Claims';
import { Routes, ROLE } from 'types/types';
import { ApiErrorsSnackbar } from 'features/ApiErrors';
import { ShowForRole } from 'utils/ShowForRole';
import { ProgressBox } from 'components/ProgressIndicator';
import { setClaimEntity } from '../reducer/claimStatusReducer';

export const ClaimView: React.FC = () => {
	const dispatch = useDispatch();
	const { claimId } = useParams();

	const claim = useFetch<ClaimEntity>(`${Routes.Claims}/${claimId || ''}`);

	useEffect(() => {
		if (claim.data) {
			dispatch(setClaimEntity(claim.data));
		}
	}, [claim.isLoading]);

	if (!claim.isLoading && claim.error) {
		return (
			<ApiErrorsSnackbar
				messages={claim.error}
				type="error"
				isOpen={!claim.isLoading && !!claim.error}
			/>
		);
	}
	return (
		<ShowForRole roles={[ROLE.Admin, ROLE.User]}>
			{claim.isLoading ? (
				<ProgressBox />
			) : (
				<Box m={2} sx={{ background: 'white' }}>
					<Box p={2}>
						<Link to={`/${PageRoutes.Claims}`}>
							<Button startIcon={<ArrowBackIcon fontSize="small" />}>
								<FormattedMessage id="navigation.back.to.claims.list" />
							</Button>
						</Link>
					</Box>
					<ClaimTabs />
				</Box>
			)}
		</ShowForRole>
	);
};
