import React from 'react';
import { ClaimsStats } from '../types';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { SimpleCard } from 'components/Cards/SimpleCard';
import { getStandardAmount } from 'core/helper';
interface Props {
	claimsStats: ClaimsStats | null;
}

export const ClaimsStatsNode: React.FC<Props> = ({ claimsStats }) => {
	if (!claimsStats) return <></>;

	return (
		<Grid
			container
			spacing={{ xs: 2, md: 3 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
		>
			<Grid item xs={4} sm={4} md={6}>
				<SimpleCard
					title={<FormattedMessage id="dashboard.card.claim.count" />}
					secondary={claimsStats.count}
				/>
			</Grid>
			{/* <Grid item xs={2} sm={2} md={3}>
				<SimpleCard
					title={<FormattedMessage id="dashboard.card.claim.median.amount" />}
					secondary={getStandardAmount(claimsStats.medianAmount)}
				/>
			</Grid> */}

			<Grid item xs={4} sm={4} md={6}>
				<SimpleCard
					title={<FormattedMessage id="dashboard.card.claim.payout.amount" />}
					secondary={getStandardAmount(claimsStats.payoutAmount)}
				/>
			</Grid>
			{/* <Grid item xs={2} sm={2} md={3}>
				<SimpleCard
					title={<FormattedMessage id="dashboard.card.claim.submitted.count" />}
					secondary={claimsStats.submittedIndex}
				/>
			</Grid> */}
		</Grid>
	);
};
