import type { State } from 'redux-store/Store';

import { usersAdapter } from './reducer/usersReducer';

const usersSelectors = usersAdapter.getSelectors((state: State) => state.users);

export const selectAllUsers = usersSelectors.selectAll;

export const selectPagination = (state: State) => state.users.pagination;
export const selectCurrentPage = (state: State) =>
	state.users.pagination.currentPage;
export const selectItemsPerPage = (state: State) =>
	state.users.pagination.itemsPerPage;
export const selectTotalItems = (state: State) =>
	state.users.pagination.totalItems;
export const selectFilterValues = (state: State) => state.users.filters;
