import type { State } from 'redux-store/Store';

import { partnersAdapter } from './reducer/partnersReducers';
import { PartnerState } from './types';

const partnersSelectors = partnersAdapter.getSelectors(
	(state: State) => state.partners
);
export const selectAllPartners = partnersSelectors.selectAll;

export const selectAllPartnersByState = (
	state: State,
	partnerState: PartnerState
) => {
	const allPartners = selectAllPartners(state);
	return allPartners.filter((p) => p.state === partnerState);
};

export const selectPartnersNames = (state: State) => {
	const partners = selectAllPartners(state);
	if (!partners) return null;

	return partners.map((p) => ({ id: p.id, name: p.company.name }));
};

export const selectPagination = (state: State) => state.partners.pagination;
export const selectFilterValues = (state: State) => state.partners.filters;
