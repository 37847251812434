import type { State } from 'redux-store/Store';

export const selectCountry = (state: State) =>
	state.myCompany.myPartner.address.country;
export const selectAuthenticationToken = (state: State) =>
	state.myCompany.authenticationToken;
export const selectBankCountry = (state: State) =>
	state.myCompany.myPartner.bankDetails.country;
export const selectMyPartner = (state: State) => state.myCompany.myPartner;
export const selectMyPartnerId = (state: State) => state.myCompany.myPartner.id;
export const selectCommissionType = (state: State) =>
	state.myCompany.myPartner.commission.type;
