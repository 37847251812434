import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { getUrlIfMatch } from '../helper';
import { PageRoutes } from 'types';
import { ROLE } from 'types/types';
import { useShowForRole } from 'hooks/useShowForRole';
import { ProgressBox } from 'components/ProgressIndicator';

export const PartnersZoneNavigation: React.FC = () => {
	const intl = useIntl();
	const location = useLocation();
	const navigate = useNavigate();
	const isVisibleForAdmin = useShowForRole([ROLE.Admin]);
	const isVisibleForUser = useShowForRole([ROLE.User], true);
	const isVisibleForUserOrAdmin = isVisibleForAdmin || isVisibleForUser;
	const hideNotImplemented = false;

	const tabId = useMemo(() => {
		const visibleTabs = [
			isVisibleForUser && PageRoutes.Dashboard,
			isVisibleForAdmin && hideNotImplemented && PageRoutes.AdminDashboard,
			isVisibleForAdmin && PageRoutes.Partners,
			isVisibleForAdmin && PageRoutes.Users,
			isVisibleForUserOrAdmin && PageRoutes.Purchases,
			isVisibleForUserOrAdmin && PageRoutes.Claims,
			isVisibleForUserOrAdmin && PageRoutes.Invoices,
			isVisibleForUser && PageRoutes.MyCompany,
		].filter((tab) => !!tab) as string[];
		return getUrlIfMatch(location?.pathname, visibleTabs);
	}, [location?.pathname, isVisibleForAdmin, isVisibleForUser]);

	if (!tabId) {
		return <ProgressBox />;
	}
	return (
		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<Tabs value={tabId} aria-label="partners table" variant="scrollable">
				{isVisibleForAdmin && hideNotImplemented && (
					<Tab
						id={PageRoutes.AdminDashboard}
						value={PageRoutes.AdminDashboard}
						label={intl.formatMessage({
							id: 'partners.tab.admin.dashboard.title',
						})}
						onClick={() => navigate(`/${PageRoutes.AdminDashboard}`)}
					/>
				)}
				{isVisibleForUser && (
					<Tab
						id={PageRoutes.Dashboard}
						value={PageRoutes.Dashboard}
						label={intl.formatMessage({ id: 'partners.tab.dashboard.title' })}
						onClick={() => navigate(`/${PageRoutes.Dashboard}`)}
					/>
				)}

				{isVisibleForAdmin && (
					<Tab
						id={PageRoutes.Partners}
						value={PageRoutes.Partners}
						label={intl.formatMessage({ id: 'partners.tab.partners.title' })}
						onClick={() => navigate(`/${PageRoutes.Partners}`)}
					/>
				)}
				{isVisibleForAdmin && (
					<Tab
						id={PageRoutes.Users}
						value={PageRoutes.Users}
						label={intl.formatMessage({ id: 'partners.tab.users.title' })}
						onClick={() => navigate(`/${PageRoutes.Users}`)}
					/>
				)}
				{isVisibleForUserOrAdmin && (
					<Tab
						id={PageRoutes.Purchases}
						value={PageRoutes.Purchases}
						label={intl.formatMessage({ id: 'partners.tab.purchases.title' })}
						onClick={() => navigate(`/${PageRoutes.Purchases}`)}
					/>
				)}
				{isVisibleForUserOrAdmin && (
					<Tab
						id={PageRoutes.Claims}
						value={PageRoutes.Claims}
						label={intl.formatMessage({ id: 'partners.tab.claims.title' })}
						onClick={() => navigate(`/${PageRoutes.Claims}`)}
					/>
				)}

				{isVisibleForUser && (
					<Tab
						id={PageRoutes.MyCompany}
						value={PageRoutes.MyCompany}
						label={intl.formatMessage({ id: 'partners.tab.my.company.title' })}
						onClick={() => navigate(`/${PageRoutes.MyCompany}`)}
					/>
				)}
				{isVisibleForUserOrAdmin && (
					<Tab
						id={PageRoutes.Invoices}
						value={PageRoutes.Invoices}
						label={intl.formatMessage({ id: 'partners.tab.invoices.title' })}
						onClick={() => navigate(`/${PageRoutes.Invoices}`)}
					/>
				)}
			</Tabs>
		</Box>
	);
};
