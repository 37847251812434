import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { selectClaimContactDetails } from '../selectors';

export const Customer: React.FC = () => {
	const intl = useIntl();
	const contactDetails = useSelector(selectClaimContactDetails);

	return (
		<FormFieldsContainer>
			<TextField
				disabled
				label={intl.formatMessage({
					id: 'partner.claims.view.tab.customer.first.name',
				})}
				value={contactDetails.firstName}
			/>
			<TextField
				disabled
				label={intl.formatMessage({
					id: 'partner.claims.view.tab.customer.last.name',
				})}
				value={contactDetails.lastName}
			/>
			<TextField
				disabled
				label={intl.formatMessage({
					id: 'partner.claims.view.tab.customer.email',
				})}
				value={contactDetails.email}
			/>
			<TextField
				disabled
				label={intl.formatMessage({
					id: 'partner.claims.view.tab.customer.phone',
				})}
				value={`(${contactDetails.phoneCode}) ${contactDetails.phoneNumber}`}
			/>
			<TextField
				disabled
				label={intl.formatMessage({
					id: 'partner.claims.view.tab.customer.country',
				})}
				value={contactDetails.country}
			/>
			<TextField
				disabled
				label={intl.formatMessage({
					id: 'partner.claims.view.tab.customer.city',
				})}
				value={contactDetails.city}
			/>
			<TextField
				disabled
				label={intl.formatMessage({
					id: 'partner.claims.view.tab.customer.birth.date',
				})}
				value={contactDetails.birthDate}
			/>
		</FormFieldsContainer>
	);
};
