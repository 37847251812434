import React from 'react';

import Box from '@mui/material/Box';

interface Props {
	tabsNavigation: React.ReactElement;
	content: React.ReactElement;
}

export const ContentWithNavigation: React.FC<Props> = ({
	tabsNavigation,
	content,
}) => (
	<Box m={2} sx={{ background: 'white' }}>
		{tabsNavigation}
		<Box sx={{ p: 3 }}>{content}</Box>
	</Box>
);
