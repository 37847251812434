import { ClaimStates } from 'types/states';

export enum ClaimsTableFilterFields {
	state = 'state',
	purchaseCode = 'purchaseCode',
	departureAirport = 'departureAirport',
	arrivalAirport = 'arrivalAirport',
	details = 'details',
	fullName = 'fullName',
	createdAtStart = 'createdAtStart',
	createdAtEnd = 'createdAtEnd',
}

export enum ClaimsTableFilterFieldsAPI {
	id = 'id',
	state = 'state',
	purchaseCode = 'purchaseCode',
	details = 'disruption.reasons',
	departureAirport = 'destination.departureAirport',
	arrivalAirport = 'destination.arrivalAirport',
	fullName = 'contact.fullName',
	createdAtStart = 'createdAt[after]',
	createdAtEnd = 'createdAt[before]',
}

export interface ClaimsStateInterface {
	pagination: {
		currentPage: number;
		itemsPerPage: number;
		totalItems: number;
	};
	filters: ClaimsTableFilterFieldsProps;
}

export type ClaimsTableFilterFieldsProps = {
	[ClaimsTableFilterFields.state]: string;
	[ClaimsTableFilterFields.purchaseCode]: string;
	[ClaimsTableFilterFields.departureAirport]: string;
	[ClaimsTableFilterFields.arrivalAirport]: string;
	[ClaimsTableFilterFields.details]: string;
	[ClaimsTableFilterFields.fullName]: string;
	[ClaimsTableFilterFields.createdAtStart]: string;
	[ClaimsTableFilterFields.createdAtEnd]: string;
};
export interface ClaimsPagination {
	currentPage: number;
	itemsPerPage: number;
	totalItems: number;
}

export type ClaimAttribute = {
	attributes: ClaimEntity;
	id: string;
	type: string;
};

export interface ClaimsListAPI {
	data: ClaimAttribute[];
	links: {
		first: string;
		last: string;
		next: string;
		self: string;
	};
	meta: {
		currentPage: number;
		itemsPerPage: number;
		totalItems: number;
	};
}

export interface ClaimEntity {
	id: string;
	_id: string;
	state: ClaimStates;
	purchaseCode: string;
	purchasePrice: {
		amount: number;
		currency: {
			code: string;
		};
	};
	destination: {
		departureAirport: string;
		departureAirportIataCode: string;
		arrivalAirport: string;
		arrivalAirportIataCode: string;
	};
	disruption: {
		userReason: string;
		airlineReason: string;
		details: string;
	};
	flightDetails: {
		informed: string;
		delayed: string;
	};
	contact: {
		firstName: string;
		lastName: string;
		email: string;
		address: string;
		city: string;
		country: string;
		countryCode: string;
		phoneCode: string;
		phoneNumber: string;
		birthDate: string;
	};
	createdAt: string;
	updatedAt: string;
	partnerName: string;
	numberOfPassengers: number;
}
