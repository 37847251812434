import * as React from 'react';
import { useFormikContext, useField } from 'formik';
import { FormattedMessage } from 'react-intl';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export type Option = {
	id: string;
	name: string;
};

interface Props {
	name: string;
	options: Option[];
	value: Option;
	onChange: (value: Option | null) => void;
	label: string;
	helperText?: string;
	onInputChange: (value: string) => void;
	loading: boolean;
	handleLoadMore: () => void;
}

export const SelectField: React.FC<Props> = ({
	options,
	value,
	name,
	label,
	helperText,
	onChange,
	onInputChange,
	loading,
	handleLoadMore,
	...rest
}) => {
	const formikProps = useFormikContext();

	const [, meta] = useField(name);

	const error = !!(meta && meta.touched && meta.error);
	const helperT = error ? meta.error : helperText;

	return (
		<Autocomplete
			ListboxProps={{
				role: 'list-box',
				onScroll: (event) => {
					const listboxNode = event.currentTarget;

					if (
						listboxNode.scrollTop + listboxNode.clientHeight ===
						listboxNode.scrollHeight
					) {
						if (handleLoadMore) {
							handleLoadMore();
						}
					}
				},
			}}
			size="small"
			disablePortal
			id={name}
			isOptionEqualToValue={(option, val: Option) => option.id === val.id}
			getOptionLabel={(option: Option) => option.name}
			options={options}
			loading={loading}
			loadingText={<FormattedMessage id="skyknight.fetching.data" />}
			renderInput={(params) => (
				<TextField
					error={error}
					helperText={helperT}
					label={label}
					{...params}
					onChange={(val) => onInputChange && onInputChange(val.target.value)}
				/>
			)}
			value={value}
			onChange={(_, opt) => {
				onChange(opt);
				formikProps.setFieldValue(name, opt);
			}}
			{...rest}
		/>
	);
};
