import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuList from '@mui/material/MenuList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { UserDeleteModal } from './UserDeleteModal';
import { userEdit } from '../constants';

interface Props {
	userId: string;
	isPrimary: boolean;
	handleUserDelete: (userId: string) => void;
}

export const UsersMoreActions: React.FC<Props> = ({
	userId,
	isPrimary,
	handleUserDelete,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	const handleUserDeleteModalOpen = () => setIsUserDeleteModalOpen(true);
	const handleUserDeleteModalClose = () => setIsUserDeleteModalOpen(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				id="actions-button"
				aria-controls={open ? 'menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="menu"
				MenuListProps={{
					'aria-labelledby': 'actions-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuList>
					<MenuItem onClick={() => navigate(`${userEdit}/${userId}`)}>
						<ListItemIcon>
							<EditIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage id="partner.users.table.more.actions.edit" />
						</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleClose();
							handleUserDeleteModalOpen();
						}}
					>
						<ListItemIcon>
							<DeleteForeverIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage id="partner.users.table.more.actions.delete" />
						</ListItemText>
					</MenuItem>
					{/* <MenuItem>
						<ListItemIcon>
							<ListAltIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage id="partner.users.table.more.actions.view.logs" />
						</ListItemText>
					</MenuItem> */}
				</MenuList>
			</Menu>
			<UserDeleteModal
				userId={userId}
				isOpen={isUserDeleteModalOpen}
				handleClose={handleUserDeleteModalClose}
				onDelete={(id) => handleUserDelete(id)}
				isPrimary={isPrimary}
			/>
		</>
	);
};
