import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Option } from 'components/FormFields';

import { UserStateInterface } from '../types';

const initialState: UserStateInterface = {
	id: '',
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	partner: null,
	createdAt: '',
	plainPassword: '',
	primaryContact: false,
};

export const userFormSlice = createSlice({
	name: 'userForm',
	initialState,
	reducers: {
		setUser: (
			state: UserStateInterface,
			action: PayloadAction<UserStateInterface>
		) => {
			state.id = action.payload.id;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
			state.phoneNumber = action.payload.phoneNumber;
			state.partner = action.payload.partner;
			state.createdAt = action.payload.createdAt;
			state.plainPassword = action.payload.plainPassword;
			state.primaryContact = action.payload.primaryContact;
		},
		resetUserState: (state: UserStateInterface) => {
			state.id = '';
			state.firstName = '';
			state.lastName = '';
			state.email = '';
			state.phoneNumber = '';
			state.partner = null;
			state.createdAt = '';
			state.plainPassword = '';
			state.primaryContact = false;
		},
		setPartner: (
			state: UserStateInterface,
			action: PayloadAction<Option | null>
		) => {
			state.partner = action.payload;
		},
		setPassword: (state: UserStateInterface, action: PayloadAction<string>) => {
			state.plainPassword = action.payload;
		},
	},
});

export const { setPartner, setUser, resetUserState, setPassword } =
	userFormSlice.actions;

export default userFormSlice.reducer;
