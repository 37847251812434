import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export const ForgotPassword: React.FC = () => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button variant="text" onClick={handleClickOpen}>
				<FormattedMessage id="login.form.forgot.password" />
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<FormattedMessage id="login.form.forgot.password.dialog.content1" />
						<Box
							sx={{ color: 'primary.main' }}
							color="primary"
							fontWeight={700}
							display="inline"
						>
							<FormattedMessage id="skyknight.support.email" />
						</Box>
						<FormattedMessage id="login.form.forgot.password.dialog.content2" />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						<FormattedMessage id="login.form.forgot.password.dialog.close" />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
