import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserRoles } from 'features/LoginForm';
import { ROLE } from 'types/types';
import { isUserHasAccess } from 'utils/helpers';

interface Props {
	userChildren: React.ReactElement;
	adminChildren: React.ReactElement;
}

export const SwitchByUserRole: React.FC<Props> = ({
	userChildren,
	adminChildren,
}) => {
	const userRoles = useSelector(selectUserRoles);
	const isAdmin = isUserHasAccess(userRoles, [ROLE.Admin]);

	if (isAdmin) {
		return <>{adminChildren}</>;
	}
	return <>{userChildren}</>;
};
