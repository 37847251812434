import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';

import { selectPurchaseEntity, selectInvoiceStatus } from '../selectors';
import { getStandardAmount } from 'core/helper';
import { States } from 'core/types';

import moment from 'moment';
import { DateFormats } from 'components/FormFields';

export const General: React.FC = () => {
	const intl = useIntl();
	const purchase = useSelector(selectPurchaseEntity);
	const purchaseStatus = useSelector(selectInvoiceStatus) || States.Rejected;

	return (
		<>
			<FormFieldsContainer>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.purchase.id',
					})}
					value={purchase.id}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.skyknight.code',
					})}
					value={purchase.claimCode.value}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.pdf.to.customer',
					})}
					value={purchase.claimInstruction.linkToPdfInstruction}
					InputProps={{
						endAdornment: (
							<InputAdornment
								sx={{
									'&:hover': {
										cursor: 'pointer',
									},
								}}
								position="start"
								onClick={() =>
									window.open(
										purchase.claimInstruction.linkToPdfInstruction,
										'_blank'
									)
								}
							>
								<OpenInNewIcon />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.sold.by',
					})}
					value={purchase.partner.name}
				/>
			</FormFieldsContainer>
			<Divider sx={{ marginBottom: 2 }} />

			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': { margin: '0px 10px 20px 0px' },
				}}
			>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.total.paid',
					})}
					value={getStandardAmount(purchase.priceTotal.amount)}
				/>

				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.commission.amount',
					})}
					value={getStandardAmount(purchase.partnerCommission.amount)}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.skyknight.revenue',
					})}
					value={getStandardAmount(purchase.skyknightCommission.amount)}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.original.currency.original',
					})}
					value={purchase.priceTotal.currency.code}
				/>
			</Box>
			<Divider sx={{ marginBottom: 2 }} />
			{/* enable MVP2 */}
			{/* <Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': { margin: '0px 10px 20px 0px' },
				}}
			>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.total.paid.original',
					})}
					value={'to be updated'}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.commission.amount.original',
					})}
					value={'to be updated'}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.skyknight.revenue.original',
					})}
					value={'to be updated'}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.original.currency',
					})}
					value={'to be updated'}
				/>
			</Box> */}
			<Divider sx={{ marginBottom: 2 }} />
			<FormFieldsContainer>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.purchase.timestamp',
					})}
					value={
						moment(purchase.createdAt)
							.format(DateFormats.YYYY_MM_DD_HH_MM)
							.toString() || ''
					}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.claim.status',
					})}
					value={'maintenance in progress'}
				/>
			</FormFieldsContainer>
			<FormFieldsContainer>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.invoice.status',
					})}
					value={purchaseStatus}
				/>
			</FormFieldsContainer>
		</>
	);
};
