import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik, Form } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { StateSimpleSelect, DateFormats } from 'components/FormFields';

import { selectClaimStatus, selectClaimEntity } from '../selectors';
import { ClaimStatusStateInterface } from '../types';
import { setClaimStatus } from '../reducer/claimStatusReducer';
import { ClaimStates } from 'types/states';

export const General: React.FC = () => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const claimStatus = useSelector(selectClaimStatus);
	const claimEntity = useSelector(selectClaimEntity);

	const [state, setState] = useState<ClaimStates>(
		claimStatus || ClaimStates.Rejected
	);

	const initialValues: ClaimStatusStateInterface = {
		state: claimStatus || null,
	};

	const handleClaimStatus = (stateVal: ClaimStates) => {
		console.log('stateVal', stateVal);
		if (claimStatus === stateVal) return;

		dispatch(setClaimStatus(stateVal));
		setState(stateVal);
	};

	const handleSubmit = (values: ClaimStatusStateInterface) => {
		console.log('values', values);
	};

	const validationSchema = yup.object().shape({
		claimStatus: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{() => (
				<Form noValidate autoComplete="off">
					<FormFieldsContainer>
						<TextField
							disabled
							label={intl.formatMessage({
								id: 'partner.claims.view.tab.general.claim.id',
							})}
							value={claimEntity.id}
						/>
						<TextField
							disabled
							label={intl.formatMessage({
								id: 'partner.claims.view.tab.general.skyknight.code',
							})}
							value={claimEntity.purchaseCode}
						/>
						<TextField
							disabled
							label={intl.formatMessage({
								id: 'partner.claims.view.tab.general.sold.by.partner',
							})}
							value={claimEntity.partnerName}
						/>
					</FormFieldsContainer>
					<FormFieldsContainer>
						<Field
							as={StateSimpleSelect}
							options={Object.values(ClaimStates)}
							intl={intl}
							value={state}
							onChange={handleClaimStatus}
							name="state"
							type="object"
							label={intl.formatMessage({
								id: 'partner.purchase.view.invoice.status',
							})}
						/>
					</FormFieldsContainer>
					<FormFieldsContainer>
						<TextField
							disabled
							label={intl.formatMessage({
								id: 'partner.claims.view.tab.general.claim.submitted',
							})}
							value={moment(claimEntity.createdAt)
								.format(DateFormats.YYYY_MM_DD_HH_MM)
								.toString()}
						/>
						<TextField
							disabled
							label={intl.formatMessage({
								id: 'partner.claims.view.tab.general.passenger.number',
							})}
							value={claimEntity.numberOfPassengers}
						/>
					</FormFieldsContainer>
					<Box paddingBottom={2} paddingTop={2}>
						<Button type="submit" variant="contained" color="primary">
							<FormattedMessage id="partner.claims.view.tab.general.save.changes" />
						</Button>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
