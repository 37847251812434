import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { AlertBoxProps } from '../types';

export const AlertBox: React.FC<AlertBoxProps> = ({ title, message, type }) => (
	<Alert severity={type}>
		{title && <AlertTitle>{title}</AlertTitle>}
		{message && message}
	</Alert>
);
