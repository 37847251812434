import React from 'react';
import { CenterContainer } from 'layouts/containers/CenterContainer';
import { BoxWrapper } from 'layouts/wrappers/BoxWrapper';
import { LoginForm } from 'features/LoginForm';

export const LoginPage: React.FC = () => (
	<CenterContainer>
		<BoxWrapper p={3}>
			<LoginForm />
		</BoxWrapper>
	</CenterContainer>
);
