import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ClaimStates } from 'types/states';
import { ClaimEntity } from 'features/Claims';

const initialState: ClaimEntity = {
	_id: '',
	id: '',
	state: ClaimStates.Rejected,
	purchaseCode: '',
	purchasePrice: {
		amount: 0,
		currency: {
			code: '',
		},
	},
	destination: {
		departureAirport: '',
		departureAirportIataCode: '',
		arrivalAirport: '',
		arrivalAirportIataCode: '',
	},
	disruption: {
		userReason: '',
		airlineReason: '',
		details: '',
	},
	flightDetails: {
		informed: '',
		delayed: '',
	},
	contact: {
		firstName: '',
		lastName: '',
		email: '',
		address: '',
		city: '',
		country: '',
		countryCode: '',
		phoneCode: '',
		phoneNumber: '',
		birthDate: '',
	},
	createdAt: '',
	updatedAt: '',
	numberOfPassengers: 0,
	partnerName: '',
};

export const claimStatusFormSlice = createSlice({
	name: 'claimStatusForm',
	initialState,
	reducers: {
		setClaimStatus: (
			state: ClaimEntity,
			action: PayloadAction<ClaimStates>
		) => {
			state.state = action.payload || null;
		},
		setClaimEntity: (
			state: ClaimEntity,
			action: PayloadAction<ClaimEntity>
		) => {
			return action.payload;
		},
	},
});

export const { setClaimStatus, setClaimEntity } = claimStatusFormSlice.actions;

export default claimStatusFormSlice.reducer;
