import React from 'react';
import { Field } from 'formik';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
}

export const CheckboxField: React.FC<Props> = ({ name, label, disabled }) => (
	<Field
		as={FormControlLabel}
		type="checkbox"
		name={name}
		control={<Checkbox />}
		label={label}
		disabled={disabled}
	/>
);
