import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';

export const AccessDeniedPage: React.FC = () => (
	<Layout
		header={<Header />}
		main={
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					minHeight: '100vh',
				}}
			>
				<Typography variant="h6">
					<FormattedMessage id="access.denied.page.title" />
				</Typography>
			</Box>
		}
	/>
);
