import React from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import {
	SelectField,
	Option,
	TextField,
	CheckboxField,
} from 'components/FormFields';
import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { PartnerStateFields } from '../types';

import { setCommissionType } from '../reducer/partnerReducer';
import { selectCommissionType, selectPartnerId } from '../selectors';
import { commissionTypes } from '../constants';

export const General: React.FC = () => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const commissionType = useSelector(selectCommissionType);
	const partnerId = useSelector(selectPartnerId);

	const handleCommissionTypeSelect = (option: Option) => {
		if (commissionType?.id === option?.id) return;

		dispatch(setCommissionType(!option ? null : option));
	};

	return (
		<>
			<FormFieldsContainer>
				<Field
					disabled
					as={TextField}
					name={PartnerStateFields.id}
					type="text"
					label={intl.formatMessage({
						id: 'partners.edit.create.partner.id',
					})}
				/>
			</FormFieldsContainer>
			<FormFieldsContainer>
				<Field
					as={TextField}
					name={PartnerStateFields.legalEntityName}
					type="text"
					label={intl.formatMessage({
						id: 'partners.edit.create.legal.name',
					})}
				/>
				<Field
					as={TextField}
					name={PartnerStateFields.legalEntityCode}
					type="text"
					label={intl.formatMessage({
						id: 'partners.edit.create.legal.entity.code',
					})}
				/>
				<Field
					as={TextField}
					name={PartnerStateFields.vatNumber}
					type="text"
					label={intl.formatMessage({
						id: 'partners.edit.create.vat.number',
					})}
					helperText={intl.formatMessage({
						id: 'partners.edit.create.vat.number.description',
					})}
				/>
				<Field
					as={TextField}
					name={PartnerStateFields.website}
					type="text"
					label={intl.formatMessage({
						id: 'partners.edit.create.website',
					})}
				/>
				<Field
					as={TextField}
					name={PartnerStateFields.commissionAmount}
					type="number"
					label={intl.formatMessage({
						id: 'partners.edit.create.commission.amount',
					})}
				/>
				<Field
					as={SelectField}
					options={commissionTypes}
					value={commissionType}
					onChange={handleCommissionTypeSelect}
					name={PartnerStateFields.commissionType}
					type="object"
					label={intl.formatMessage({
						id: 'partners.edit.create.commission.type',
					})}
				/>
				{partnerId ? (
					<Field
						disabled
						as={TextField}
						name={PartnerStateFields.createdAt}
						type="text"
						label={intl.formatMessage({
							id: 'partners.edit.create.created.at',
						})}
					/>
				) : (
					<></>
				)}
			</FormFieldsContainer>
			<CheckboxField
				name={PartnerStateFields.state}
				label={intl.formatMessage({
					id: 'partners.edit.create.status',
				})}
			/>
		</>
	);
};
