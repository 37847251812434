import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectLocale } from 'features/LanguageSelector';
import { http } from 'core/ApiClient';
import { Error } from 'core/types';
import { Routes } from 'types/types';
import { resolveApiErrors } from 'features/ApiErrors';

import { PurePartner } from './types';
import { setPartner, resetPartnerState } from './reducer/partnerReducer';

export const useFetchPartner = (partnerId?: string) => {
	const [data, setData] = useState<PurePartner | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Record<string, string> | null>(null);

	const dispatch = useDispatch();
	const locale = useSelector(selectLocale);

	useEffect(() => {
		dispatch(resetPartnerState());
		if (partnerId) {
			const fetchData = async () => {
				setIsLoading(true);
				const response = await http.get<PurePartner>(
					`${Routes.Partners}/${partnerId}`
				);
				setIsLoading(false);
				setData(response);
				setError(null);
				dispatch(setPartner(response));
			};
			fetchData().catch((e: Error) => {
				setError(resolveApiErrors(e.cause.violations, {}, locale).globalErrors);
				setIsLoading(false);
			});
		}
	}, []);

	return { data, isLoading, error };
};
