import { FormattedMessage } from 'react-intl';

import { HeadCell } from 'components/Table';
import { RequiredFields } from './types';

export const logsTableHeadCells: HeadCell[] = [
	{
		id: 'logId',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.user.edit.create.logs.head.log.id" />,
	},
	{
		id: 'user',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.user.edit.create.logs.head.user" />,
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.user.edit.create.logs.head.email" />,
	},
	{
		id: 'companyName',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.user.edit.create.logs.head.company.name" />
		),
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.user.edit.create.logs.head.action" />,
	},
	{
		id: 'actionTimestamp',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.user.edit.create.logs.head.action.timestamp" />
		),
	},
];

export const generalTabRequiredFields: string[] = [
	RequiredFields.FirstName,
	RequiredFields.LastName,
	RequiredFields.Email,
	RequiredFields.Phone,
	RequiredFields.Company,
];
