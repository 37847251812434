import React from 'react';
import { PurchasesStats } from '../types';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { SimpleCard } from 'components/Cards/SimpleCard';
import { getStandardAmount } from 'core/helper';

interface Props {
	purchasesStats: PurchasesStats | null;
}

export const PurchasesStatsNode: React.FC<Props> = ({ purchasesStats }) => {
	if (!purchasesStats) return <></>;

	return (
		<Grid
			container
			spacing={{ xs: 2, md: 3 }}
			columns={{ xs: 4, sm: 8, md: 10 }}
		>
			<Grid item xs={2} sm={2} md={2}>
				<SimpleCard
					title={<FormattedMessage id="dashboard.card.purchase.count" />}
					secondary={purchasesStats.count}
				/>
			</Grid>
			<Grid item xs={2} sm={2} md={2}>
				<SimpleCard
					title={
						<FormattedMessage id="dashboard.card.purchase.covered.passengers" />
					}
					secondary={purchasesStats.coveredPassengers}
				/>
			</Grid>

			<Grid item xs={2} sm={2} md={2}>
				<SimpleCard
					title={
						<FormattedMessage id="dashboard.card.purchase.median.amount" />
					}
					secondary={getStandardAmount(purchasesStats.medianAmount)}
				/>
			</Grid>
			<Grid item xs={2} sm={2} md={2}>
				<SimpleCard
					title={
						<FormattedMessage id="dashboard.card.purchase.median.number.of.passenger" />
					}
					secondary={purchasesStats.coveredPassengers}
				/>
			</Grid>
			<Grid item xs={2} sm={2} md={2}>
				<SimpleCard
					title={<FormattedMessage id="dashboard.card.purchase.total.amount" />}
					secondary={getStandardAmount(purchasesStats.totalAmount)}
				/>
			</Grid>
		</Grid>
	);
};
