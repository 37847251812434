import { FormattedMessage } from 'react-intl';

import { HeadCell } from 'components/Table';

export const purchasesTableHeadCells: HeadCell[] = [
	// {
	// 	id: 'id',
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: <FormattedMessage id="partner.purchase.head.cell.label.id" />,
	// },
	{
		id: 'skyKnightCode',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.purchase.head.cell.label.skyknight.code" />
		),
	},
	{
		id: 'partnerName',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.purchase.head.cell.label.partner.sold.by" />
		),
	},
	{
		id: 'totallyPaid',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.purchase.head.cell.label.totally.paid" />
		),
	},
	{
		id: 'partnerCommission',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.purchase.head.cell.label.partner.commission" />
		),
	},
	{
		id: 'revenue',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.purchase.head.cell.label.skyknight.revenue" />
		),
	},
	{
		id: 'at',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.purchase.head.cell.label.at" />,
	},
	{
		id: 'invoiceStatus',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.purchase.head.cell.label.invoice.status" />
		),
	},
	{
		id: 'claimStatus',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.purchase.head.cell.label.claim.status" />
		),
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: true,
		label: '',
	},
];

export const purchaseViewRoute = '/purchase/view';
