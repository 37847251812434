import { ROLE } from 'types/types';

export interface FormValues {
	email: string;
	password: string;
}

export enum LoginStorageValues {
	Email = 'email',
	Password = 'password',
}

export interface LoginRequest {
	email: string;
	password: string;
}

export interface LoginResponse {
	token: string;
	refresh_token: string;
}

export interface AuthDataStateInterface {
	token: string | null;
	refresh_token: string | null;
	roles: Array<ROLE> | null;
	exp: number | null;
	username: string | null;
}
