import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#149954',
		},
		secondary: {
			main: '#0009',
		},
		background: {
			default: '#f5fafa',
		},
	},
	typography: {
		fontFamily: '"Open Sans", sans-serif',
		button: {
			textTransform: 'none',
		},
	},
	components: {
		MuiTextField: {
			defaultProps: {
				size: 'small',
			},
		},
	},
});
