import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RowsPerPageOptions } from 'components/Table';

import {
	ClaimsStateInterface,
	ClaimsPagination,
	ClaimsTableFilterFields,
} from '../types';

const initialState: ClaimsStateInterface = {
	pagination: {
		currentPage: 1,
		itemsPerPage: RowsPerPageOptions[0],
		totalItems: 1,
	},
	filters: {
		[ClaimsTableFilterFields.state]: '',
		[ClaimsTableFilterFields.purchaseCode]: '',
		[ClaimsTableFilterFields.departureAirport]: '',
		[ClaimsTableFilterFields.arrivalAirport]: '',
		[ClaimsTableFilterFields.details]: '',
		[ClaimsTableFilterFields.fullName]: '',
		[ClaimsTableFilterFields.createdAtStart]: '',
		[ClaimsTableFilterFields.createdAtEnd]: '',
	},
};

export const claimsSlice = createSlice({
	name: 'claims',
	initialState,
	reducers: {
		setPagination: (state, action: PayloadAction<ClaimsPagination>) => {
			state.pagination.currentPage = action.payload.currentPage;
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
			state.pagination.totalItems = action.payload.totalItems;
		},

		setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
			state.pagination.currentPage = action.payload.currentPage;
		},
		setItemsPerPage: (
			state,
			action: PayloadAction<{ itemsPerPage: number }>
		) => {
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
		},
		resetPagination: (state) => {
			state.pagination = {
				currentPage: 1,
				itemsPerPage: RowsPerPageOptions[0],
				totalItems: 1,
			};
		},
		setFilterValue: (
			state,
			action: PayloadAction<{
				filterName: ClaimsTableFilterFields;
				value: string;
			}>
		) => {
			state.filters[action.payload.filterName] = action.payload.value;
		},
	},
});

export const {
	setPagination,
	setCurrentPage,
	setItemsPerPage,
	resetPagination,
	setFilterValue,
} = claimsSlice.actions;

export default claimsSlice.reducer;
