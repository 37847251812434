import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { RowsPerPageOptions } from '../types';
import { useIntl } from 'react-intl';

interface Props {
	rowsLength: number;
	page: number;
	rowsPerPage: number;
	handleChangePage: (
		_: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => void;
	handleChangeRowsPerPage: (rowsPerPage: number, newPage: number) => void;
}

export const Pagination: React.FC<Props> = ({
	rowsLength,
	rowsPerPage,
	page,
	handleChangePage,
	handleChangeRowsPerPage,
}) => {
	const intl = useIntl();

	return (
		<TablePagination
			labelRowsPerPage={intl.formatMessage({ id: 'table.rows.per.page' })}
			rowsPerPageOptions={RowsPerPageOptions}
			component="div"
			count={rowsLength}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={handleChangePage}
			onRowsPerPageChange={(
				event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
			) => handleChangeRowsPerPage(parseInt(event.target.value, 10), 0)}
		/>
	);
};
