import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ApiErrorsSnackbar, resolveApiErrors } from 'features/ApiErrors';
import { setCountries, CountriesListAPI } from 'features/Country';
import { Error } from 'core/types';
import { Routes } from 'types/types';
import { MyCompanyTabs } from './MyCompanyTabs';
import { PureMyPartner } from '../types';
import { setMyPartner } from '../reducer/myCompanyReducer';
import { selectLocale } from 'features/LanguageSelector';
import { http } from 'core/ApiClient';
import { ProgressBox } from 'components/ProgressIndicator';
import { ListPaginationConfig } from 'core/constants';

export const MyCompany: React.FC = () => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<Record<string, string> | null>(null);

	const locale = useSelector(selectLocale);

	useEffect(() => {
		const fetchCountries = async () =>
			http.get<CountriesListAPI>(
				`${Routes.Countries}?itemsPerPage=10`,
				ListPaginationConfig
			);

		const fetchMyPartner = async () =>
			http.get<PureMyPartner>(Routes.MyUserPartner);

		const fetchData = async () => {
			setIsLoading(true);
			await Promise.all([fetchCountries(), fetchMyPartner()])
				.then((res) => {
					dispatch(setCountries(res[0].data));
					dispatch(setMyPartner(res[1]));
					setIsLoading(false);
				})
				.catch((e: Error) => {
					setIsLoading(false);
					setError(
						resolveApiErrors(e.cause.violations, {}, locale).globalErrors
					);
				});
		};
		fetchData().catch((e: Error) => {
			setError(resolveApiErrors(e.cause.violations, {}, locale).globalErrors);
			setIsLoading(false);
		});
	}, []);

	if (!isLoading && error) {
		return (
			<ApiErrorsSnackbar
				messages={error}
				type="error"
				isOpen={!isLoading && !!error}
			/>
		);
	}

	return isLoading ? <ProgressBox /> : <MyCompanyTabs />;
};
