import React from 'react';
import { PurchasesStats } from '../types';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { SimpleCard } from 'components/Cards/SimpleCard';
import { getStandardAmount } from 'core/helper';

interface Props {
	commissionStats: PurchasesStats | null;
}

export const CommissionStatsNode: React.FC<Props> = ({ commissionStats }) => {
	if (!commissionStats) return <></>;

	return (
		<Grid
			container
			spacing={{ xs: 2, md: 3 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
		>
			<Grid item xs={2} sm={4} md={6}>
				<SimpleCard
					title={
						<FormattedMessage id="dashboard.card.commission.median.amount" />
					}
					secondary={getStandardAmount(commissionStats.commissionMedianAmount)}
				/>
			</Grid>
			<Grid item xs={2} sm={4} md={6}>
				<SimpleCard
					title={
						<FormattedMessage id="dashboard.card.commission.total.amount" />
					}
					secondary={getStandardAmount(commissionStats.commissionTotalAmount)}
				/>
			</Grid>
		</Grid>
	);
};
