import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from 'types';
import { useShowForRole } from 'hooks/useShowForRole';
import { ROLE } from 'types/types';

// import { LanguageSelector } from 'features/LanguageSelector';
import { LogoutButton } from 'features/LoginForm';
import logo from 'assets/images/logo.png';

export const Header = () => {
	const navigate = useNavigate();
	const isVisibleForUser = useShowForRole([ROLE.User], true);

	return (
		<AppBar
			position="relative"
			component="nav"
			sx={{ background: 'white', height: '72px' }}
		>
			<Toolbar
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					margin: { md: '0 50px 0 50px' },
				}}
			>
				<Box
					onClick={() =>
						navigate(
							isVisibleForUser
								? `/${PageRoutes.Dashboard}`
								: `/${PageRoutes.Partners}`
							// : `/${PageRoutes.AdminDashboard}`
						)
					}
					component="img"
					sx={{
						'&:hover': {
							cursor: 'pointer',
						},
						width: { md: '200px', xs: '100px' },
					}}
					alt="logo"
					src={logo}
				/>
				<Box>
					{/* <LanguageSelector /> */}
					<LogoutButton />
				</Box>
			</Toolbar>
		</AppBar>
	);
};
