import { ObjectValType } from 'components/Table';

export enum PartnerState {
	Active = 'active',
	Deactivated = 'deactivated',
}

export interface Partner {
	[key: string]: ObjectValType;
	_id: string;
	id: string;
	address: {
		city: string;
		country: string;
		firstLine: string;
		postalCode: string;
		secondLine: string;
	};
	bankDetails: {
		address: string;
		country: string;
		ibanAccount: string;
		swiftAccount: string;
		title: string;
	};
	commission: {
		amount: number;
		type: string;
	};
	company: {
		name: string;
		entityCode: string;
		website: string;
	};
	contactEmail: string;
	contactPerson: string;
	createdAt: string;
	numberOfPurchases: number;
	state: PartnerState;
}

type Company = {
	name: string;
};
export interface PartnerOption {
	id: string;
	company: Company;
}

export enum PartnersTableFilterFields {
	id = 'id',
	partner = 'partnerName',
	website = 'website',
	contactPerson = 'contactPerson',
	contactEmail = 'contactEmail',
	createdAtStart = 'createdAtStart',
	createdAtEnd = 'createdAtEnd',
	state = 'state',
}

export interface PartnersPagination {
	currentPage: number;
	itemsPerPage: number;
	totalItems: number;
}

export type PartnerAttribute = {
	attributes: Partner;
	id: string;
	type: string;
};

export interface PartnersListAPI {
	data: PartnerAttribute[];
	links: {
		first: string;
		last: string;
		next: string;
		self: string;
	};
	meta: PartnersPagination;
}

export interface PartnersTableFiltersFieldsProps {
	[PartnersTableFilterFields.id]: string;
	[PartnersTableFilterFields.partner]: string;
	[PartnersTableFilterFields.website]: string;
	[PartnersTableFilterFields.contactPerson]: string;
	[PartnersTableFilterFields.contactEmail]: string;
	[PartnersTableFilterFields.createdAtStart]: string;
	[PartnersTableFilterFields.createdAtEnd]: string;
	[PartnersTableFilterFields.state]: PartnerState;
}

export enum PartnersTableFiltersFieldsAPI {
	id = 'id',
	partnerName = 'company.name',
	website = 'company.website',
	contactPerson = 'contactPerson',
	contactEmail = 'contactEmail',
	createdAtStart = 'createdAt[after]',
	createdAtEnd = 'createdAt[before]',
	state = 'state',
	numberOfPurchases = 'number_of_purchases',
	createdAt = 'createdAt',
}
