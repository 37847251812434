import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { AuthDataStateInterface } from '../types';

const initialState: AuthDataStateInterface = {
	token: null,
	refresh_token: null,
	roles: null,
	exp: null,
	username: null,
};

export const authDataSlice = createSlice({
	name: 'authData',
	initialState,
	reducers: {
		setAuth: (
			state: AuthDataStateInterface,
			action: PayloadAction<AuthDataStateInterface>
		) => {
			state.token = action.payload.token;
			state.refresh_token = action.payload.refresh_token;
			state.roles = action.payload.roles;
			state.exp = action.payload.exp;
			state.username = action.payload.username;
		},
		resetAuth: (state: AuthDataStateInterface) => {
			state.token = null;
			state.refresh_token = null;
			state.roles = null;
			state.exp = null;
			state.username = null;
		},
	},
});

export const { setAuth, resetAuth } = authDataSlice.actions;

export default authDataSlice.reducer;
