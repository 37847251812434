import type { State } from 'redux-store/Store';

import { airportAdapter } from './reducer/airportReducer';

const airportSelectors = airportAdapter.getSelectors();

export const selectAllAirports = (state: State) =>
	airportSelectors.selectAll(state.airports);

export const selectCurrentPage = (state: State) =>
	state.airports.pagination.currentPage;
