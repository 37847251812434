import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Error } from 'core/types';
import { http } from 'core/ApiClient';
import { Routes } from 'types/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { selectLocale } from 'features/LanguageSelector';

import { UsersTable } from 'features/Users/components/UsersTable';
import { UsersTableMoreActions } from '../components/UsersTableMoreActions';
import { resetUserState } from 'features/UserCreateEdit';
import { PartnersListAPI, setPartnerCompanyNames } from 'features/Partners';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { ProgressBox } from 'components/ProgressIndicator';
import { UserViewEditModal } from '../components/UserViewEditModal';
import { ListPaginationConfig } from 'core/constants';

interface Props {
	partnerId?: string;
}

export const Users: React.FC<Props> = ({ partnerId }) => {
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [refetch, setRefetch] = useState<boolean>(false);
	const [apiError, setApiError] = useState<Record<string, string> | null>();
	const dispatch = useDispatch();

	const locale = useSelector(selectLocale);

	const handleUsersRefetch = (value: boolean) => setRefetch(value);

	const handleModalOpen = async () => {
		handleUsersRefetch(false);
		setOpenModal(true);
		setLoading(true);
		setApiError(null);
		dispatch(resetUserState());

		await http
			.get<PartnersListAPI>(
				`${Routes.Partners}?groups[]=select&itemsPerPage=10`,
				ListPaginationConfig
			)
			.then((res) => {
				setLoading(false);
				dispatch(setPartnerCompanyNames(res.data));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setLoading(false);
			});
	};

	return (
		<Box>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end',
					mb: 2,
				}}
			>
				<Button
					onClickCapture={() => void handleModalOpen()}
					onClick={() => setOpenModal(true)}
					sx={{ minWidth: 'max-content', height: 'fit-content' }}
					variant="outlined"
				>
					<FormattedMessage id="my.company.view.edit.users.table.add.new.user" />
				</Button>
			</Box>
			{apiError && !loading && (
				<ApiErrorsSnackbar
					messages={apiError}
					type="error"
					isOpen={apiError && !loading}
				/>
			)}
			<UsersTable
				refetch={refetch}
				partnerId={partnerId}
				moreActions={(userId, handleUserDelete, isPrimary) => (
					<UsersTableMoreActions
						userId={userId}
						handleUserDelete={() => void handleUserDelete(userId)}
						handleUsersRefetch={handleUsersRefetch}
						isPrimary={isPrimary}
					/>
				)}
			/>
			{loading && !apiError ? (
				<ProgressBox />
			) : (
				<UserViewEditModal
					isOpen={openModal}
					handleUsersRefetch={handleUsersRefetch}
					handleClose={() => {
						setOpenModal(false);
						handleUsersRefetch(true);
					}}
				/>
			)}
		</Box>
	);
};
