import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';

import { ClaimView } from 'features/ClaimView';

export const ClaimViewPage: React.FC = () => (
	<Layout header={<Header />} main={<ClaimView />} />
);
