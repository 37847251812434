import React from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import {
	SelectField,
	Option,
	TextField,
	CheckboxField,
} from 'components/FormFields';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { selectMyPartnerId, selectCommissionType } from '../selectors';
import { setCommissionType } from '../reducer/myCompanyReducer';
import { MyPartnerStateFields } from '../types';
import { commissionTypes } from '../constants';

export const General: React.FC = () => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const commissionType = useSelector(selectCommissionType);
	const myPartnerId = useSelector(selectMyPartnerId);

	const handleCommissionTypeSelect = (option: Option) => {
		if (commissionType?.id === option?.id) return;

		dispatch(setCommissionType(!option ? null : option));
	};

	return (
		<>
			<FormFieldsContainer>
				<Field
					disabled
					as={TextField}
					name={MyPartnerStateFields.id}
					type="text"
					label={intl.formatMessage({
						id: 'my.company.view.edit.partner.id',
					})}
				/>
			</FormFieldsContainer>
			<FormFieldsContainer>
				<Field
					as={TextField}
					name={MyPartnerStateFields.legalEntityName}
					type="text"
					label={intl.formatMessage({
						id: 'my.company.view.edit.legal.name',
					})}
				/>
				<Field
					as={TextField}
					name={MyPartnerStateFields.legalEntityCode}
					type="text"
					label={intl.formatMessage({
						id: 'my.company.view.edit.legal.entity.code',
					})}
				/>
				<Field
					as={TextField}
					name={MyPartnerStateFields.vatNumber}
					type="text"
					label={intl.formatMessage({
						id: 'my.company.view.edit.vat.number',
					})}
					helperText={intl.formatMessage({
						id: 'my.company.view.edit.vat.number.description',
					})}
				/>
				<Field
					as={TextField}
					name={MyPartnerStateFields.website}
					type="text"
					label={intl.formatMessage({
						id: 'my.company.view.edit.website',
					})}
				/>
				<Field
					as={TextField}
					name={MyPartnerStateFields.commissionAmount}
					disabled
					type="number"
					label={intl.formatMessage({
						id: 'my.company.view.edit.commission.amount',
					})}
				/>
				<Field
					as={SelectField}
					options={commissionTypes}
					value={commissionType}
					onChange={handleCommissionTypeSelect}
					name={MyPartnerStateFields.commissionType}
					type="object"
					label={intl.formatMessage({
						id: 'my.company.view.edit.commission.type',
					})}
				/>
				{myPartnerId ? (
					<Field
						disabled
						as={TextField}
						name={MyPartnerStateFields.createdAt}
						type="text"
						label={intl.formatMessage({
							id: 'my.company.view.edit.created.at',
						})}
					/>
				) : (
					<></>
				)}
			</FormFieldsContainer>
			<CheckboxField
				disabled
				name={MyPartnerStateFields.state}
				label={intl.formatMessage({
					id: 'my.company.view.edit.status',
				})}
			/>
		</>
	);
};
