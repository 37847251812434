import { ROLE } from 'types/types';
import { isUserHasAccess } from 'utils/helpers';
import { useSelector } from 'react-redux';

import { selectUserRoles } from 'features/LoginForm';
export const useShowForRole = (
	requiredRoles: Array<ROLE>,
	strict?: boolean
): boolean => {
	const userRoles = useSelector(selectUserRoles);

	return isUserHasAccess(userRoles, requiredRoles, strict);
};
