import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import HandshakeIcon from '@mui/icons-material/Handshake';
import BusinessIcon from '@mui/icons-material/Business';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { useShowForRole } from 'hooks/useShowForRole';
import { ROLE, Routes } from 'types/types';
import { Error } from 'core/types';
import { http } from 'core/ApiClient';

import { ApiErrorsSnackbar, resolveApiErrors } from 'features/ApiErrors';
import { StyledCard } from 'components/Cards/StyledCard';
import { PageRoutes } from 'types';
import { selectLocale } from 'features/LanguageSelector';
import { ClaimsStats, PurchasesStats } from '../types';
import { ProgressBox } from 'components/ProgressIndicator';
import { ClaimsStatsNode } from './ClaimsStatsNode';
import { PurchasesStatsNode } from './PurchasesStatsNode';
import { CommissionStatsNode } from './CommissionsStatsNode';

export const Dashboard: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [apiError, setApiError] = useState<Record<string, string> | null>(null);
	const [purchasesStats, setPurchasesStats] = useState<PurchasesStats | null>(
		null
	);
	const [claimsStats, setClaimsStats] = useState<ClaimsStats | null>(null);

	const locale = useSelector(selectLocale);

	useEffect(() => {
		const fetchPurchasesStats = async () =>
			http.get<PurchasesStats>(Routes.PurchaseStats);

		const fetchClaimsStats = async () =>
			http.get<ClaimsStats>(Routes.ClaimStats);

		const fetchData = async () => {
			setIsLoading(true);
			await Promise.all([fetchPurchasesStats(), fetchClaimsStats()])
				.then((res) => {
					setPurchasesStats(res[0]);
					setClaimsStats(res[1]);
					setIsLoading(false);
				})
				.catch((e: Error) => {
					setIsLoading(false);
					setApiError(
						resolveApiErrors(e.cause.violations, {}, locale).globalErrors
					);
				});
		};
		fetchData().catch((e: Error) => {
			setApiError(
				resolveApiErrors(e.cause.violations, {}, locale).globalErrors
			);
			setIsLoading(false);
		});
	}, []);

	const isVisibleForUser = useShowForRole([ROLE.User]);

	if (!isLoading && apiError) {
		return (
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={!isLoading && !!apiError}
			/>
		);
	}

	return isLoading ? (
		<ProgressBox />
	) : (
		<>
			{isVisibleForUser && (
				<Grid textAlign="center" justifyContent="center">
					<Grid
						container
						item
						xs={12}
						alignItems="center"
						justifyContent="center"
					>
						<Grid container item sm={12}>
							<Grid container style={{ marginBottom: 25 }} item lg={12}>
								<StyledCard
									title={
										<FormattedMessage id="dashboard.page.purchases.title" />
									}
									statsNode={
										<PurchasesStatsNode purchasesStats={purchasesStats} />
									}
									iconPerformance={<SummarizeIcon />}
									link={PageRoutes.Purchases}
									navigateTitle={
										<FormattedMessage id="dashboard.card.action.view.purchases" />
									}
								/>
							</Grid>
							<Grid container item lg={12} style={{ marginBottom: 25 }}>
								<StyledCard
									title={<FormattedMessage id="dashboard.page.claims.title" />}
									statsNode={<ClaimsStatsNode claimsStats={claimsStats} />}
									iconPerformance={<HandshakeIcon />}
									link={PageRoutes.Claims}
									navigateTitle={
										<FormattedMessage id="dashboard.card.action.view.claims" />
									}
								/>
							</Grid>
							<Grid container style={{ marginBottom: 25 }} item lg={12}>
								<StyledCard
									title={
										<FormattedMessage id="dashboard.page.commissions.title" />
									}
									statsNode={
										<CommissionStatsNode commissionStats={purchasesStats} />
									}
									iconPerformance={<BusinessIcon />}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};
