import { ClaimStates } from 'types/states';

export enum TabId {
	General = 0,
	DisruptedFlightDetails = 1,
	Customer = 2,
	Logs = 3,
}

export interface ClaimStatusStateInterface {
	state: ClaimStates | null;
}
