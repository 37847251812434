import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch } from 'redux-store/Store';
import { useAppDispatch } from 'redux-store/hooks';

import secureLocalStorage from 'react-secure-storage';
import { JwtToken } from 'core/types';

import { setAuth, resetAuth } from '../reducer/authDataReducers';
import { getDecodedJwtToken } from 'utils/helpers';

export const authHandler = (dispatch: AppDispatch) => {
	try {
		const userToken = secureLocalStorage.getItem(JwtToken.Token);
		const userRefreshToken = secureLocalStorage.getItem(JwtToken.RefreshToken);
		const authData = getDecodedJwtToken();
		dispatch(
			setAuth({
				token: userToken?.toString() || null,
				refresh_token: userRefreshToken?.toString() || null,
				roles: authData.roles,
				exp: authData.exp,
				username: authData.username,
			})
		);
	} catch (e) {
		dispatch(resetAuth());
	}
};

export const useInitAuth = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		authHandler(dispatch);
	}, []);
};
