import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';

import CssBaseline from '@mui/material/CssBaseline';

import { AppRouter } from './AppRouter';
import { theme } from './config/theme';
import { useInitialFetch } from 'features/LanguageSelector/hooks/initialFetch';
import { Cookie } from 'features/Cookie';
import { useInitAuth, authHandler } from 'features/LoginForm/';
import { JwtRefreshEvent } from 'core/types';
import { useAppDispatch } from 'redux-store/hooks';
import ErrorBoundary from 'core/ErrorBoundary';

export const App: React.FC = () => {
	const dispatch = useAppDispatch();
	const { locale, messages, defaultLocale } = useInitialFetch();
	useInitAuth();
	useEffect(() => {
		const handleRefreshToken = () => {
			authHandler(dispatch);
		};

		window.addEventListener(JwtRefreshEvent.RefreshEvent, handleRefreshToken);
		return () => {
			window.removeEventListener(
				JwtRefreshEvent.RefreshEvent,
				handleRefreshToken
			);
		};
	}, []);

	return (
		<BrowserRouter>
			<ThemeProviderLegacy theme={theme}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<IntlProvider
						locale={locale}
						messages={messages}
						defaultLocale={defaultLocale}
					>
						<Cookie />
						<ErrorBoundary>
							<AppRouter />
						</ErrorBoundary>
					</IntlProvider>
				</ThemeProvider>
			</ThemeProviderLegacy>
		</BrowserRouter>
	);
};
