import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { http } from 'core/ApiClient';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { SelectField, Option, TextField } from 'components/FormFields';
import { Routes } from 'types/types';

import { selectCountry } from '../selectors';
import { setCountry } from '../reducer/partnerReducer';
import {
	selectAllCountriesIdNamePhoneCode,
	addCountries,
	CountriesListAPI,
	setCurrentPage,
	selectCurrentPage,
} from 'features/Country';
import { PartnerStateFields } from '../types';
import { ListPaginationConfig } from 'core/constants';

export const Address: React.FC = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [debouncedValue, setDebouncedValue] = useState<string | null>(null);
	const [value, setValue] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const currentCountriesPage = useSelector(selectCurrentPage);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), 500);
		return () => clearTimeout(timer);
	}, [value]);

	useEffect(() => {
		const controller = new AbortController();

		setIsLoading(true);
		if (debouncedValue) {
			http
				.get<CountriesListAPI>(
					`${Routes.Countries}?itemsPerPage=10&title=${debouncedValue}`,
					ListPaginationConfig
				)
				.then((res) => {
					dispatch(addCountries(res.data));
					setIsLoading(false);
				})
				.catch((e: Error) => {
					console.error(e);
				});
		}

		return () => controller.abort();
	}, [debouncedValue]);

	const handlePartnerSearchValue = (searchVal: string) => {
		setValue(searchVal);
	};

	const handleLoadMoreCountries = () => {
		setIsLoading(true);
		http
			.get<CountriesListAPI>(
				`${Routes.Countries}?itemsPerPage=10&page=${currentCountriesPage + 1}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setCurrentPage({ currentPage: res.meta.currentPage }));
				dispatch(addCountries(res.data));
			})
			.catch((e: Error) => {
				console.error(e);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const countries = useSelector(selectAllCountriesIdNamePhoneCode);

	const country = useSelector(selectCountry);

	const handleCountrySelect = (option: Option) => {
		if (country?.id === option?.id) return;

		dispatch(setCountry(!option ? null : option));
	};

	return (
		<FormFieldsContainer>
			<Field
				as={TextField}
				name={PartnerStateFields.addressLine1}
				type="text"
				label={intl.formatMessage({
					id: 'partners.edit.create.address.line.1',
				})}
			/>
			<Field
				as={TextField}
				name="addressLine2"
				type="text"
				label={intl.formatMessage({
					id: 'partners.edit.create.address.line.2',
				})}
			/>
			<Field
				as={SelectField}
				options={countries}
				value={country}
				onChange={handleCountrySelect}
				name={PartnerStateFields.country}
				type="object"
				label={intl.formatMessage({
					id: 'partners.edit.create.country',
				})}
				onInputChange={handlePartnerSearchValue}
				loading={isLoading}
				handleLoadMore={handleLoadMoreCountries}
			/>
			<Field
				as={TextField}
				name={PartnerStateFields.city}
				type="text"
				label={intl.formatMessage({
					id: 'partners.edit.create.city',
				})}
			/>
			<Field
				as={TextField}
				name={PartnerStateFields.postalCode}
				type="text"
				label={intl.formatMessage({
					id: 'partners.edit.create.postal.code',
				})}
			/>
		</FormFieldsContainer>
	);
};
