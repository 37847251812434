import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { http } from 'core/ApiClient';
import moment from 'moment';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import { TabPanel, TabLabel } from 'components/Table';
import {
	TabId,
	PartnerForm,
	PurePartner,
	ApiPartnerState,
	PartnerStateFields,
	CommissionTypeNames,
} from '../types';
import { SnackbarAlert } from 'components/Snackbar';

import { General } from '../parts/General';
import { Address } from '../parts/Address';
import { Users } from '../parts/Users';
import { ApiAccess } from '../parts/ApiAccess';
import { BankDetails } from '../parts/BankDetails';
import { Logs } from '../parts/Logs';
import { selectPartner, selectAuthenticationToken } from '../selectors';
import { isTabHasErrors } from 'utils/helpers';
import { Routes } from 'types/types';
import { DateFormats } from 'components/FormFields';
import { AMOUNT_MULTIPLIER, Error } from 'core/types';

import {
	generalTabRequiredFields,
	addressTabRequiredFields,
	bankDetailsTabRequiredFields,
} from '../constants';
import { PartnerState } from 'features/Partners/types';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { selectLocale } from 'features/LanguageSelector';
import { setPartner } from '../reducer/partnerReducer';

export const PartnerTabs: React.FC = () => {
	const [tabId, setTabId] = useState<number>(TabId.General);
	const [apiError, setApiError] = useState<Record<string, string> | null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);

	const intl = useIntl();

	const partner = useSelector(selectPartner);
	const authenticationToken = useSelector(selectAuthenticationToken);
	const locale = useSelector(selectLocale);
	const dispatch = useDispatch();

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabId(newValue);
	};

	const initialValues: PartnerForm = {
		[PartnerStateFields.id]: partner.id,
		[PartnerStateFields.legalEntityName]: partner.company.name,
		[PartnerStateFields.legalEntityCode]: partner.company.entityCode,
		[PartnerStateFields.vatNumber]: partner.vat.number,
		[PartnerStateFields.website]: partner.company.website,
		[PartnerStateFields.commissionAmount]: partner.commission.amount,
		[PartnerStateFields.commissionType]: partner.commission.type,
		[PartnerStateFields.createdAt]: moment(partner.createdAt)
			.format(DateFormats.YYYY_MM_DD_HH_MM)
			.toString(),
		[PartnerStateFields.state]:
			partner.state === PartnerState.Active
				? true
				: partner.id === ''
				? true
				: false,
		[PartnerStateFields.addressLine1]: partner.address.firstLine,
		[PartnerStateFields.addressLine2]: partner.address.secondLine,
		[PartnerStateFields.country]: partner.address.country,
		[PartnerStateFields.city]: partner.address.city,
		[PartnerStateFields.postalCode]: partner.address.postalCode,
		[PartnerStateFields.authenticationToken]: authenticationToken || '',
		[PartnerStateFields.bankTitle]: partner.bankDetails.title,
		[PartnerStateFields.swiftCode]: partner.bankDetails.swiftCode,
		[PartnerStateFields.iban]: partner.bankDetails.ibanAccount,
		[PartnerStateFields.bankAddress]: partner.bankDetails.address,
		[PartnerStateFields.bankCountry]: partner.bankDetails.country,
	};

	const validationSchema = yup.object().shape({
		[PartnerStateFields.legalEntityName]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.legalEntityCode]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.website]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.addressLine1]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.country]: yup
			.object()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.city]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.postalCode]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.bankTitle]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.swiftCode]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.iban]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.bankAddress]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[PartnerStateFields.bankCountry]: yup
			.object()
			.required(intl.formatMessage({ id: 'form.field.required' })),
	});

	const handleSubmit = async (
		values: PartnerForm,
		{ setErrors, setFieldValue }: FormikHelpers<PartnerForm>
	) => {
		setApiError(null);
		const apiPartner: ApiPartnerState = {
			address: {
				city: values.city,
				country: values.country?.name || '',
				firstLine: values.addressLine1,
				secondLine: values.addressLine2,
				postalCode: values.postalCode,
			},
			bankDetails: {
				address: values.bankAddress,
				country: values.bankCountry?.name || '',
				ibanAccount: values.iban,
				swiftCode: values.swiftCode,
				title: values.bankTitle,
			},
			commission: {
				amount:
					values.commissionType?.name === CommissionTypeNames.fixed
						? values.commissionAmount * AMOUNT_MULTIPLIER
						: values.commissionAmount,
				type: values.commissionType?.name || '',
			},
			company: {
				entityCode: values.legalEntityCode,
				name: values.legalEntityName,
				website: values.website,
			},
			createdAt: values.createdAt,
			state: values.state ? PartnerState.Active : PartnerState.Deactivated,
			vat: {
				number: values.vatNumber,
				exists: !!values.vatNumber,
			},
		};
		if (values.id) {
			await http
				.put<ApiPartnerState, PurePartner>(
					`${Routes.Partners}/${values.id}`,
					apiPartner
				)
				.then((res) => {
					setIsOpen(true);
					dispatch(setPartner(res));
				})
				.catch((e: Error) => {
					const resolvedErrors = resolveApiErrors(
						e.cause.violations,
						PartnerStateFields,
						locale
					);
					if (resolvedErrors.formErrors) {
						setErrors(resolvedErrors.formErrors);
					}
					setApiError(resolvedErrors.globalErrors);
					setIsOpenSnack(true);
				});
		}

		if (!values.id) {
			await http
				.post<ApiPartnerState, PurePartner>(Routes.Partners, apiPartner)
				.then((res) => {
					setIsOpen(true);
					setFieldValue(PartnerStateFields.id, res.id);
					setFieldValue(PartnerStateFields.createdAt, res.createdAt);
					dispatch(setPartner(res));
				})
				.catch((e: Error) => {
					const resolvedErrors = resolveApiErrors(
						e.cause.violations,
						PartnerStateFields,
						locale
					);
					if (resolvedErrors.formErrors) {
						setErrors(resolvedErrors.formErrors);
					}
					setApiError(resolvedErrors.globalErrors);
					setIsOpenSnack(true);
				});
		}
	};

	return (
		<>
			<SnackbarAlert
				isOpen={isOpen}
				type="success"
				handleClose={() => setIsOpen(false)}
				message={intl.formatMessage({
					id: 'api.request.was.successfully',
				})}
			/>
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={isOpenSnack && !!apiError && !!Object.keys(apiError).length}
				handleClose={() => setIsOpenSnack(false)}
			/>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values }) => (
					<Form noValidate autoComplete="off">
						<Box m={2} sx={{ background: 'white' }}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs
									value={tabId}
									onChange={handleChange}
									variant="scrollable"
									aria-label="partners edit tabs"
								>
									<TabLabel
										label={intl.formatMessage({
											id: 'partners.edit.create.tab.general',
										})}
										index={TabId.General}
										value={TabId.General}
										hasError={isTabHasErrors(
											generalTabRequiredFields,
											errors,
											touched
										)}
									/>
									<TabLabel
										label={intl.formatMessage({
											id: 'partners.edit.create.tab.address',
										})}
										index={TabId.Address}
										value={TabId.Address}
										hasError={isTabHasErrors(
											addressTabRequiredFields,
											errors,
											touched
										)}
									/>
									{values.id && (
										<TabLabel
											label={intl.formatMessage({
												id: 'partners.edit.create.tab.users',
											})}
											index={TabId.Users}
											value={TabId.Users}
										/>
									)}
									{values.id && (
										<TabLabel
											label={intl.formatMessage({
												id: 'partners.edit.create.tab.api.access',
											})}
											index={TabId.ApiAccess}
											value={TabId.ApiAccess}
										/>
									)}
									<TabLabel
										label={intl.formatMessage({
											id: 'partners.edit.create.tab.bank.details',
										})}
										index={TabId.BankDetails}
										value={TabId.BankDetails}
										hasError={isTabHasErrors(
											bankDetailsTabRequiredFields,
											errors,
											touched
										)}
									/>
									{/* still not exists */}
									{/* {partnerId && (
										<TabLabel
											label={intl.formatMessage({
												id: 'partners.edit.create.tab.logs',
											})}
											index={TabId.Logs}
											value={TabId.Logs}
										/>
									)} */}
								</Tabs>
							</Box>
							<TabPanel value={tabId} index={TabId.General}>
								<General />
							</TabPanel>
							<TabPanel value={tabId} index={TabId.Address}>
								<Address />
							</TabPanel>
							{values.id && (
								<TabPanel value={tabId} index={TabId.Users} p={0}>
									<Box pt={3} pb={3}>
										<Users partnerId={partner?.id} />
									</Box>
								</TabPanel>
							)}
							{values.id && (
								<TabPanel value={tabId} index={TabId.ApiAccess}>
									<ApiAccess />
								</TabPanel>
							)}
							<TabPanel value={tabId} index={TabId.BankDetails}>
								<BankDetails />
							</TabPanel>
							{/* still not exists */}
							{/* {partnerId && (
								<TabPanel value={tabId} index={TabId.Logs}>
									<Logs />
								</TabPanel>
							)} */}
							<Box paddingBottom={2} paddingTop={2}>
								<Button type="submit" variant="contained" color="primary">
									<FormattedMessage id="partners.edit.create.save.changes" />
								</Button>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
};
