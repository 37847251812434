import React from 'react';
import Box from '@mui/material/Box';

interface Props {
	children: JSX.Element | JSX.Element[];
}

export const FormFieldsContainer: React.FC<Props> = ({ children }) => (
	<Box
		sx={{
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-between',
			'& > :not(style)': { margin: '0px 0px 20px 0px' },
			'& > :nth-of-type(1n)': {
				md: { flexBasis: '45%' },
				xs: { flexBasis: '100%' },
			},
		}}
	>
		{children}
	</Box>
);
