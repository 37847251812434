import React from 'react';
import { useField } from 'formik';

import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface Props {
	fieldName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	errorColor: {
		color: theme.palette.error.main,
	},
}));

export const ErrorBox: React.FC<Props> = ({ fieldName }) => {
	const classes = useStyles();
	const [, meta] = useField(fieldName);

	const error = !!(meta && meta.touched && meta.error);
	const errorText = error && meta.error;

	if (!error) return null;
	return (
		<FormHelperText>
			<span className={classes.errorColor}>{errorText}</span>
		</FormHelperText>
	);
};
