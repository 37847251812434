import { ErrorField, SystemErr } from 'core/types';
import { messages } from 'translations/apiValidations';
import { Locales } from 'features/LanguageSelector';
import { ResolvedErrors } from './types';

const camelCaseToDot = (value: string) => {
	return value
		.split(/(?=[A-Z])/)
		.join('.')
		.toLocaleLowerCase();
};

export const resolveApiErrors = <T extends string, TEnumValue extends string>(
	violations: ErrorField[],
	fields: { [key in string]: TEnumValue | T },
	locale: Locales
): ResolvedErrors => {
	if (!violations || !violations.length)
		return { globalErrors: {}, formErrors: {} };

	const formErrors = {};
	const globalErrors = {};
	const fieldKeys = Object.keys(fields) || [];

	violations.forEach((v) => {
		const message = messages[locale][v.code];
		if (v.propertyPath === SystemErr.systemErr) {
			Object.assign(globalErrors, { [v.code]: message });
		} else {
			fieldKeys.forEach((key) => {
				if (
					v.propertyPath.includes(fields[key]) ||
					v.propertyPath.includes(camelCaseToDot(fields[key]))
				) {
					const errorText = !message ? messages[locale][400] : message;
					Object.assign(formErrors, { [key]: errorText });
				}
			});
		}

		if (!fieldKeys.length && !Object.keys(formErrors).length && violations) {
			violations.forEach((vio) => {
				const errorText = !message ? messages[locale][400] : message;
				Object.assign(globalErrors, { [vio.code]: errorText });
			});
		}
	});

	return {
		globalErrors: Object.keys(globalErrors).length ? globalErrors : null,
		formErrors: Object.keys(formErrors).length ? formErrors : null,
	};
};
