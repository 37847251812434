import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PreviewIcon from '@mui/icons-material/Preview';
import { purchaseViewRoute } from '../constants';

interface Props {
	purchaseId: string;
}

export const PurchasesMoreActions: React.FC<Props> = ({ purchaseId }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				id="actions-button"
				aria-controls={open ? 'menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="menu"
				MenuListProps={{
					'aria-labelledby': 'actions-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => navigate(`${purchaseViewRoute}/${purchaseId}`)}
				>
					<ListItemIcon>
						<PreviewIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>
						<FormattedMessage id="partner.purchase.table.more.actions.view" />
					</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};
