import React from 'react';
import { FormattedMessage } from 'react-intl';

import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';

import { Logout } from './Logout';

export const LogoutButton: React.FC = () => (
	<Logout>
		<Button variant="outlined" startIcon={<LogoutIcon />}>
			<FormattedMessage id="logout.button.title" />
		</Button>
	</Logout>
);
