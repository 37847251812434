/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { Field, Formik, FormikHelpers, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import secureLocalStorage from 'react-secure-storage';
import {
	LoginStorageValues,
	FormValues,
	LoginResponse,
	LoginRequest,
} from '../types';
import { isUserHasAccess, useAuthenticated } from 'utils/helpers';

import { http } from 'core/ApiClient';
import { TextField } from 'components/FormFields';
import { AlertBox } from 'components/AlertBox';
import { ProgressIndicator } from 'components/ProgressIndicator';
import { JwtToken, Error, JwtTokenType } from 'core/types';
import { Routes, ROLE } from 'types/types';
import { setAuth } from '../reducer/authDataReducers';
import { PageRoutes } from 'types';
import login_img from 'assets/images/login_img.png';
import { ForgotPassword } from './ForgotPassword';
import { selectExpTime } from '../selectors';
import { useShowForRole } from 'hooks/useShowForRole';

export const LoginForm: React.FC = () => {
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const intl = useIntl();
	const dispatch = useDispatch();
	const expTime = useSelector(selectExpTime);

	const isAuthenticated = useAuthenticated(expTime);

	const emailS = secureLocalStorage.getItem(LoginStorageValues.Email);
	const passwordS = secureLocalStorage.getItem(LoginStorageValues.Password);
	const isVisibleForUser = useShowForRole([ROLE.User], true);
	const hideNotImplemented = false;

	useEffect(() => {
		if (isAuthenticated) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (location.state?.from) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				navigate(location.state.from);
			} else {
				navigate(
					isVisibleForUser
						? `/${PageRoutes.Dashboard}`
						: hideNotImplemented
						? `/${PageRoutes.AdminDashboard}`
						: `/${PageRoutes.Claims}`
				);
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	}, [isAuthenticated]);

	const initialValues: FormValues = {
		email: emailS?.toString() || '',
		password: passwordS?.toString() || '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email(intl.formatMessage({ id: 'form.field.required' }))
			.required(intl.formatMessage({ id: 'form.field.required' })),
		password: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
	});

	const handleFormSubmit = async (
		values: FormValues,
		formikHelpers: FormikHelpers<FormValues>
	) => {
		setError(null);
		await http
			.post<LoginRequest, LoginResponse>(Routes.Login, {
				email: values.email,
				password: values.password,
			})
			.then((data) => {
				const decodedToken: JwtTokenType = jwt_decode(data.token);

				dispatch(
					setAuth({
						token: data.token,
						refresh_token: data.refresh_token,
						roles: decodedToken.roles,
						exp: decodedToken.exp,
						username: decodedToken.username,
					})
				);
				secureLocalStorage.setItem(JwtToken.Token, data.token);
				secureLocalStorage.setItem(JwtToken.RefreshToken, data.refresh_token);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (location.state?.from) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					navigate(location.state.from);
				} else {
					navigate(
						isUserHasAccess(decodedToken.roles, [ROLE.User], true)
							? `/${PageRoutes.Dashboard}`
							: hideNotImplemented
							? `/${PageRoutes.AdminDashboard}`
							: `/${PageRoutes.Claims}`
					);
				}
			})
			.catch((e: Error) => {
				setError(e.message);
			})
			.finally(() => {
				formikHelpers.setSubmitting(false);
			});
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleFormSubmit}
		>
			{({ isSubmitting }) => (
				<Form noValidate autoComplete="off">
					{/* enable it when we support at least two languages */}
					{/* <Grid
						item
						xs={12}
						display="flex"
						justifyContent="end"
						sx={{ marginBottom: 4 }}
					>
						<LanguageSelector />
					</Grid> */}
					<Grid container wrap="wrap" direction="row" alignItems="center">
						<Grid item xs={12} md={6} sx={{ paddingRight: { md: '20px' } }}>
							<Box
								component="img"
								sx={{
									height: '100%',
									width: '100%',
									borderRadius: '4px',
								}}
								alt="logo"
								src={login_img}
							/>
						</Grid>
						<Grid item xs={12} md={6} flexGrow={1}>
							<Grid
								item
								marginBottom={4}
								display="flex"
								justifyContent="space-between"
							>
								<Typography variant="h5">
									<FormattedMessage id="login.form.title" />
								</Typography>
							</Grid>
							<Grid item marginBottom={2}>
								<Field
									as={TextField}
									name="email"
									type="email"
									label={intl.formatMessage({ id: 'login.form.email' })}
								/>
							</Grid>
							<Grid item marginBottom={2}>
								<Field
									as={TextField}
									name="password"
									type="password"
									label={intl.formatMessage({ id: 'login.form.password' })}
								/>
							</Grid>
							<Grid item display="flex" justifyContent="space-between">
								{isSubmitting ? (
									<ProgressIndicator />
								) : (
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={isSubmitting}
									>
										<FormattedMessage id="login.form.sign.in" />
									</Button>
								)}
								<ForgotPassword />
							</Grid>
							{error && (
								<Grid item marginTop={2} position="absolute">
									<AlertBox message={error} type="error" />
								</Grid>
							)}
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
