import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateFormats } from 'components/FormFields';
import { makeStyles } from '@mui/styles';

interface Props {
	onChange: (value: string | null) => void;
	label: string;
	defaultValue: string | null;
}

dayjs.extend(customParseFormat);

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-root': {
			fontSize: 14,
		},
		'& .MuiInputLabel-root': {
			fontSize: 14,
		},
		'& .MuiSvgIcon-root': {
			fontSize: 20,
		},
		'& .css-i4bv87-MuiSvgIcon-root': {
			fontSize: 20,
		},
	},
});

export const BasicDatePicker: React.FC<Props> = ({
	onChange,
	label,
	defaultValue,
	...props
}) => {
	const classes = useStyles();

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				className={classes.root}
				format="YYYY.MM.DD"
				defaultValue={
					defaultValue !== null && defaultValue !== ''
						? dayjs(defaultValue, DateFormats.YYYY_MM_DD)
						: null
				}
				label={label}
				onChange={(val: Dayjs | null) => {
					onChange(
						val?.isValid() ? dayjs(val).format(DateFormats.YYYY_MM_DD) : null
					);
				}}
				slotProps={{
					textField: {
						size: 'small',
					},
				}}
				{...props}
			/>
		</LocalizationProvider>
	);
};
