import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { selectLocale } from 'features/LanguageSelector';
import { Error } from 'core/types';
import { getStandardAmount } from 'core/helper';
import { Routes, ROLE } from 'types/types';
import { ListPaginationConfig } from 'core/constants';
import moment from 'moment';
import { DateFormats } from 'components/FormFields';
import {
	OrderValues,
	OrderType,
	HeadCells,
	Pagination,
	TableRowProgress,
} from 'components/Table';

import {
	PurchaseAttribute,
	PurchaseListAPI,
	PurchasesTableFilterFieldsAPI,
} from '../types';
import { purchasesTableHeadCells } from '../constants';
import { selectPagination } from '../selectors';
import {
	setCurrentPage,
	setItemsPerPage,
	setPagination,
} from '../reducer/purchasesReducer';
import { http } from 'core/ApiClient';
import { ShowForRole } from 'utils/ShowForRole';
import { getFetchParams } from 'utils/helpers';
import { ApiErrorsSnackbar, resolveApiErrors } from 'features/ApiErrors';
import { useFetch } from 'core/hooks';
import { PurchasesMoreActions } from './PurchasesMoreActions';

export const PurchasesTable: React.FC = () => {
	const [order, setOrder] = useState<OrderType>(OrderValues.Asc);
	const [orderBy, setOrderBy] = useState<string>('');
	const [apiError, setApiError] = useState<Record<string, string> | null>();
	const [loading, setLoading] = useState<boolean>(false);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);
	const [data, setData] = useState<PurchaseAttribute[] | null>(null);

	const pagination = useSelector(selectPagination);
	const locale = useSelector(selectLocale);
	const dispatch = useDispatch();

	const purchases = useFetch<PurchaseListAPI>(
		`${Routes.Purchases}${getFetchParams(
			{},
			order,
			orderBy,
			pagination.currentPage,
			pagination.itemsPerPage,
			PurchasesTableFilterFieldsAPI
		)}`,
		ListPaginationConfig
	);

	useEffect(() => {
		if (purchases.data) {
			setData(purchases.data.data);
			dispatch(setPagination(purchases.data.meta));
		}
	}, [purchases.isLoading]);

	const handlePageChange = async (newPageVal: number) => {
		setLoading(true);

		await http
			.get<PurchaseListAPI>(
				`${Routes.Purchases}${getFetchParams(
					{},
					order,
					orderBy,
					newPageVal + 1,
					pagination.itemsPerPage,
					PurchasesTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				setData(res.data);
				dispatch(setCurrentPage({ currentPage: newPageVal + 1 }));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRowsPerPage = async (rowsPerP: number) => {
		setLoading(true);
		await http
			.get<PurchaseListAPI>(
				`${Routes.Purchases}${getFetchParams(
					{},
					order,
					orderBy,
					1,
					rowsPerP,
					PurchasesTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				setData(res.data);
				dispatch(setItemsPerPage({ itemsPerPage: rowsPerP }));
				dispatch(setCurrentPage({ currentPage: 1 }));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRequestSort = (property: string) => {
		const isAsc = orderBy === property && order === OrderValues.Asc;
		setOrder(isAsc ? OrderValues.Desc : OrderValues.Asc);
		setOrderBy(property);
	};

	return (
		<ShowForRole roles={[ROLE.Admin, ROLE.User]}>
			{apiError ? (
				<ApiErrorsSnackbar
					messages={apiError}
					type="error"
					isOpen={isOpenSnack}
					handleClose={() => setIsOpenSnack(false)}
				/>
			) : (
				<Box>
					<TableContainer>
						<Table aria-labelledby="claimsTable" size="small">
							<HeadCells
								headCells={purchasesTableHeadCells}
								orderBy={orderBy}
								order={order}
								handleRequestSort={handleRequestSort}
							/>
							{purchases.isLoading || loading ? (
								<TableRowProgress headCellsCount={8} />
							) : (
								<TableBody>
									{data &&
										data.map(({ attributes }) => (
											<TableRow
												hover
												tabIndex={-1}
												key={attributes._id}
												sx={{ width: '100%' }}
											>
												<TableCell align="left">
													{attributes.claimCode.value}
												</TableCell>
												<TableCell align="left">
													{attributes.partner.name}
												</TableCell>
												<TableCell align="left">
													{`${getStandardAmount(
														attributes.ticketsPrice.amount
													)} ${attributes.ticketsPrice.currency.code}`}
												</TableCell>
												<TableCell align="left">{`${getStandardAmount(
													attributes.skyknightCommission.amount
												)} ${
													attributes.skyknightCommission.currency.code
												}`}</TableCell>
												<TableCell align="left">{`${getStandardAmount(
													attributes.partnerCommission.amount
												)} ${
													attributes.partnerCommission.currency.code
												}`}</TableCell>
												<TableCell align="left">
													{moment(attributes.createdAt)
														.format(DateFormats.YYYY_MM_DD_HH_MM)
														.toString() || ''}
												</TableCell>
												<TableCell align="left">
													maintenance in progress
												</TableCell>
												<TableCell align="left">
													maintenance in progress
												</TableCell>
												<TableCell align="left">
													<PurchasesMoreActions purchaseId={attributes._id} />
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
					<Pagination
						rowsLength={pagination.totalItems}
						page={pagination.currentPage - 1}
						rowsPerPage={pagination.itemsPerPage}
						handleChangePage={(_, newPage) => {
							void handlePageChange(newPage);
						}}
						handleChangeRowsPerPage={(rowsPP) => {
							void handleRowsPerPage(rowsPP);
						}}
					/>
				</Box>
			)}
		</ShowForRole>
	);
};
