import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';

import { ContentWithNavigation } from 'layouts/wrappers/ContentWithNavigationWrapper';
import { PartnersZoneNavigation } from 'features/PartnersZone';
import { Dashboard } from 'features/UsersDashboard';

export const UsersDashboardPage: React.FC = () => (
	<Layout
		header={<Header />}
		main={
			<ContentWithNavigation
				tabsNavigation={<PartnersZoneNavigation />}
				content={<Dashboard />}
			/>
		}
	/>
);
