import React from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import { JwtToken } from 'core/types';
import { PageRoutes } from 'types';

import { resetAuth } from '../reducer/authDataReducers';

interface Props {
	children: React.ReactElement;
}

export const Logout: React.FC<Props> = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = () => {
		secureLocalStorage.removeItem(JwtToken.Token);
		secureLocalStorage.removeItem(JwtToken.RefreshToken);
		dispatch(resetAuth());
		navigate(`/${PageRoutes.Login}`);
	};

	return <Typography onClick={() => handleLogout()}>{children}</Typography>;
};
