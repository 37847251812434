import * as React from 'react';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { HeadCell, OrderType, OrderValues } from '../types';

interface Props {
	headCells: HeadCell[];
	orderBy: string;
	order: OrderType;
	handleRequestSort: (headCellId: string) => void;
}

export const HeadCells: React.FC<Props> = ({
	headCells,
	orderBy,
	order,
	handleRequestSort,
}) => (
	<TableHead>
		<TableRow>
			{headCells.map((headCell) => (
				<TableCell
					key={headCell.id}
					align="center"
					padding={headCell.disablePadding ? 'none' : 'normal'}
					sortDirection={orderBy === headCell.id ? order : false}
				>
					{headCell.label && (
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : OrderValues.Asc}
							onClick={() => handleRequestSort(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					)}
				</TableCell>
			))}
		</TableRow>
	</TableHead>
);
