import type { State } from 'redux-store/Store';

export const selectClaimStatus = (state: State) =>
	state.claimViewStatusForm.state;

export const selectClaimEntity = (state: State) => state.claimViewStatusForm;
export const selectClaimContactDetails = (state: State) =>
	state.claimViewStatusForm.contact;
export const selectClaimFlightDetails = (state: State) => ({
	...state.claimViewStatusForm.destination,
	...state.claimViewStatusForm.disruption,
	...state.claimViewStatusForm.flightDetails,
});
