import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import {
	SelectField,
	Option,
	TextField,
	CheckboxField,
} from 'components/FormFields';
import {
	selectPartnersNames,
	addPartnerCompanyNames,
	PartnersListAPI,
} from 'features/Partners';
import { http } from 'core/ApiClient';
import { Routes } from 'types/types';
import { Error } from 'core/types';

import { selectPartner, selectUserId } from '../selectors';
import { setPartner } from '../reducer/userReducer';
import { UserStateFields } from '../types';
import { ListPaginationConfig } from 'core/constants';

export const General: React.FC = () => {
	const [debouncedValue, setDebouncedValue] = useState<string | null>(null);
	const [value, setValue] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [page, setPage] = useState<number>(1);

	const intl = useIntl();
	const dispatch = useDispatch();
	const partners = useSelector(selectPartnersNames);

	const partner = useSelector(selectPartner);
	const userId = useSelector(selectUserId);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), 500);
		return () => clearTimeout(timer);
	}, [value]);

	useEffect(() => {
		const controller = new AbortController();

		setIsLoading(true);
		if (debouncedValue) {
			http
				.get<PartnersListAPI>(
					`${Routes.Partners}?itemsPerPage=10&groups[]=select&company.name=${debouncedValue}`,
					ListPaginationConfig
				)
				.then((res) => {
					dispatch(addPartnerCompanyNames(res.data));
					setIsLoading(false);
				})
				.catch((e: Error) => {
					// eslint-disable-next-line no-console
					console.error(e);
				});
		}

		return () => controller.abort();
	}, [debouncedValue]);

	const handlePartnerSelect = (option: Option) => {
		if (partner?.id === option?.id) return;
		dispatch(setPartner(option));
	};

	const handlePartnerSearchValue = (searchVal: string) => {
		setValue(searchVal);
	};

	const handleLoadMorePartners = () => {
		setIsLoading(true);
		http
			.get<PartnersListAPI>(
				`${Routes.Partners}?itemsPerPage=10&page=${page + 1}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(addPartnerCompanyNames(res.data));
				setPage(res.meta.currentPage);
			})
			.catch((e: Error) => {
				console.error(e);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			{!!userId && (
				<FormFieldsContainer>
					<Field
						disabled
						as={TextField}
						name={UserStateFields.id}
						type="text"
						label={intl.formatMessage({
							id: 'partner.user.edit.create.user.id',
						})}
					/>
				</FormFieldsContainer>
			)}
			<FormFieldsContainer>
				<Field
					as={TextField}
					name={UserStateFields.firstName}
					type="text"
					label={intl.formatMessage({
						id: 'partner.user.edit.create.first.name',
					})}
				/>
				<Field
					as={TextField}
					name={UserStateFields.lastName}
					type="text"
					label={intl.formatMessage({
						id: 'partner.user.edit.create.last.name',
					})}
				/>
				<Field
					as={TextField}
					name={UserStateFields.email}
					type="text"
					label={intl.formatMessage({
						id: 'partner.user.edit.create.email',
					})}
				/>
				<Field
					as={TextField}
					name={UserStateFields.phoneNumber}
					type="text"
					label={intl.formatMessage({
						id: 'partner.user.edit.create.phone',
					})}
				/>
				<Field
					as={SelectField}
					options={partners}
					value={partner}
					loading={isLoading}
					onChange={handlePartnerSelect}
					name={UserStateFields.partner}
					type="object"
					label={intl.formatMessage({
						id: 'partner.user.edit.create.partner.name',
					})}
					onInputChange={handlePartnerSearchValue}
					handleLoadMore={handleLoadMorePartners}
				/>
			</FormFieldsContainer>
			{!!userId && (
				<FormFieldsContainer>
					<Field
						disabled
						as={TextField}
						name={UserStateFields.createdAt}
						type="text"
						label={intl.formatMessage({
							id: 'partner.user.edit.create.created.at',
						})}
					/>
				</FormFieldsContainer>
			)}
			<CheckboxField
				name={UserStateFields.primaryContact.toString()}
				label={intl.formatMessage({
					id: 'partner.user.edit.create.company.is.primary',
				})}
			/>
		</>
	);
};
