import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import { useFetch } from 'core/hooks';
import { Routes, ROLE } from 'types/types';
import { ApiErrorsSnackbar, resolveApiErrors } from 'features/ApiErrors';
import { Error } from 'core/types';
import { ListPaginationConfig } from 'core/constants';
import { FiltersRow, FilterType } from 'components/Filters';
import { generateFilters } from '../helpers';
import { getFetchParams } from 'utils/helpers';
import {
	OrderValues,
	OrderType,
	HeadCells,
	Pagination,
	TableRowProgress,
} from 'components/Table';

import { ShowForRole } from 'utils/ShowForRole';
import { usersTableHeadCells } from '../constants';
import {
	User,
	PrimaryContactRequestApi,
	UsersListAPI,
	UsersTableFilterFields,
	UsersTableFilterFieldsAPI,
} from '../types';
import { UsersMoreActions } from './UsersMoreActions';
import {
	setUsers,
	removeUser,
	setPagination,
	setItemsPerPage,
	setCurrentPage,
	setFilterValue,
	setPartnerIdFilter,
} from '../reducer/usersReducer';
import {
	selectAllUsers,
	selectPagination,
	selectFilterValues,
} from '../selectors';
import { http } from 'core/ApiClient';
import { selectLocale } from 'features/LanguageSelector';
import { DateFormats } from 'components/FormFields';

interface Props {
	moreActions?: (
		userId: string,
		handleUserDelete: (userId: string) => void,
		isPrimary: boolean
	) => React.ReactNode;
	partnerId?: string;
	refetch?: boolean;
}

export const UsersTable: React.FC<Props> = ({
	moreActions,
	partnerId,
	refetch,
}) => {
	const [order, setOrder] = useState<OrderType>(OrderValues.Asc);
	const [orderBy, setOrderBy] = useState<string>('');
	const [apiError, setApiError] = useState<Record<string, string> | null>();
	const [loading, setLoading] = useState<boolean>(false);
	const [tableFilters, setTableFilters] = useState<
		FilterType<UsersTableFilterFields>[]
	>([]);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);

	const dispatch = useDispatch();
	const intl = useIntl();

	const pagination = useSelector(selectPagination);
	const filterValues = useSelector(selectFilterValues);
	const allUsers = useSelector(selectAllUsers);
	const locale = useSelector(selectLocale);

	const users = useFetch<UsersListAPI>(
		`${Routes.Users}${getFetchParams(
			{ ...filterValues, partnerId: partnerId || '' },
			order,
			orderBy,
			pagination.currentPage,
			pagination.itemsPerPage,
			UsersTableFilterFieldsAPI
		)}`,
		ListPaginationConfig
	);

	useEffect(() => {
		if (users.data) {
			dispatch(setUsers(users.data.data));
			dispatch(setPagination(users.data.meta));
			dispatch(setPartnerIdFilter(partnerId || ''));
		}
	}, [users.isLoading]);

	const fetchUser = async () => {
		await http
			.get<UsersListAPI>(
				`${Routes.Users}${getFetchParams(
					filterValues,
					order,
					orderBy,
					pagination.currentPage,
					pagination.itemsPerPage,
					UsersTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setUsers(res.data));
				dispatch(setPagination(res.meta));
			});
	};

	useEffect(() => {
		if (refetch) {
			setLoading(true);
			fetchUser()
				.catch((e: Error) => {
					setApiError(
						resolveApiErrors(e.cause.violations, {}, locale).globalErrors
					);
					setLoading(false);
					setIsOpenSnack(true);
				})
				.finally(() => setLoading(false));
		}
	}, [refetch]);

	useEffect(() => {
		const generatedFilters = generateFilters(filterValues, intl);
		setTableFilters(generatedFilters);
	}, [filterValues]);

	const handleUserDelete = async (id: string) => {
		setLoading(true);
		setApiError(null);

		const deleteUser = async () => {
			await http.delete(`${Routes.Users}/${id}`).then(() => {
				dispatch(removeUser({ id }));
			});
		};

		await deleteUser()
			.then(() =>
				fetchUser().then(() => {
					setLoading(false);
				})
			)
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
				setLoading(false);
			});
	};

	const handleRequestSort = async (property: string) => {
		const isAsc = orderBy === property && order === OrderValues.Asc;
		const orderV = isAsc ? OrderValues.Desc : OrderValues.Asc;
		setOrder(orderV);
		setOrderBy(property);

		setLoading(true);
		await http
			.get<UsersListAPI>(
				`${Routes.Users}${getFetchParams(
					filterValues,
					property,
					orderV,
					pagination.currentPage,
					pagination.itemsPerPage,
					UsersTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setUsers(res.data));
				dispatch(setPagination(res.meta));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRowsPerPage = async (rowsPerP: number) => {
		setLoading(true);
		await http
			.get<UsersListAPI>(
				`${Routes.Users}${getFetchParams(
					filterValues,
					order,
					orderBy,
					1,
					rowsPerP,
					UsersTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setUsers(res.data));
				dispatch(setItemsPerPage({ itemsPerPage: rowsPerP }));
				dispatch(setCurrentPage({ currentPage: 1 }));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handlePageChange = async (newPageVal: number) => {
		setLoading(true);

		await http
			.get<UsersListAPI>(
				`${Routes.Users}${getFetchParams(
					filterValues,
					order,
					orderBy,
					newPageVal + 1,
					pagination.itemsPerPage,
					UsersTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setUsers(res.data));
				dispatch(setCurrentPage({ currentPage: newPageVal + 1 }));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handlePrimaryContact = async (id: string) => {
		setApiError(null);
		setLoading(true);

		const setPrimaryPromise = async () => {
			await http.put<PrimaryContactRequestApi, User>(
				`${Routes.Users}/${id}${Routes.PrimaryContact}`,
				{
					primaryContact: true,
				}
			);
		};

		await setPrimaryPromise()
			.then(() =>
				fetchUser().then(() => {
					setLoading(false);
				})
			)
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			});
	};

	const handleFilters = async (name: string, value: string) => {
		if (!name) return;
		setApiError(null);
		setLoading(true);

		dispatch(
			setFilterValue({ filterName: name as UsersTableFilterFields, value })
		);

		await http
			.get<UsersListAPI>(
				`${Routes.Users}${getFetchParams(
					{ ...filterValues, [name]: value },
					order,
					orderBy,
					pagination.currentPage,
					pagination.itemsPerPage,
					UsersTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setUsers(res.data));
				dispatch(setPagination(res.meta));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (!users.isLoading && users.error) {
		return (
			<ApiErrorsSnackbar
				messages={users.error}
				type="error"
				isOpen={!users.isLoading && !!users.error}
			/>
		);
	}

	return (
		<ShowForRole roles={[ROLE.Admin, ROLE.User]}>
			<>
				{apiError && (
					<ApiErrorsSnackbar
						messages={apiError}
						type="error"
						isOpen={isOpenSnack}
						handleClose={() => {
							setIsOpenSnack(false);
							setApiError(null);
						}}
					/>
				)}
				<Box>
					<TableContainer>
						<Table aria-labelledby="usersTable" size="small">
							<FiltersRow<UsersTableFilterFields>
								filters={tableFilters}
								handleFilter={(name, value) => void handleFilters(name, value)}
							/>
							<HeadCells
								headCells={usersTableHeadCells}
								orderBy={orderBy}
								order={order}
								handleRequestSort={(sortVal) => void handleRequestSort(sortVal)}
							/>
							{users.isLoading || loading ? (
								<TableRowProgress headCellsCount={9} />
							) : (
								<TableBody>
									{allUsers.map((row) => (
										<TableRow
											hover
											tabIndex={-1}
											key={row.id}
											sx={{ width: '100%' }}
										>
											{/* <TableCell align="center">{row.id}</TableCell> */}
											<TableCell align="left">{row.firstName}</TableCell>
											<TableCell align="left">{row.lastName}</TableCell>
											<TableCell align="left">{row.email}</TableCell>
											<TableCell align="left">{row.partner?.name}</TableCell>
											<TableCell align="left">{row.phoneNumber}</TableCell>
											<TableCell align="center">
												<Radio
													checked={row.primaryContact}
													onClick={
														!row.primaryContact
															? () => {
																	void (async () => {
																		await handlePrimaryContact(row.id);
																	})();
															  }
															: undefined
													}
													name="primary-contact"
												/>
											</TableCell>
											<TableCell align="center">
												{moment(row.createdAt)
													.format(DateFormats.YYYY_MM_DD_HH_MM)
													.toString()}
											</TableCell>
											<TableCell align="center">
												{(moreActions && (
													<>
														{moreActions(
															row.id,
															() =>
																void (async () => {
																	await handleUserDelete(row.id);
																})(),
															row.primaryContact
														)}
													</>
												)) || (
													<UsersMoreActions
														isPrimary={row.primaryContact}
														userId={row.id}
														handleUserDelete={() =>
															void (async () => {
																await handleUserDelete(row.id);
															})()
														}
													/>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
					<Pagination
						rowsLength={pagination.totalItems}
						page={pagination.currentPage - 1}
						rowsPerPage={pagination.itemsPerPage}
						handleChangePage={(_, newPage) => {
							void handlePageChange(newPage);
						}}
						handleChangeRowsPerPage={(rowsPP) => {
							void handleRowsPerPage(rowsPP);
						}}
					/>
				</Box>
			</>
		</ShowForRole>
	);
};
