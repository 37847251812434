import { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from 'axios';
import secureLocalStorage from 'react-secure-storage';
import {
	JwtToken,
	RefreshJwtResponse,
	RefreshJwtRequest,
	JwtRefreshEvent,
	HttpCodes,
} from './types';
import { Routes } from 'types/types';
import { PageRoutes } from 'types';
import { useAuthenticated, getDecodedJwtToken } from 'utils/helpers';

const onResponseError = async (
	error: AxiosError,
	axiosInstance: AxiosInstance
): Promise<AxiosError> => {
	const originalConfig = error.config as InternalAxiosRequestConfig & {
		_retry: boolean;
	};

	const jwtRefreshToken =
		secureLocalStorage.getItem(JwtToken.RefreshToken)?.toString() || '';

	if (
		error.response?.status === HttpCodes.Unauthorized &&
		!originalConfig?._retry &&
		error?.config?.url !== Routes.Login
	) {
		originalConfig._retry = true;
		try {
			const rs = await axiosInstance.post<
				RefreshJwtRequest,
				RefreshJwtResponse
			>(
				Routes.AuthRefresh,
				{
					refresh_token: jwtRefreshToken,
				},
				originalConfig
			);

			const token = rs.token;
			const refreshToken = rs.refresh_token;

			secureLocalStorage.setItem(JwtToken.Token, token);
			secureLocalStorage.setItem(JwtToken.RefreshToken, refreshToken);

			axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;

			window.dispatchEvent(new Event(JwtRefreshEvent.RefreshEvent));

			return await axiosInstance(originalConfig);
		} catch (e) {
			if (e) {
				secureLocalStorage.removeItem(JwtToken.Token);
				secureLocalStorage.removeItem(JwtToken.RefreshToken);

				if (
					error.response.status.toString() ===
						HttpCodes.Unauthorized.toString() ||
					error.response.status.toString() === HttpCodes.Forbidden.toString()
				) {
					window.location.replace(`/${PageRoutes.Login}`);
				}

				return Promise.reject(e);
			}

			return Promise.reject(error);
		}
	}

	return Promise.reject({
		status: error.response?.status,
		error: error.response?.data,
	});
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance) => {
	axiosInstance.interceptors.request.use((config) => {
		const jwtToken =
			secureLocalStorage.getItem(JwtToken.Token)?.toString() || '';
		const authData = getDecodedJwtToken();
		const isLoggedIn = useAuthenticated(authData?.exp);

		if (config.url !== Routes.Login) {
			config.headers.Authorization = `Bearer ${jwtToken}`;
		} else if (jwtToken === '' || !isLoggedIn) {
			config.headers.Authorization = null;
		}
		return config;
	});
	axiosInstance.interceptors.response.use(
		(response) => response,
		(error: AxiosError) => onResponseError(error, axiosInstance)
	);
};
