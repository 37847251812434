import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import { PageRoutes } from '../types';
import {
	useAuthenticated,
	isUserHasAccess,
	getDecodedJwtToken,
} from './helpers';
import { ROLE } from 'types/types';
import { AccessDeniedPage } from 'pages/AccessDeniedPage';

interface Props {
	requiredRoles: Array<ROLE>;
	strict?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({
	requiredRoles,
	strict = false,
}) => {
	const location = useLocation();
	const authData = getDecodedJwtToken();
	const isLoggedIn = useAuthenticated(authData?.exp);

	const userHasRequiredRole = isUserHasAccess(
		authData?.roles,
		requiredRoles,
		strict
	);
	if (!isLoggedIn)
		return (
			<Navigate to={PageRoutes.Login} replace state={{ from: location }} />
		);

	if (isLoggedIn && !userHasRequiredRole) return <AccessDeniedPage />;

	return <Outlet />;
};
