import { FormattedMessage } from 'react-intl';

import { HeadCell } from 'components/Table';
import { PartnerStateFields, CommissionTypeNames } from './types';

export const generalTabRequiredFields: string[] = [
	PartnerStateFields.legalEntityName,
	PartnerStateFields.legalEntityCode,
	PartnerStateFields.website,
];

export const addressTabRequiredFields: string[] = [
	PartnerStateFields.addressLine1,
	PartnerStateFields.country,
	PartnerStateFields.city,
	PartnerStateFields.postalCode,
];

export const bankDetailsTabRequiredFields: string[] = [
	PartnerStateFields.bankTitle,
	PartnerStateFields.swiftCode,
	PartnerStateFields.iban,
	PartnerStateFields.bankAddress,
	PartnerStateFields.bankCountry,
];

export const commissionTypes = [
	{
		name: CommissionTypeNames.percentage,
		id: CommissionTypeNames.percentage,
	},
	{
		name: CommissionTypeNames.fixed,
		id: CommissionTypeNames.fixed,
	},
];

export const logsTableHeadCells: HeadCell[] = [
	{
		id: 'logId',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.edit.create.logs.table.head.log.id" />
		),
	},
	{
		id: 'user',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partners.edit.create.logs.table.head.user" />,
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partners.edit.create.logs.table.head.email" />,
	},
	{
		id: 'companyName',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.edit.create.logs.table.head.company.name" />
		),
	},
	{
		id: 'actionTimestamp',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.edit.create.logs.table.head.action.timestamp" />
		),
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.edit.create.logs.table.head.action" />
		),
	},
	{
		id: 'oldValue',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.edit.create.logs.table.head.old.value" />
		),
	},
	{
		id: 'newValue',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partners.edit.create.logs.table.head.new.value" />
		),
	},
];
