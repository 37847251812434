import React from 'react';
import { useSelector } from 'react-redux';

import { isUserHasAccess } from './helpers';
import { ROLE } from 'types/types';
import { selectUserRoles } from 'features/LoginForm';

interface Props {
	roles: Array<ROLE>;
	children: JSX.Element;
}

export const ShowForRole: React.FC<Props> = ({ roles, children }) => {
	const userRoles = useSelector(selectUserRoles);
	const isVisible = isUserHasAccess(userRoles, roles);

	if (isVisible) return children;

	return null;
};
