import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';
import { SwitchByUserRole } from 'components/Switcher';

import { ContentWithNavigation } from 'layouts/wrappers/ContentWithNavigationWrapper';
import { PartnersZoneNavigation } from 'features/PartnersZone';

export const InvoicesPage: React.FC = () => (
	<Layout
		header={<Header />}
		main={
			<ContentWithNavigation
				tabsNavigation={<PartnersZoneNavigation />}
				content={
					<SwitchByUserRole
						userChildren={
							<div>OOPS....Partner invoices maintenance in progress</div>
						}
						adminChildren={
							<div>OOPS....Admin invoices maintenance in progress</div>
						}
					/>
				}
			/>
		}
	/>
);
