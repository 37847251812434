import {
	claimDestinationSliceDefault,
	claimDisruptionReasonSliceDefault,
	claimAdditionalInformationSliceDefault,
	claimContactFormSliceDefault,
	claimFormReducerDefault,
} from 'features/ClaimForm';
import { authDataSliceDefault } from 'features/LoginForm';
import { localeSliceReducerDefault } from 'features/LanguageSelector';
import { partnerSliceDefault } from 'features/PartnerCreateEdit';
import { userSliceDefault } from 'features/UserCreateEdit';
import { purchaseFormSliceDefault } from 'features/PurchaseView';
import { claimStatusFormSliceDefault } from 'features/ClaimView';
import { myCompanySliceDefault } from 'features/MyCompany';
import { partnersSliceDefault } from 'features/Partners';
import { countrySliceDefault } from 'features/Country';
import { usersSliceDefault } from 'features/Users';
import { airportSliceDefault } from 'features/Airport';
import { claimsSliceDefault } from 'features/Claims';
import { purchasesSliceDefault } from 'features/Purchases';

export const rootReducer = () => ({
	claimDestinationForm: claimDestinationSliceDefault,
	claimDisruptionReasonForm: claimDisruptionReasonSliceDefault,
	claimAdditionalInfoForm: claimAdditionalInformationSliceDefault,
	claimContactForm: claimContactFormSliceDefault,
	globalClaimForm: claimFormReducerDefault,
	authData: authDataSliceDefault,
	locale: localeSliceReducerDefault,
	partner: partnerSliceDefault,
	user: userSliceDefault,
	users: usersSliceDefault,
	partners: partnersSliceDefault,
	purchase: purchaseFormSliceDefault,
	claimViewStatusForm: claimStatusFormSliceDefault,
	myCompany: myCompanySliceDefault,
	countries: countrySliceDefault,
	airports: airportSliceDefault,
	claims: claimsSliceDefault,
	purchases: purchasesSliceDefault,
});
