import { FormattedMessage } from 'react-intl';

import { HeadCell } from 'components/Table';

export const claimsTableHeadCells: HeadCell[] = [
	// {
	// 	id: 'id',
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: <FormattedMessage id="partner.claims.head.cell.label.claim.id" />,
	// },
	{
		id: 'status',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.claims.head.cell.label.status" />,
	},
	{
		id: 'skyKnightCode',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.claims.head.cell.label.skyKnight" />,
	},
	// {
	// 	id: 'soldBy',
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: <FormattedMessage id="partner.claims.head.cell.label.sold.by" />,
	// },
	{
		id: 'departureAirport',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.claims.head.cell.label.disrupted.departure" />
		),
	},
	{
		id: 'arrivalAirport',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.claims.head.cell.label.disrupted.arrival" />
		),
	},
	{
		id: 'details',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.claims.head.cell.label.disrupted.details" />
		),
	},
	{
		id: 'customer',
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.claims.head.cell.label.customer" />,
	},
	{
		id: 'createdTimestamp',
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.claims.head.cell.label.claim.created.timestamp" />
		),
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: true,
		label: '',
	},
];

export const claimViewRoute = '/claim/view';
