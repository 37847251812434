import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';
import ReplayIcon from '@mui/icons-material/Replay';
import Button from '@mui/material/Button';

export const ErrorPage: React.FC = () => {
	const navigate = useNavigate();

	return (
		<Layout
			header={<Header />}
			main={
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						minHeight: '100vh',
					}}
				>
					<Typography variant="h6">
						<FormattedMessage id="error.page.title" />
					</Typography>
					<Button
						onClick={() => navigate(0)}
						variant="outlined"
						startIcon={<ReplayIcon />}
					>
						<FormattedMessage id="error.page.reload.button" />
					</Button>
				</Box>
			}
		/>
	);
};
