export enum Routes {
	Login = '/auth',
	AuthRefresh = '/auth/token/refresh',
	Partners = '/partners',
	PartnerAuthToken = '/partners/new-access-token',
	Users = '/users',
	PrimaryContact = '/primary-contact',
	UserPassword = '/users/new-password',
	Countries = '/countries',
	Claims = '/claims',
	ClaimsFormDisruptionReasons = '/disruption-reasons',
	ClaimFormFlightDetails = '/flight-details',
	ClaimFormContactDetails = '/contact-details',
	Airports = '/airports',
	MyUserPartner = '/users/partner',
	Purchases = '/purchases',
	PurchaseStats = '/purchases-partner-statistics',
	ClaimStats = '/claims-partner-statistics',
}

export enum ROLE {
	Admin = 'ROLE_ADMIN',
	User = 'ROLE_USER',
}
