import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';

import { Partner } from 'features/PartnerCreateEdit';

export const PartnerCreateEditPage: React.FC = () => (
	<Layout header={<Header />} main={<Partner />} />
);
