import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FiltersRow, FilterType } from 'components/Filters';

import {
	OrderValues,
	OrderType,
	HeadCells,
	Pagination,
	TableRowProgress,
} from 'components/Table';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { ListPaginationConfig } from 'core/constants';
import type { State } from 'redux-store/Store';

import { partnersTableHeadCells } from '../constants';
import {
	PartnersListAPI,
	Partner,
	PartnersTableFilterFields,
	PartnerState,
	PartnersTableFiltersFieldsAPI,
} from '../types';
import { PartnerMoreActions } from './PartnersMoreActions';
import { PageRoutes } from 'types';
import { ShowForRole } from 'utils/ShowForRole';
import {
	selectPagination,
	selectFilterValues,
	selectAllPartnersByState,
} from '../selectors';
import { useFetch } from 'core/hooks';
import { Routes, ROLE } from 'types/types';
import { Error } from 'core/types';
import { selectLocale } from 'features/LanguageSelector';
import {
	setPartners,
	setPagination,
	setItemsPerPage,
	setCurrentPage,
	updatePartnerState,
	setFilterState,
	setFilterValue,
} from '../reducer/partnersReducers';
import { http } from 'core/ApiClient';
import { generateFilters, getPartnersFetchParams } from '../helpers';
import { DateFormats } from 'components/FormFields';

export const PartnersTable: React.FC = () => {
	const [order, setOrder] = useState<OrderType>(OrderValues.Asc);
	const [orderBy, setOrderBy] = useState<string>('');
	const [apiError, setApiError] = useState<Record<string, string> | null>();
	const [loading, setLoading] = useState<boolean>(false);
	const [tableFilters, setTableFilters] = useState<
		FilterType<PartnersTableFilterFields>[]
	>([]);
	const [statusActive, setStatusActive] = useState<boolean>(true);
	const [statusDisabled, setStatusDisabled] = useState<boolean>(false);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const intl = useIntl();

	const pagination = useSelector(selectPagination);
	const filterValues = useSelector(selectFilterValues);
	const allPartners = useSelector((state: State) =>
		selectAllPartnersByState(state, filterValues.state)
	);
	const locale = useSelector(selectLocale);

	const partners = useFetch<PartnersListAPI>(
		`${Routes.Partners}${getPartnersFetchParams(
			filterValues,
			order,
			orderBy,
			pagination.currentPage,
			pagination.itemsPerPage,
			PartnersTableFiltersFieldsAPI
		)}`,
		ListPaginationConfig
	);

	useEffect(() => {
		if (partners.data) {
			dispatch(setPartners(partners.data.data));
			dispatch(setPagination(partners.data.meta));
		}
	}, [partners.isLoading]);

	useEffect(() => {
		const generatedFilters = generateFilters(filterValues, intl);
		setTableFilters(generatedFilters);
	}, [filterValues]);

	const handleRequestSort = async (property: string) => {
		const isAsc = orderBy === property && order === OrderValues.Asc;
		const orderV = isAsc ? OrderValues.Desc : OrderValues.Asc;
		setOrder(orderV);
		setOrderBy(property);

		setLoading(true);
		await http
			.get<PartnersListAPI>(
				`${Routes.Partners}${getPartnersFetchParams(
					filterValues,
					property,
					orderV,
					pagination.currentPage,
					pagination.itemsPerPage,
					PartnersTableFiltersFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setPartners(res.data));
				dispatch(setPagination(res.meta));
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handlePartnerStatus = async (state: PartnerState) => {
		setLoading(true);
		setApiError(null);

		await http
			.get<PartnersListAPI>(
				`${Routes.Partners}${getPartnersFetchParams(
					{ ...filterValues, state },
					orderBy,
					order,
					pagination.currentPage,
					pagination.itemsPerPage,
					PartnersTableFiltersFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setPartners(res.data));
				dispatch(setPagination(res.meta));
				dispatch(setFilterState(state));

				if (state === PartnerState.Active) {
					if (!statusActive) {
						setStatusDisabled(false);
					}
					setStatusActive(!statusActive);
				}
				if (state === PartnerState.Deactivated) {
					if (!statusDisabled) {
						setStatusActive(false);
					}
					setStatusDisabled(!statusDisabled);
				}
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleFilters = async (name: string, value: string) => {
		if (!name) return;
		setApiError(null);
		setLoading(true);
		dispatch(
			setFilterValue({ filterName: name as PartnersTableFilterFields, value })
		);

		await http
			.get<PartnersListAPI>(
				`${Routes.Partners}${getPartnersFetchParams(
					{ ...filterValues, [name]: value },
					order,
					orderBy,
					pagination.currentPage,
					pagination.itemsPerPage,
					PartnersTableFiltersFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setPartners(res.data));
				dispatch(setPagination(res.meta));
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRowsPerPage = async (rowsPerP: number) => {
		setLoading(true);
		await http
			.get<PartnersListAPI>(
				`${Routes.Partners}${getPartnersFetchParams(
					filterValues,
					order,
					orderBy,
					1,
					rowsPerP,
					PartnersTableFiltersFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setPartners(res.data));
				dispatch(setItemsPerPage({ itemsPerPage: rowsPerP }));
				dispatch(setCurrentPage({ currentPage: 1 }));
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onPartnerStatusChange = async (
		partnerId: string,
		partnerState: PartnerState
	) => {
		setApiError(null);
		setLoading(true);
		await http
			.put<{ state: PartnerState }, Partner>(
				`${Routes.Partners}/${partnerId}/state`,
				{ state: partnerState }
			)
			.then((res) => {
				dispatch(updatePartnerState({ id: partnerId, state: res.state }));
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handlePageChange = async (newPageVal: number) => {
		setLoading(true);
		setApiError(null);

		await http
			.get<PartnersListAPI>(
				`${Routes.Partners}${getPartnersFetchParams(
					filterValues,
					order,
					orderBy,
					newPageVal + 1,
					pagination.itemsPerPage,
					PartnersTableFiltersFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setPartners(res.data));
				dispatch(setCurrentPage({ currentPage: newPageVal + 1 }));
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (!partners.isLoading && partners.error) {
		return (
			<ApiErrorsSnackbar
				messages={partners.error}
				type="error"
				isOpen={!partners.isLoading && !!partners.error}
			/>
		);
	}

	return (
		<ShowForRole roles={[ROLE.Admin]}>
			{apiError ? (
				<ApiErrorsSnackbar
					messages={apiError}
					type="error"
					isOpen={isOpenSnack}
					handleClose={() => setIsOpenSnack(false)}
				/>
			) : (
				<Box>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							mb: 2,
						}}
					>
						<FormGroup sx={{ display: 'inline' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={statusActive}
										onChange={() =>
											void (async () => {
												await handlePartnerStatus(PartnerState.Active);
											})()
										}
									/>
								}
								label={<FormattedMessage id="partners.table.filter.active" />}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={statusDisabled}
										onChange={() =>
											void (async () => {
												await handlePartnerStatus(PartnerState.Deactivated);
											})()
										}
									/>
								}
								label={
									<FormattedMessage id="partners.table.filter.deactivated" />
								}
							/>
						</FormGroup>
						<Button
							sx={{ minWidth: 'max-content', height: 'fit-content' }}
							variant="outlined"
							onClick={() => navigate(`/${PageRoutes.PartnersCreate}`)}
						>
							<FormattedMessage id="partners.table.add.new.partner" />
						</Button>
					</Box>
					<TableContainer>
						<Table aria-labelledby="partnersTable" size="small">
							<FiltersRow<PartnersTableFilterFields>
								filters={tableFilters}
								handleFilter={(name, value) => void handleFilters(name, value)}
							/>
							<HeadCells
								headCells={partnersTableHeadCells}
								orderBy={orderBy}
								order={order}
								handleRequestSort={(sortVal) => void handleRequestSort(sortVal)}
							/>
							{partners.isLoading || loading ? (
								<TableRowProgress headCellsCount={9} />
							) : (
								<TableBody>
									{allPartners.map((row) => (
										<TableRow
											hover
											tabIndex={-1}
											key={row.id}
											sx={{ width: '100%' }}
										>
											{/* <TableCell align="center">{row.id}</TableCell> */}
											<TableCell align="left">{row.company.name}</TableCell>
											<TableCell align="left">{row.company.website}</TableCell>
											<TableCell align="left">{row.contactPerson}</TableCell>
											<TableCell align="left">{row.contactEmail}</TableCell>
											<TableCell align="center">
												{row.numberOfPurchases}
											</TableCell>
											<TableCell align="center">
												{moment(row.createdAt)
													.format(DateFormats.YYYY_MM_DD_HH_MM)
													.toString()}
											</TableCell>
											<TableCell align="left">
												<PartnerMoreActions
													partnerId={row.id}
													partnerState={row.state}
													onPartnerStatusChange={(id, state) => {
														void onPartnerStatusChange(id, state);
													}}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
					<Pagination
						rowsLength={pagination.totalItems}
						page={pagination.currentPage - 1}
						rowsPerPage={pagination.itemsPerPage}
						handleChangePage={(_, newPage) => {
							void handlePageChange(newPage);
						}}
						handleChangeRowsPerPage={(rowsPP) => {
							void handleRowsPerPage(rowsPP);
						}}
					/>
				</Box>
			)}
		</ShowForRole>
	);
};
