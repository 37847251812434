import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BlockIcon from '@mui/icons-material/Block';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuList from '@mui/material/MenuList';

import { editRoute } from '../constants';
import { PartnerState } from '../types';

interface Props {
	partnerId: string;
	partnerState: PartnerState;
	onPartnerStatusChange: (partnerId: string, state: PartnerState) => void;
}

export const PartnerMoreActions: React.FC<Props> = ({
	partnerId,
	partnerState,
	onPartnerStatusChange,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				id="actions-button"
				aria-controls={open ? 'menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="menu"
				MenuListProps={{
					'aria-labelledby': 'actions-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuList>
					<MenuItem onClick={() => navigate(`${editRoute}/${partnerId}`)}>
						<ListItemIcon>
							<EditIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage id="partners.table.more.actions.edit" />
						</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleClose();
							onPartnerStatusChange(
								partnerId,
								partnerState === PartnerState.Active
									? PartnerState.Deactivated
									: PartnerState.Active
							);
						}}
					>
						<ListItemIcon>
							{partnerState === PartnerState.Active ? (
								<BlockIcon color="error" fontSize="small" />
							) : (
								<CheckCircleIcon color="primary" fontSize="small" />
							)}
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage
								id={
									partnerState === PartnerState.Active
										? 'partners.table.more.actions.deactivate'
										: 'partners.table.more.actions.activate'
								}
							/>
						</ListItemText>
					</MenuItem>
					{/* <MenuItem>
						<ListItemIcon>
							<ListAltIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							<FormattedMessage id="partners.table.more.actions.view.logs" />
						</ListItemText>
					</MenuItem> */}
				</MenuList>
			</Menu>
		</>
	);
};
