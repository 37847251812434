import { Locales } from 'features/LanguageSelector';

export const messages: Record<Locales, Record<string, string>> = {
	[Locales.En]: {
		400: 'Bad request',
		404: 'Resource not found',
		401: 'Unauthorized',
		403: 'Forbidden',
		500: 'Internal server error',
		Unhandled: 'Unhandled error',
		'c1051bb4-d103-4f74-8988-acbcafc7fdc3': 'This value should not be blank.',
		'ca23f4ca-38f4-4325-9bcc-eb570a4abe7f':
			'This value is not a valid phone number.',
		'bd79c0ab-ddba-46cc-a703-a7a4b08de310':
			'This value is not a valid email address.',
		'8e179f1b-97aa-4560-a02f-2a8b42e49df7':
			'The value you selected is not a valid choice.',
		'57c2f299-1154-4870-89bb-ef3b1f5ad229': 'This value is not a valid URL.',
		'66dad313-af0b-4214-8566-6c799be9789c':
			'This is not a valid Business Identifier Code (BIC).',
		'e2c259f3-4b46-48e6-b72e-891658158ec8':
			'This is not a valid International Bank Account Number (IBAN).',
		'3a95d939-d1b6-4ee9-8e94-d94409865e13': 'This value is already used.',
		'4aaac518-0dda-4129-a6d9-e216b9b454a0':
			'Departure and Arrival airports should be distinct from each other',
		'69819696-02ac-4a99-9ff0-14e127c4d1bc': 'This value is not a valid date.',
		'c8d318f1-2ecc-41ba-b983-df70d225cf5a': 'Invalid IBAN',
		'9215b0b1-6bed-4197-992e-5723e461fa8a': 'One of the airport is not valid.',
		'4b6f5c76-22b4-409d-af16-fbe823ba9332':
			'This value should have exactly 8 characters.',
		'6ebcd454-e759-43fc-9962-38dc431695e3':
			'No purchases found with this purchase code.',
		'8f900c12-61bd-455d-9398-996cd040f7f0':
			'This value is not a valid country.',
		'd1d15ae3-5159-498e-b6fd-5072796baf55': 'User is primary contact',
	},
	[Locales.De]: {
		400: 'Ungültige Anforderung',
		404: 'Ressource nicht gefunden',
	},
};
