import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'white',
	boxShadow: 24,
	p: 3,
};

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	onDelete: (userId: string) => void;
	userId: string;
	isPrimary: boolean;
}

export const UserDeleteModal: React.FC<Props> = ({
	isOpen,
	handleClose,
	onDelete,
	userId,
	isPrimary,
}) => (
	<Modal open={isOpen} onClose={handleClose}>
		<Box sx={style}>
			<Typography id="modal-modal-title" variant="h6" component="h2">
				{isPrimary ? (
					<FormattedMessage id="partner.user.edit.create.delete.primary" />
				) : (
					<FormattedMessage id="partner.user.edit.create.confirmation" />
				)}
			</Typography>
			<Box mt={2}>
				{isPrimary ? (
					<Button variant="outlined" onClick={handleClose}>
						<FormattedMessage id="partner.user.edit.create.delete.ok" />
					</Button>
				) : (
					<>
						<Button
							sx={{ marginRight: 2 }}
							color="error"
							variant="outlined"
							startIcon={<DeleteForeverIcon />}
							onClick={() => {
								handleClose();
								onDelete(userId);
							}}
						>
							<FormattedMessage id="partner.user.edit.create.delete" />
						</Button>
						<Button variant="outlined" onClick={handleClose}>
							<FormattedMessage id="partner.user.edit.create.delete.cancel" />
						</Button>
					</>
				)}
			</Box>
		</Box>
	</Modal>
);
