import type { State } from 'redux-store/Store';

import { countryAdapter } from './reducer/countryReducer';

const countriesSelectors = countryAdapter.getSelectors();

export const selectAllCountries = (state: State) =>
	countriesSelectors.selectAll(state.countries);

export const selectCountryById = (state: State, id: string) =>
	countriesSelectors.selectById(state.countries, id);

export const selectAllCountriesIdNamePhoneCode = (state: State) => {
	const countries = countriesSelectors.selectAll(state.countries);
	return countries.map((c) => ({
		id: c.id,
		name: c.title,
		phoneCode: c.phoneCode,
	}));
};

export const selectCurrentPage = (state: State) =>
	state.countries.pagination.currentPage;
