import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Option } from 'components/FormFields';
import { PartnerState } from 'features/Partners/types';

import { CompanyStateInterface, PureMyPartner } from '../types';

const initialState: CompanyStateInterface = {
	myPartner: {
		id: '',
		address: {
			city: '',
			country: null,
			firstLine: '',
			postalCode: '',
			secondLine: '',
		},
		bankDetails: {
			address: '',
			country: null,
			ibanAccount: '',
			swiftCode: '',
			title: '',
		},
		commission: {
			amount: 0,
			type: null,
		},
		company: {
			entityCode: '',
			name: '',
			website: '',
		},
		createdAt: '',
		state: PartnerState.Deactivated,
		vat: {
			number: '',
			exists: false,
		},
	},
	authenticationToken: '',
};

export const myCompanyFormSlice = createSlice({
	name: 'myCompanyForm',
	initialState,
	reducers: {
		setMyPartner: (
			state: CompanyStateInterface,
			action: PayloadAction<PureMyPartner>
		) => {
			const myCompanyPartner = {
				...action.payload,
				address: {
					...action.payload.address,
					country: {
						id: action.payload.address.country,
						name: action.payload.address.country,
					},
				},
				bankDetails: {
					address: action.payload.bankDetails.address,
					country: {
						id: action.payload.bankDetails.country,
						name: action.payload.bankDetails.country,
					},
					ibanAccount: action.payload.bankDetails.ibanAccount,
					swiftCode: action.payload.bankDetails.swiftCode,
					title: action.payload.bankDetails.title,
				},
				commission: {
					amount: action.payload.commission.amount,
					type: {
						id: action.payload.commission.type,
						name: action.payload.commission.type,
					},
				},
			};
			state.myPartner = myCompanyPartner;
		},
		setCommissionType: (
			state: CompanyStateInterface,
			action: PayloadAction<Option | null>
		) => {
			state.myPartner.commission.type = action.payload;
		},
		setCountry: (
			state: CompanyStateInterface,
			action: PayloadAction<Option | null>
		) => {
			state.myPartner.address.country = action.payload;
		},
		setBankCountry: (
			state: CompanyStateInterface,
			action: PayloadAction<Option | null>
		) => {
			state.myPartner.bankDetails.country = action.payload;
		},
		resetPartnerState: (state: CompanyStateInterface) => {
			state.myPartner = initialState.myPartner;
			state.authenticationToken = '';
		},
		setMyPartnerAuthenticationToken: (
			state: CompanyStateInterface,
			action: PayloadAction<string>
		) => {
			state.authenticationToken = action.payload;
		},
	},
});

export const {
	setMyPartner,
	setCommissionType,
	setCountry,
	setBankCountry,
	resetPartnerState,
	setMyPartnerAuthenticationToken,
} = myCompanyFormSlice.actions;

export default myCompanyFormSlice.reducer;
