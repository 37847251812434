import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AxiosRequestConfig } from 'axios';

import { http } from './ApiClient';
import { Error } from 'core/types';
import { resolveApiErrors } from 'features/ApiErrors';
import { selectLocale } from 'features/LanguageSelector';

export const useFetch = <T>(url: string, config?: AxiosRequestConfig) => {
	const [data, setData] = useState<T | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Record<string, string> | null>(null);

	const locale = useSelector(selectLocale);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await http.get<T>(url, config);
			setIsLoading(false);
			setData(response);
			setError(null);
		};
		fetchData().catch((e: Error) => {
			setError(resolveApiErrors(e.cause.violations, {}, locale).globalErrors);
			setIsLoading(false);
		});
	}, []);

	return { data, isLoading, error };
};
