import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { States } from 'core/types';
import { PurchaseEntity } from 'features/Purchases';

const initialState: PurchaseEntity = {
	id: '',
	_id: '',
	state: States.Rejected,
	partner: {
		id: '',
		name: '',
	},
	trip: {
		type: 'one_way',
		numberOfPassengers: 0,
		numberOfLegs: 0,
	},
	claimCode: {
		value: '',
	},
	claimInstruction: {
		linkToPdfInstruction: '',
		validTill: '',
	},
	pricePerPassenger: {
		amount: 0,
		currency: {
			code: '',
		},
	},
	priceTotal: {
		amount: 0,
		currency: {
			code: '',
		},
	},
	ticketsPrice: {
		amount: 0,
		currency: {
			code: '',
		},
	},
	flights: [],
	partnerCommission: {
		amount: 0,
		currency: {
			code: '',
		},
	},
	skyknightCommission: {
		amount: 0,
		currency: {
			code: '',
		},
	},
	createdAt: '',
	updatedAt: '',
	destinationCodes: [],
};

export const purchaseFormSlice = createSlice({
	name: 'purchaseForm',
	initialState,
	reducers: {
		setPurchaseEntity: (
			_: PurchaseEntity,
			{ payload }: PayloadAction<PurchaseEntity>
		) => {
			return payload;
		},
		setInvoiceStatus: (
			state: PurchaseEntity,
			action: PayloadAction<States>
		) => {
			state.state = action.payload || null;
		},
	},
});

export const { setInvoiceStatus, setPurchaseEntity } =
	purchaseFormSlice.actions;

export default purchaseFormSlice.reducer;
