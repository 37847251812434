import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { http } from 'core/ApiClient';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import {
	generalTabRequiredFields,
	addressTabRequiredFields,
	bankDetailsTabRequiredFields,
} from '../constants';
import {
	TabId,
	MyPartnerForm,
	MyPartnerStateFields,
	ApiMyPartnerState,
	PureMyPartner,
} from '../types';
import { TabPanel, TabLabel } from 'components/Table';
import { Routes } from 'types/types';
import { General } from '../parts/General';
import { Address } from '../parts/Address';
import { ApiAccess } from '../parts/ApiAccess';
import { BankDetails } from '../parts/BankDetails';
import { Users } from '../parts/Users';
import { isTabHasErrors } from 'utils/helpers';
import { PartnerState } from 'features/Partners/types';
import { selectLocale } from 'features/LanguageSelector';
import { SnackbarAlert } from 'components/Snackbar';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { Error } from 'core/types';
import moment from 'moment';
import { DateFormats } from 'components/FormFields';
import {
	selectMyPartner,
	selectAuthenticationToken,
	selectMyPartnerId,
} from '../selectors';
import { setMyPartner } from '../reducer/myCompanyReducer';

export const MyCompanyTabs: React.FC = () => {
	const [tabId, setTabId] = useState<number>(TabId.General);
	const [apiError, setApiError] = useState<Record<string, string> | null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);

	const intl = useIntl();
	const dispatch = useDispatch();

	const myPartner = useSelector(selectMyPartner);
	const authenticationToken = useSelector(selectAuthenticationToken);
	const locale = useSelector(selectLocale);
	const myPartnerId = useSelector(selectMyPartnerId);

	const initialValues: MyPartnerForm = {
		[MyPartnerStateFields.id]: myPartner.id,
		[MyPartnerStateFields.legalEntityName]: myPartner.company.name,
		[MyPartnerStateFields.legalEntityCode]: myPartner.company.entityCode,
		[MyPartnerStateFields.vatNumber]: myPartner.vat.number,
		[MyPartnerStateFields.website]: myPartner.company.website,
		[MyPartnerStateFields.commissionAmount]: myPartner.commission.amount,
		[MyPartnerStateFields.commissionType]: myPartner.commission.type,
		[MyPartnerStateFields.createdAt]:
			moment(myPartner.createdAt)
				.format(DateFormats.YYYY_MM_DD_HH_MM)
				.toString() || '',
		[MyPartnerStateFields.state]:
			myPartner.state === PartnerState.Active
				? true
				: myPartner.id === ''
				? true
				: false,
		[MyPartnerStateFields.addressLine1]: myPartner.address.firstLine,
		[MyPartnerStateFields.addressLine2]: myPartner.address.secondLine,
		[MyPartnerStateFields.country]: myPartner.address.country,
		[MyPartnerStateFields.city]: myPartner.address.city,
		[MyPartnerStateFields.postalCode]: myPartner.address.postalCode,
		[MyPartnerStateFields.authenticationToken]: authenticationToken || '',
		[MyPartnerStateFields.bankTitle]: myPartner.bankDetails.title,
		[MyPartnerStateFields.swiftCode]: myPartner.bankDetails.swiftCode,
		[MyPartnerStateFields.iban]: myPartner.bankDetails.ibanAccount,
		[MyPartnerStateFields.bankAddress]: myPartner.bankDetails.address,
		[MyPartnerStateFields.bankCountry]: myPartner.bankDetails.country,
	};

	const validationSchema = yup.object().shape({
		[MyPartnerStateFields.legalEntityName]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.legalEntityCode]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.website]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.addressLine1]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.country]: yup
			.object()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.city]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.postalCode]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.bankTitle]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.swiftCode]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.iban]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.bankAddress]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[MyPartnerStateFields.bankCountry]: yup
			.object()
			.required(intl.formatMessage({ id: 'form.field.required' })),
	});

	const handleSubmit = async (
		values: MyPartnerForm,
		{ setErrors }: FormikHelpers<MyPartnerForm>
	) => {
		setApiError(null);

		const apiPartner: ApiMyPartnerState = {
			address: {
				city: values.city,
				country: values.country?.name || '',
				firstLine: values.addressLine1,
				secondLine: values.addressLine2,
				postalCode: values.postalCode,
			},
			bankDetails: {
				address: values.bankAddress,
				country: values.bankCountry?.name || '',
				ibanAccount: values.iban,
				swiftCode: values.swiftCode,
				title: values.bankTitle,
			},
			commission: {
				amount: values.commissionAmount,
				type: values.commissionType?.name || '',
			},
			company: {
				entityCode: values.legalEntityCode,
				name: values.legalEntityName,
				website: values.website,
			},
			createdAt: values.createdAt,
			state: values.state ? PartnerState.Active : PartnerState.Deactivated,
			vat: {
				number: values.vatNumber,
				exists: !!values.vatNumber,
			},
		};

		await http
			.put<ApiMyPartnerState, PureMyPartner>(
				`${Routes.Partners}/${values.id}`,
				apiPartner
			)
			.then((res) => {
				setIsOpen(true);
				dispatch(setMyPartner(res));
			})
			.catch((e: Error) => {
				const resolvedErrors = resolveApiErrors(
					e.cause.violations,
					MyPartnerStateFields,
					locale
				);
				if (resolvedErrors.formErrors) {
					setErrors(resolvedErrors.formErrors);
				}
				setApiError(resolvedErrors.globalErrors);
				setIsOpenSnack(true);
			});
	};

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabId(newValue);
	};

	const handleSnackbarClose = () => {
		setIsOpen(false);
	};
	return (
		<>
			<SnackbarAlert
				isOpen={isOpen}
				type="success"
				handleClose={handleSnackbarClose}
				message={intl.formatMessage({
					id: 'api.request.was.successfully',
				})}
			/>
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={isOpenSnack}
				handleClose={() => setIsOpenSnack(false)}
			/>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched }) => (
					<Form noValidate autoComplete="off">
						<Box m={2} sx={{ background: 'white' }}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs
									value={tabId}
									onChange={handleChange}
									variant="scrollable"
									aria-label="partners edit tabs"
								>
									<TabLabel
										label={intl.formatMessage({
											id: 'my.company.view.edit.tab.general',
										})}
										index={TabId.General}
										value={TabId.General}
										hasError={isTabHasErrors(
											generalTabRequiredFields,
											errors,
											touched
										)}
									/>
									<TabLabel
										label={intl.formatMessage({
											id: 'my.company.view.edit.tab.address',
										})}
										index={TabId.Address}
										value={TabId.Address}
										hasError={isTabHasErrors(
											addressTabRequiredFields,
											errors,
											touched
										)}
									/>
									{myPartnerId && (
										<TabLabel
											label={intl.formatMessage({
												id: 'my.company.view.edit.tab.users',
											})}
											index={TabId.Users}
											value={TabId.Users}
										/>
									)}
									{myPartnerId && (
										<TabLabel
											label={intl.formatMessage({
												id: 'my.company.view.edit.tab.api.access',
											})}
											index={TabId.ApiAccess}
											value={TabId.ApiAccess}
										/>
									)}
									<TabLabel
										label={intl.formatMessage({
											id: 'my.company.view.edit.tab.bank.details',
										})}
										index={TabId.BankDetails}
										value={TabId.BankDetails}
										hasError={isTabHasErrors(
											bankDetailsTabRequiredFields,
											errors,
											touched
										)}
									/>
									{/* still not exists */}
									{/* {partnerId && (
										<TabLabel
											label={intl.formatMessage({
												id: 'partners.edit.create.tab.logs',
											})}
											index={TabId.Logs}
											value={TabId.Logs}
										/>
									)} */}
								</Tabs>
							</Box>
							<TabPanel value={tabId} index={TabId.General}>
								<General />
							</TabPanel>
							<TabPanel value={tabId} index={TabId.Address}>
								<Address />
							</TabPanel>
							{myPartnerId && (
								<TabPanel value={tabId} index={TabId.Users} p={0}>
									<Box pt={3} pb={3}>
										<Users partnerId={myPartnerId} />
									</Box>
								</TabPanel>
							)}
							{myPartnerId && (
								<TabPanel value={tabId} index={TabId.ApiAccess}>
									<ApiAccess />
								</TabPanel>
							)}
							<TabPanel value={tabId} index={TabId.BankDetails}>
								<BankDetails />
							</TabPanel>
							{/* still not exists */}
							{/* {partnerId && (
								<TabPanel value={tabId} index={TabId.Logs}>
									<Logs />
								</TabPanel>
							)} */}
							<Box paddingBottom={2} paddingTop={2}>
								<Button type="submit" variant="contained" color="primary">
									<FormattedMessage id="partners.edit.create.save.changes" />
								</Button>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
};
