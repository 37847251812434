import { FormattedMessage } from 'react-intl';

import { HeadCell } from 'components/Table';
import { UsersTableFields } from './types';

export const userEdit = '/user-edit';

export const usersTableHeadCells: HeadCell[] = [
	// {
	// 	id: UsersTableFields.id,
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: <FormattedMessage id="partner.users.head.cell.label.user.id" />,
	// },
	{
		id: UsersTableFields.firstName,
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.users.head.cell.label.first.name" />,
	},
	{
		id: UsersTableFields.lastName,
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.users.head.cell.label.last.name" />,
	},
	{
		id: UsersTableFields.email,
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.users.head.cell.label.email" />,
	},
	{
		id: UsersTableFields.partner,
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.users.head.cell.label.company.name" />,
	},
	{
		id: UsersTableFields.phoneNumber,
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.users.head.cell.label.phone.number" />,
	},
	{
		id: UsersTableFields.primaryContact,
		numeric: false,
		disablePadding: true,
		label: (
			<FormattedMessage id="partner.users.head.cell.label.primary.contact" />
		),
	},
	{
		id: UsersTableFields.createdAt,
		numeric: false,
		disablePadding: true,
		label: <FormattedMessage id="partner.users.head.cell.label.created.at" />,
	},
	{
		id: UsersTableFields.actions,
		numeric: false,
		disablePadding: true,
		label: '',
	},
];
