import { BankDetails } from './../../MyCompany/parts/BankDetails';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Option } from 'components/FormFields';

import {
	PartnerStateInterface,
	PurePartner,
	CommissionTypeNames,
} from '../types';
import { PartnerState } from 'features/Partners/types';
import { AMOUNT_DIVIDER } from 'core/types';

const initialState: PartnerStateInterface = {
	partner: {
		id: '',
		address: {
			city: '',
			country: null,
			firstLine: '',
			postalCode: '',
			secondLine: '',
		},
		bankDetails: {
			address: '',
			country: null,
			ibanAccount: '',
			swiftCode: '',
			title: '',
		},
		commission: {
			amount: 0,
			type: null,
		},
		company: {
			entityCode: '',
			name: '',
			website: '',
		},
		createdAt: '',
		state: PartnerState.Deactivated,
		vat: {
			number: '',
			exists: false,
		},
	},
	authenticationToken: '',
};

export const partnerFormSlice = createSlice({
	name: 'partnerForm',
	initialState,
	reducers: {
		setPartner: (
			state: PartnerStateInterface,
			action: PayloadAction<PurePartner>
		) => {
			const partner = {
				...action.payload,
				address: {
					...action.payload.address,
					country: {
						id: action.payload.address.country,
						name: action.payload.address.country,
					},
				},
				bankDetails: {
					address: action.payload.bankDetails.address,
					country: {
						id: action.payload.bankDetails.country,
						name: action.payload.bankDetails.country,
					},
					ibanAccount: action.payload.bankDetails.ibanAccount,
					swiftCode: action.payload.bankDetails.swiftCode,
					title: action.payload.bankDetails.title,
				},
				commission: {
					amount:
						action.payload.commission.type === CommissionTypeNames.fixed
							? action.payload.commission.amount / AMOUNT_DIVIDER
							: action.payload.commission.amount,
					type: {
						id: action.payload.commission.type,
						name: action.payload.commission.type,
					},
				},
			};
			state.partner = partner;
		},
		setCommissionType: (
			state: PartnerStateInterface,
			action: PayloadAction<Option | null>
		) => {
			state.partner.commission.type = action.payload;
		},
		setCountry: (
			state: PartnerStateInterface,
			action: PayloadAction<Option | null>
		) => {
			state.partner.address.country = action.payload;
		},
		setBankCountry: (
			state: PartnerStateInterface,
			action: PayloadAction<Option | null>
		) => {
			state.partner.bankDetails.country = action.payload;
		},
		resetPartnerState: (state: PartnerStateInterface) => {
			state.partner = initialState.partner;
			state.authenticationToken = '';
		},
		setPartnerAuthenticationToken: (
			state: PartnerStateInterface,
			action: PayloadAction<string>
		) => {
			state.authenticationToken = action.payload;
		},
	},
});

export const {
	setCommissionType,
	setCountry,
	setBankCountry,
	setPartner,
	resetPartnerState,
	setPartnerAuthenticationToken,
} = partnerFormSlice.actions;

export default partnerFormSlice.reducer;
