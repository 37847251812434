import { ObjectValType } from 'components/Table';

export interface UsersListAPI {
	data: UserAttribute[];
	links: {
		first: string;
		last: string;
		next: string;
		self: string;
	};
	meta: {
		currentPage: number;
		itemsPerPage: number;
		totalItems: number;
	};
}

export type UserAttribute = {
	attributes: User;
	id: string;
	type: string;
};

type Partner = {
	id: string;
	name: string;
};

export interface User {
	[key: string]: ObjectValType;
	_id: string;
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	primaryContact: boolean;
	createdAt: string;
	partner: Partner;
	password: string;
	roles: string[];
	updatedAd: string;
	userIdentifier: string;
}
export interface PrimaryContactRequestApi {
	primaryContact: boolean;
}

export interface UserPagination {
	currentPage: number;
	itemsPerPage: number;
	totalItems: number;
}

export enum UsersTableFields {
	id = 'id',
	firstName = 'firstName',
	lastName = 'lastName',
	email = 'email',
	partner = 'partner',
	phoneNumber = 'phoneNumber',
	primaryContact = 'primaryContact',
	createdAt = 'createdAt',
	actions = 'actions',
}

export enum UsersTableFilterFields {
	id = 'id',
	firstName = 'firstName',
	lastName = 'lastName',
	email = 'email',
	partner = 'partner',
	partnerId = 'partnerId',
	phoneNumber = 'phoneNumber',
	primaryContact = 'primaryContact',
	createdAtStart = 'createdAtStart',
	createdAtEnd = 'createdAtEnd',
}

export interface UsersTableFilterFieldsProps {
	[UsersTableFilterFields.id]: string;
	[UsersTableFilterFields.firstName]: string;
	[UsersTableFilterFields.lastName]: string;
	[UsersTableFilterFields.email]: string;
	[UsersTableFilterFields.partner]: string;
	[UsersTableFilterFields.partnerId]: string;
	[UsersTableFilterFields.phoneNumber]: string;
	[UsersTableFilterFields.primaryContact]: string;
	[UsersTableFilterFields.createdAtStart]: string;
	[UsersTableFilterFields.createdAtEnd]: string;
}

export enum UsersTableFilterFieldsAPI {
	id = 'id',
	firstName = 'firstName',
	lastName = 'lastName',
	email = 'email.value',
	partner = 'partner.company.name',
	partnerId = 'partner.id',
	phoneNumber = 'phoneNumber',
	primaryContact = 'primaryContact',
	createdAtStart = 'createdAt[after]',
	createdAtEnd = 'createdAt[before]',
}
