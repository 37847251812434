import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { http } from 'core/ApiClient';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { TextField, SelectField, Option } from 'components/FormFields';

import { setBankCountry } from '../reducer/myCompanyReducer';
import { selectBankCountry } from '../selectors';
import { Routes } from 'types/types';

import {
	selectAllCountriesIdNamePhoneCode,
	addCountries,
	CountriesListAPI,
	selectCurrentPage,
	setCurrentPage,
} from 'features/Country';
import { MyPartnerStateFields } from '../types';
import { ListPaginationConfig } from 'core/constants';

export const BankDetails: React.FC = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [debouncedValue, setDebouncedValue] = useState<string | null>(null);
	const [value, setValue] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const currentCountriesPage = useSelector(selectCurrentPage);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), 500);
		return () => clearTimeout(timer);
	}, [value]);

	useEffect(() => {
		const controller = new AbortController();

		setIsLoading(true);
		if (debouncedValue) {
			http
				.get<CountriesListAPI>(
					`${Routes.Countries}?itemsPerPage=10&title=${debouncedValue}`,
					ListPaginationConfig
				)
				.then((res) => {
					dispatch(addCountries(res.data));
					setIsLoading(false);
				})
				.catch((e: Error) => {
					console.error(e);
				});
		}
		return () => controller.abort();
	}, [debouncedValue]);

	const handlePartnerSearchValue = (searchVal: string) => {
		setValue(searchVal);
	};

	const bankCountry = useSelector(selectBankCountry);
	const countries = useSelector(selectAllCountriesIdNamePhoneCode);

	const handleBankCountrySelect = (option: Option) => {
		if (bankCountry?.id === option?.id) return;

		dispatch(setBankCountry(!option ? null : option));
	};

	const handleLoadMoreCountries = () => {
		setIsLoading(true);
		http
			.get<CountriesListAPI>(
				`${Routes.Countries}?itemsPerPage=10&page=${currentCountriesPage + 1}`,
				ListPaginationConfig
			)
			.then((res) => {
				dispatch(setCurrentPage({ currentPage: res.meta.currentPage }));
				dispatch(addCountries(res.data));
			})
			.catch((e: Error) => {
				console.error(e);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<FormFieldsContainer>
			<Field
				as={TextField}
				name={MyPartnerStateFields.bankTitle}
				type="text"
				label={intl.formatMessage({
					id: 'my.company.view.edit.bank.title',
				})}
			/>
			<Field
				as={TextField}
				name={MyPartnerStateFields.swiftCode}
				type="text"
				label={intl.formatMessage({
					id: 'my.company.view.edit.bank.bic.swift.code',
				})}
			/>
			<Field
				as={TextField}
				name={MyPartnerStateFields.iban}
				type="text"
				label={intl.formatMessage({
					id: 'my.company.view.edit.bank.iban',
				})}
			/>
			<Field
				as={TextField}
				name={MyPartnerStateFields.bankAddress}
				type="text"
				label={intl.formatMessage({
					id: 'my.company.view.edit.bank.address',
				})}
			/>
			<Field
				as={SelectField}
				options={countries}
				value={bankCountry}
				onChange={handleBankCountrySelect}
				name={MyPartnerStateFields.bankCountry}
				type="object"
				label={intl.formatMessage({
					id: 'my.company.view.edit.bank.country',
				})}
				onInputChange={handlePartnerSearchValue}
				loading={isLoading}
				handleLoadMore={handleLoadMoreCountries}
			/>
		</FormFieldsContainer>
	);
};
