import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { selectPurchaseEntity } from '../selectors';
import moment from 'moment';
import { DateFormats } from 'components/FormFields';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
export const FlightDetails: React.FC = () => {
	const intl = useIntl();
	const purchase = useSelector(selectPurchaseEntity);

	return (
		<>
			{purchase.flights.map((flight, index) => (
				<>
					<FormFieldsContainer key={flight.id}>
						<TextField
							key={flight.id}
							disabled
							label={intl.formatMessage(
								{
									id: 'partner.purchase.view.flight.details.departure.airport',
								},
								{
									value: index + 1,
								}
							)}
							value={flight.departureAirport.iata}
						/>

						<TextField
							disabled
							label={intl.formatMessage(
								{
									id: 'partner.purchase.view.flight.details.departure.date.time',
								},
								{
									value: index + 1,
								}
							)}
							value={moment(flight.scheduledDepartureDateTime).format(
								DateFormats.YYYY_MM_DD_HH_MM
							)}
						/>
						<TextField
							disabled
							label={intl.formatMessage(
								{
									id: 'partner.purchase.view.flight.details.arrival.date.time',
								},
								{
									value: index + 1,
								}
							)}
							value={moment(flight.scheduledArrivalDateTime).format(
								DateFormats.YYYY_MM_DD_HH_MM
							)}
						/>
						<TextField
							disabled
							label={intl.formatMessage(
								{
									id: 'partner.purchase.view.flight.details.arrival.airport',
								},
								{
									value: index + 1,
								}
							)}
							value={flight.arrivalAirport.iata}
						/>
						<TextField
							disabled
							label={intl.formatMessage({
								id: 'partner.purchase.view.flight.details.operated.by',
							})}
							value={flight.airline.iata}
						/>
					</FormFieldsContainer>
					<Divider
						sx={{
							marginTop: 2,
							marginBottom: 2,
						}}
					/>
				</>
			))}
			<FormFieldsContainer>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.purchase.view.flight.details.passenger.number',
					})}
					value={purchase.trip.numberOfPassengers}
				/>
			</FormFieldsContainer>
		</>
	);
};
