import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface Props {
	title: React.ReactElement;
	secondary: number | string;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		card: {
			boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
			position: 'relative',
			border: '1px solid grey',
			padding: theme.spacing(1, 1),
			width: '100%',
			margin: theme.spacing(1),
		},
		title: {
			padding: theme.spacing(1),
			margin: theme.spacing(1, 0),
		},
	};
});

export const SimpleCard: React.FC<Props> = ({ title, secondary }) => {
	const classes = useStyles();

	return (
		<Paper sx={{ p: 1 }} className={classes.card}>
			<Grid container>
				<Grid
					container
					className={classes.title}
					justifyContent="center"
					sx={{ p: 0 }}
				>
					<Typography variant="body1">{title}</Typography>
				</Grid>
				<Grid sx={{ mb: 1 }} container justifyContent="center">
					<Typography variant="h4">{secondary}</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};
