import { ROLE } from 'types/types';

export enum HttpCodes {
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	NotFound = 404,
	UnprocessableContent = 422,
	InternalServerError = 500,
}

export enum SystemErr {
	systemErr = 'systemErr',
}

export type HttpHeaders = {
	[key: string]: string;
};

export type RequestConfig = {
	headers: HttpHeaders;
};

export enum JwtToken {
	Token = 'token',
	RefreshToken = 'refresh_token',
}

export enum JwtRefreshEvent {
	RefreshEvent = 'refresh_event',
}

export type JwtTokenType = {
	exp: number | null;
	iat: number | null;
	roles: Array<ROLE> | null;
	username: string | null;
};

export interface RefreshJwtRequest {
	refresh_token: string;
}

export interface RefreshJwtResponse {
	token: string;
	refresh_token: string;
}

type Violation = {
	code: string;
	message: string;
	propertyPath: string;
};
export interface ErrorResponse {
	status: number;
	error: {
		status: string;
		message: string;
		title: string;
		detail: string;
		type: string;
		violations: Violation[];
	};
}

export interface ErrorField {
	propertyPath: string;
	code: string;
}

export interface Error {
	message: string;
	cause: {
		statusCode: HttpCodes;
		violations: ErrorField[];
	};
}

export enum States {
	Submitted = 'Submitted',
	InProgress = 'In Progress',
	Rejected = 'Rejected',
	Approved = 'Approved',
}

export const statusTypes: string[] = [
	States.Approved,
	States.InProgress,
	States.Rejected,
	States.Submitted,
];

export const AMOUNT_MULTIPLIER = 100;
export const AMOUNT_DIVIDER = 100;
