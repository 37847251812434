import * as React from 'react';
import { useField } from 'formik';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { DateFormats } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
	errorColor: {
		color: theme.palette.error.main,
	},
	pickerErrorBorder: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: theme.palette.error.main,
			},
			'&:hover fieldset': {
				borderColor: theme.palette.error.main,
			},
		},
	},
}));

interface Props {
	onChange: (value: unknown) => void;
	value: string;
	name: string;
	format?: string;
}

export const FormDatePicker: React.FC<Props> = ({
	onChange,
	name,
	value,
	format = DateFormats.YYYY_MM_DD,
	...props
}) => {
	const classes = useStyles();
	const [, meta] = useField(name);

	const error = !!(meta && meta.touched && meta.error);
	const errorText = error && meta.error;

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				format={format}
				value={value ? dayjs(value) : null}
				className={error ? classes.pickerErrorBorder : ''}
				onChange={(val) => {
					onChange(val);
				}}
				slotProps={{
					textField: {
						size: 'small',
						helperText: <span className={classes.errorColor}>{errorText}</span>,
					},
				}}
				{...props}
			/>
		</LocalizationProvider>
	);
};
