import { UsersTableFilterFields, UsersTableFilterFieldsProps } from './types';
import { FilterType } from 'components/Filters';
import { IntlShape } from 'react-intl';

export const generateFilters = (
	values: UsersTableFilterFieldsProps,
	intl: IntlShape
): FilterType<UsersTableFilterFields>[] => [
	// {
	// 	type: 'Text',
	// 	textFilter: {
	// 		label: intl.formatMessage(
	// 			{
	// 				id: 'filters.cell.label',
	// 			},
	// 			{
	// 				fieldName: intl.formatMessage({
	// 					id: 'partner.users.head.cell.label.user.id',
	// 				}),
	// 			}
	// 		),
	// 		fieldName: UsersTableFilterFields.id,
	// 		defaultValue: values.id,
	// 	},
	// },
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.users.head.cell.label.first.name',
					}),
				}
			),
			fieldName: UsersTableFilterFields.firstName,
			defaultValue: values.firstName,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.users.head.cell.label.last.name',
					}),
				}
			),
			fieldName: UsersTableFilterFields.lastName,
			defaultValue: values.lastName,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.users.head.cell.label.email',
					}),
				}
			),
			fieldName: UsersTableFilterFields.email,
			defaultValue: values.email,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.users.head.cell.label.company.name',
					}),
				}
			),
			fieldName: UsersTableFilterFields.partner,
			defaultValue: values.partner,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partner.users.head.cell.label.phone.number',
					}),
				}
			),
			fieldName: UsersTableFilterFields.phoneNumber,
			defaultValue: values.phoneNumber,
		},
	},
	{ type: 'Empty' },
	{
		type: 'Date',
		dateFilter: {
			startFieldName: UsersTableFilterFields.createdAtStart,
			endFieldName: UsersTableFilterFields.createdAtEnd,
			startLabel: intl.formatMessage({
				id: 'filters.cell.label.start.date',
			}),
			endLabel: intl.formatMessage({
				id: 'filters.cell.label.end.date',
			}),
			startDefaultValue: values.createdAtStart,
			endDefaultValue: values.createdAtEnd,
		},
	},
	{ type: 'Empty' },
];
