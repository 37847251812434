import {
	PartnersTableFilterFields,
	PartnersTableFiltersFieldsProps,
} from './types';
import { FilterType } from 'components/Filters';
import { getSearchFiltersUrl, getOrderByUrl } from 'utils/helpers';

import { IntlShape } from 'react-intl';

export const generateFilters = (
	values: PartnersTableFiltersFieldsProps,
	intl: IntlShape
): FilterType<PartnersTableFilterFields>[] => [
	// {
	// 	type: 'Text',
	// 	textFilter: {
	// 		label: intl.formatMessage(
	// 			{
	// 				id: 'filters.cell.label',
	// 			},
	// 			{
	// 				fieldName: intl.formatMessage({
	// 					id: 'partners.table.head.cell.label.id',
	// 				}),
	// 			}
	// 		),
	// 		fieldName: PartnersTableFilterFields.id,
	// 		defaultValue: values.id,
	// 	},
	// },
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partners.table.head.cell.label.partner.name',
					}),
				}
			),
			fieldName: PartnersTableFilterFields.partner,
			defaultValue: values.partnerName,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partners.table.head.cell.label.website',
					}),
				}
			),
			fieldName: PartnersTableFilterFields.website,
			defaultValue: values.website,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partners.table.head.cell.label.contact.person',
					}),
				}
			),
			fieldName: PartnersTableFilterFields.contactPerson,
			defaultValue: values.contactPerson,
		},
	},
	{
		type: 'Text',
		textFilter: {
			label: intl.formatMessage(
				{
					id: 'filters.cell.label',
				},
				{
					fieldName: intl.formatMessage({
						id: 'partners.table.head.cell.label.contact.email',
					}),
				}
			),
			fieldName: PartnersTableFilterFields.contactEmail,
			defaultValue: values.contactEmail,
		},
	},
	{ type: 'Empty' },
	{
		type: 'Date',
		dateFilter: {
			startFieldName: PartnersTableFilterFields.createdAtStart,
			endFieldName: PartnersTableFilterFields.createdAtEnd,
			startLabel: intl.formatMessage({
				id: 'filters.cell.label.start.date',
			}),
			endLabel: intl.formatMessage({
				id: 'filters.cell.label.end.date',
			}),
			startDefaultValue: values.createdAtStart,
			endDefaultValue: values.createdAtEnd,
		},
	},
	{ type: 'Empty' },
];

export const getPartnersFetchParams = (
	filters: Record<string, string>,
	order: string,
	orderBy: string,
	page: number,
	itemsPerPage: number,
	fields: Record<string, string>
) => {
	const activeFilters = getSearchFiltersUrl(filters, fields);
	const activeOrder = getOrderByUrl(order, orderBy, fields);

	return `?groups[]=list&itemsPerPage=${itemsPerPage}&page=${page}${activeFilters}${activeOrder}`;
};
