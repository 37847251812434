import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
	Formik,
	Form,
	FormikHelpers,
	FormikErrors,
	FormikValues,
} from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import { isTabHasErrors } from 'utils/helpers';
import { TabPanel, TabLabel } from 'components/Table';
import { http } from 'core/ApiClient';
import { Error } from 'core/types';
import { SnackbarAlert } from 'components/Snackbar';
import { setUser } from '../reducer/userReducer';

import {
	TabId,
	UserStateInterface,
	UserInterfacePostAPI,
	UserStateFields,
} from '../types';
import { General } from '../parts/General';
import { Logs } from '../parts/Logs';
import { Password } from '../parts/Password';
import { PageRoutes } from 'types';
import { UserDelete } from './UserDelete';
import { selectUser } from '../selectors';
import { Routes } from 'types/types';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { selectLocale } from 'features/LanguageSelector';
import { generalTabRequiredFields } from '../constants';
import moment from 'moment';
import { DateFormats } from 'components/FormFields';

interface Props {
	onCancelClick?: () => void;
	onSubmitClick?: (
		values: UserStateInterface,
		setErrors: (errors: FormikErrors<UserStateInterface>) => void,
		setFieldValue: (field: string, value: FormikValues) => void
	) => void;
	onUserDelete?: (userId: string) => void;
	openSnackbar?: boolean;
	handleCloseSnackbar?: () => void;
}

export const UserTabs: React.FC<Props> = ({
	onCancelClick,
	onSubmitClick,
	onUserDelete,
	openSnackbar,
	handleCloseSnackbar,
}) => {
	const [tabId, setTabId] = useState<number>(TabId.General);
	const [apiError, setApiError] = useState<Record<string, string> | null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);

	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const locale = useSelector(selectLocale);
	const intl = useIntl();
	const dispatch = useDispatch();

	const initialValues: UserStateInterface = {
		[UserStateFields.id]: user.id || '',
		[UserStateFields.firstName]: user.firstName || '',
		[UserStateFields.lastName]: user.lastName || '',
		[UserStateFields.email]: user.email || '',
		[UserStateFields.phoneNumber]: user.phoneNumber || '',
		[UserStateFields.partner]: user.partner || null,
		[UserStateFields.createdAt]:
			moment(user.createdAt).format(DateFormats.YYYY_MM_DD_HH_MM).toString() ||
			'',
		[UserStateFields.plainPassword]: user.plainPassword || '',
		[UserStateFields.primaryContact]: user.primaryContact || false,
	};

	const validationSchema = yup.object().shape({
		[UserStateFields.firstName]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[UserStateFields.lastName]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[UserStateFields.email]: yup
			.string()
			.email(intl.formatMessage({ id: 'form.field.email' }))
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[UserStateFields.phoneNumber]: yup
			.string()
			.required(intl.formatMessage({ id: 'form.field.required' })),
		[UserStateFields.partner]: yup
			.object()
			.required(intl.formatMessage({ id: 'form.field.required' })),
	});

	const handleSubmit = async (
		values: UserStateInterface,
		{ setErrors, setFieldValue }: FormikHelpers<UserStateInterface>
	) => {
		const userObj: UserInterfacePostAPI = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			phoneNumber: values.phoneNumber,
			primaryContact: values.primaryContact,
			partnerId: values.partner?.id || '',
		};

		if (onSubmitClick)
			return onSubmitClick(
				values,
				(errors) => setErrors(errors),
				setFieldValue
			);

		if (values.id) {
			await http
				.put<UserInterfacePostAPI, UserStateInterface>(
					`${Routes.Users}/${values.id}`,
					userObj
				)
				.then((res) => {
					setIsOpen(true);
					dispatch(setUser(res));
				})
				.catch((e: Error) => {
					const resolvedErrors = resolveApiErrors(
						e.cause.violations,
						UserStateFields,
						locale
					);

					if (resolvedErrors.formErrors) {
						setErrors(resolvedErrors.formErrors);
					}
					setApiError(resolvedErrors.globalErrors);
					setIsOpenSnack(true);
				});
		} else {
			await http
				.post<UserInterfacePostAPI, UserStateInterface>(Routes.Users, userObj)
				.then((res) => {
					setIsOpen(true);
					dispatch(setUser(res));
					setFieldValue(UserStateFields.id, res.id);
					setFieldValue(UserStateFields.createdAt, res.createdAt);
					setFieldValue(UserStateFields.plainPassword, res.plainPassword);
				})
				.catch((e: Error) => {
					const resolvedErrors = resolveApiErrors(
						e.cause.violations,
						UserStateFields,
						locale
					);

					if (resolvedErrors.formErrors) {
						setErrors(resolvedErrors.formErrors);
					}
					setApiError(resolvedErrors.globalErrors);
					setIsOpenSnack(true);
				});
		}
	};

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabId(newValue);
	};

	const handleSnackbarClose = () => {
		setIsOpen(false);
	};

	const handleUserDelete = async (id: string) => {
		await http
			.delete(`${Routes.Users}/${id}`)
			.then(() => {
				navigate(`/${PageRoutes.Users}`);
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, UserStateFields, locale)
						.globalErrors
				);
				setIsOpenSnack(true);
			});
	};

	return (
		<>
			<SnackbarAlert
				isOpen={openSnackbar || isOpen}
				type="success"
				handleClose={handleCloseSnackbar || handleSnackbarClose}
				message={intl.formatMessage({
					id: 'api.request.was.successfully',
				})}
			/>
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={isOpenSnack && !!apiError && !!Object.keys(apiError).length}
				handleClose={() => setIsOpenSnack(false)}
			/>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting, errors, touched }) => (
					<Form noValidate autoComplete="off">
						<Box m={2} sx={{ background: 'white' }}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs
									variant="scrollable"
									value={tabId}
									onChange={handleChange}
									aria-label="partners edit tabs"
								>
									<TabLabel
										label={intl.formatMessage({
											id: 'partner.user.edit.create.tab.general',
										})}
										index={TabId.General}
										value={TabId.General}
										hasError={isTabHasErrors(
											generalTabRequiredFields,
											errors,
											touched
										)}
									/>
									{!!user.id && (
										<TabLabel
											label={intl.formatMessage({
												id: 'partner.user.edit.create.tab.password',
											})}
											index={TabId.Password}
											value={TabId.Password}
										/>
									)}
									{false && (
										<TabLabel
											label={intl.formatMessage({
												id: 'partner.user.edit.create.tab.logs',
											})}
											index={TabId.Logs}
											value={TabId.Logs}
										/>
									)}
								</Tabs>
							</Box>
							<TabPanel value={tabId} index={TabId.General}>
								<General />
							</TabPanel>
							{!!user.id && (
								<TabPanel value={tabId} index={TabId.Password}>
									<Password />
								</TabPanel>
							)}
							{false && (
								<TabPanel value={tabId} index={TabId.Logs}>
									<Logs />
								</TabPanel>
							)}
							<Box paddingBottom={2} paddingTop={2}>
								<Button
									sx={{ marginRight: 2 }}
									type="submit"
									variant="contained"
									color="primary"
									disabled={isSubmitting}
								>
									<FormattedMessage id="partner.user.edit.create.save.changes" />
								</Button>
								<Button
									onClick={() =>
										onCancelClick
											? onCancelClick()
											: navigate(`/${PageRoutes.Users}`)
									}
									variant="outlined"
									color="primary"
								>
									<FormattedMessage id="partner.user.edit.create.cancel" />
								</Button>
							</Box>
							{user.id && (
								<Box paddingBottom={2} paddingTop={2}>
									<UserDelete
										userId={user.id}
										isPrimary={user.primaryContact}
										onDelete={onUserDelete ? onUserDelete : handleUserDelete}
									/>
								</Box>
							)}
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
};
