export const getUrlIfMatch = (url: string, matchPath: string[]): string => {
	if (!url || !matchPath || !matchPath.length) return '';
	let matchedPath = '';
	matchPath.map((path) => {
		if (url.match(`^/${path}$`)) {
			matchedPath = path;
			return;
		}
	});
	return matchedPath;
};
