import React from 'react';
import { useField, FieldProps } from 'formik';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Help from '@mui/icons-material/Help';
import type { OverridableStringUnion } from '@mui/types';

interface Props {
	name: string;
	helperText?: string;
	infoText?: string;
	size?: OverridableStringUnion<'small' | 'medium'>;
}

export const FormTextField: React.FC<Props & FieldProps> = ({
	name,
	helperText,
	infoText,
	size = 'small',
	...otherProps
}) => {
	const [field, meta] = useField(name);

	const configTextField = {
		fullWidth: true,
		...field,
		...otherProps,
	};

	const error = !!(meta && meta.touched && meta.error);
	const helperT = error ? meta.error : helperText;

	return (
		<TextField
			error={error}
			helperText={helperT}
			InputProps={
				infoText
					? {
							endAdornment: (
								<InputAdornment position="end">
									<Tooltip title={infoText} arrow>
										<Help />
									</Tooltip>
								</InputAdornment>
							),
					  }
					: {}
			}
			size={size}
			{...configTextField}
		/>
	);
};
