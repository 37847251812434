import {
	ErrorResponse,
	ErrorField,
	HttpCodes,
	SystemErr,
	AMOUNT_DIVIDER,
} from './types';
import { IntlShape } from 'react-intl';

export const handleServiceError = (res: ErrorResponse) => {
	if (res.status === HttpCodes.BadRequest) {
		throw new Error(res.error?.message, {
			cause: {
				statusCode: res.status,
				violations: [
					{ propertyPath: SystemErr.systemErr, code: HttpCodes.BadRequest },
				],
			},
		});
	}

	if (res.status === HttpCodes.NotFound) {
		throw new Error(res.error?.detail, {
			cause: {
				statusCode: res.status,
				violations: [
					{ propertyPath: SystemErr.systemErr, code: HttpCodes.NotFound },
				],
			},
		});
	}

	if (res.status === HttpCodes.Unauthorized) {
		throw new Error(res.error?.message, {
			cause: {
				statusCode: res.status,
				violations: [
					{ propertyPath: SystemErr.systemErr, code: HttpCodes.Unauthorized },
				],
			},
		});
	}

	if (res.status === HttpCodes.InternalServerError) {
		throw new Error(res.error?.message, {
			cause: {
				statusCode: res.status,
				violations: [
					{
						propertyPath: SystemErr.systemErr,
						code: HttpCodes.InternalServerError,
					},
				],
			},
		});
	}

	if (res.status === HttpCodes.Forbidden) {
		throw new Error(res.error?.message, {
			cause: {
				statusCode: res.status,
				violations: [
					{ propertyPath: SystemErr.systemErr, code: HttpCodes.Forbidden },
				],
			},
		});
	}

	if (res.status === HttpCodes.UnprocessableContent) {
		const violations: ErrorField[] = res.error.violations.map((v) => ({
			propertyPath: v.propertyPath,
			code: v.code,
		}));
		throw new Error(res.error?.message, {
			cause: { violations, statusCode: res.status },
		});
	}

	throw new Error(res.error?.message, {
		cause: {
			statusCode: res.status,
			violations: [{ propertyPath: SystemErr.systemErr, code: 'Unhandled' }],
		},
	});
};

export const getStandardAmount = (amount: number) =>
	(amount / AMOUNT_DIVIDER).toFixed(2);

export const getTranslatedStates = <T extends string>(
	states: T[],
	intl: IntlShape
) =>
	states.map((stat) =>
		intl.formatMessage({
			id: `states.${stat}`,
		})
	);

export const getTranslatedState = <T extends string>(
	state: T,
	intl: IntlShape
) => getTranslatedStates([state], intl);
