import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import { TabPanel, TabLabel } from 'components/Table';

import { General } from '../parts/General';
import { FlightDetails } from '../parts/FlightDetails';
import { Customer } from '../parts/Customer';
import { Logs } from '../parts/Logs';

import { TabId } from '../types';

export const ClaimTabs: React.FC = () => {
	const [tabId, setTabId] = useState<number>(TabId.General);

	const intl = useIntl();

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabId(newValue);
	};

	return (
		<Box m={2} sx={{ background: 'white' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					variant="scrollable"
					value={tabId}
					onChange={handleChange}
					aria-label="claim view tabs"
				>
					<TabLabel
						label={intl.formatMessage({
							id: 'partner.claims.view.tab.general',
						})}
						index={TabId.General}
						value={TabId.General}
					/>

					<TabLabel
						label={intl.formatMessage({
							id: 'partner.claims.view.tab.flight.details',
						})}
						index={TabId.DisruptedFlightDetails}
						value={TabId.DisruptedFlightDetails}
					/>
					<TabLabel
						label={intl.formatMessage({
							id: 'partner.claims.view.tab.customer',
						})}
						index={TabId.Customer}
						value={TabId.Customer}
					/>
					{/* <TabLabel
						label={intl.formatMessage({
							id: 'partner.claims.view.tab.logs',
						})}
						index={TabId.Logs}
						value={TabId.Logs}
					/> */}
				</Tabs>
			</Box>
			<TabPanel value={tabId} index={TabId.General}>
				<General />
			</TabPanel>
			<TabPanel value={tabId} index={TabId.DisruptedFlightDetails}>
				<FlightDetails />
			</TabPanel>
			<TabPanel value={tabId} index={TabId.Customer}>
				<Customer />
			</TabPanel>
			{/* <TabPanel value={tabId} index={TabId.Logs}>
				<Logs />
			</TabPanel> */}
		</Box>
	);
};
