import React from 'react';
import { CenterContainer } from 'layouts/containers/CenterContainer';
import { BoxWrapper } from 'layouts/wrappers/BoxWrapper';
import { ClaimForm } from 'features/ClaimForm';

import { Layout } from 'layouts/Layout';

export const ClaimFormPage: React.FC = () => (
	<Layout
		header={<></>}
		main={
			<CenterContainer>
				<BoxWrapper>
					<ClaimForm />
				</BoxWrapper>
			</CenterContainer>
		}
	/>
);
