export enum PageRoutes {
	Dashboard = 'dashboard',
	AdminDashboard = 'admin-dashboard',
	Login = 'login',
	ClaimForm = 'claim-form/*',
	Partners = 'partners',
	PartnerEdit = 'partner-edit/:partnerId',
	PartnersCreate = 'partner-create',
	Users = 'users',
	UserEdit = 'user-edit/:userId',
	UserCreate = 'user-create',
	Purchases = 'purchases',
	PurchaseView = 'purchase/view/:purchaseId',
	Claims = 'claims',
	ClaimView = 'claim/view/:claimId',
	MyCompany = 'my-company',
	Invoices = 'invoices',
}
