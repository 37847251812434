import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { WizardProps } from '../types';

export const Wizard: React.FC<WizardProps> = ({
	activeStep,
	steps,
	onClick,
}) => (
	<Box sx={{ width: '100%' }}>
		<Stepper activeStep={activeStep} alternativeLabel>
			{Object.keys(steps).map((key) => (
				<Step key={key}>
					<StepButton
						color="inherit"
						onClick={() => (onClick ? onClick(key) : {})}
					>
						{steps[key]}
					</StepButton>
				</Step>
			))}
		</Stepper>
	</Box>
);
