import { States } from 'core/types';

export enum TabId {
	General = 0,
	FlightDetails = 1,
	Logs = 2,
}

export interface PurchaseStateInterface {
	state: States | null;
}
