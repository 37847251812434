import { MyPartnerStateFields } from './types';

export const generalTabRequiredFields: string[] = [
	MyPartnerStateFields.legalEntityName,
	MyPartnerStateFields.legalEntityCode,
	MyPartnerStateFields.website,
];

export const addressTabRequiredFields: string[] = [
	MyPartnerStateFields.addressLine1,
	MyPartnerStateFields.country,
	MyPartnerStateFields.city,
	MyPartnerStateFields.postalCode,
];

export const bankDetailsTabRequiredFields: string[] = [
	MyPartnerStateFields.bankTitle,
	MyPartnerStateFields.swiftCode,
	MyPartnerStateFields.iban,
	MyPartnerStateFields.bankAddress,
	MyPartnerStateFields.bankCountry,
];

export const commissionTypes = [
	{
		name: 'percentage',
		id: 'percentage',
	},
	{
		name: 'fixed',
		id: 'fixed',
	},
];
