import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { BasicDatePicker } from 'components/DatePicker';

import { FilterType } from '../types';
import { DateFormats } from 'components/FormFields';

interface Props<T> {
	handleFilter: (name: string, value: string) => void;
	filters: FilterType<T>[];
}

export const FiltersRow: <T extends string>(
	p: Props<T>
) => React.ReactElement<Props<T>> | null = ({ filters, handleFilter }) => {
	const [debouncedValue, setDebouncedValue] = useState<Record<
		string,
		string
	> | null>(null);

	const [value, setValue] = useState<Record<string, string> | null>({});

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), 800);
		return () => clearTimeout(timer);
	}, [value]);

	useEffect(() => {
		const controller = new AbortController();

		if (debouncedValue && Object.keys(debouncedValue).length) {
			const keys = Object.keys(debouncedValue);
			const fieldValue = debouncedValue[keys[0]];
			const fieldName = keys[0];
			handleFilter(fieldName, fieldValue);
		}

		return () => controller.abort();
	}, [debouncedValue]);

	if (!!filters && !filters.length) return null;
	return (
		<TableHead>
			<TableRow tabIndex={-1}>
				{filters.map((filter, i) => {
					if (filter.type === 'Text' && filter?.textFilter?.fieldName) {
						const filterName = filter.textFilter.fieldName;
						return (
							<TableCell align="left" key={i}>
								<TextField
									InputLabelProps={{ style: { fontSize: 14 } }}
									inputProps={{ style: { fontSize: 14 } }}
									id="search_by_value"
									label={filter.textFilter.label}
									variant="standard"
									defaultValue={filter.textFilter?.defaultValue}
									onChange={(val) => {
										setValue({
											[filterName]: val.target.value,
										});
									}}
									sx={{ minWidth: 'max-content' }}
								/>
							</TableCell>
						);
					} else if (filter.type === 'Date') {
						return (
							<TableCell align="left" key={i} width={'100%'}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box sx={{ width: 'max-content' }}>
										<BasicDatePicker
											defaultValue={filter.dateFilter?.startDefaultValue || ''}
											label={filter.dateFilter?.startLabel || ''}
											onChange={(val) => {
												const date = dayjs(val).isValid()
													? dayjs(val).format(DateFormats.YYYY_MM_DD)
													: '';
												setValue({
													[filter.dateFilter?.startFieldName || '']: date,
												});
											}}
										/>
									</Box>
									<Box sx={{ mr: 1, ml: 1 }}>-</Box>
									<BasicDatePicker
										defaultValue={filter.dateFilter?.endDefaultValue || null}
										label={filter.dateFilter?.endLabel || ''}
										onChange={(val) => {
											const date = dayjs(val).isValid()
												? dayjs(val).format(DateFormats.YYYY_MM_DD)
												: '';
											setValue({
												[filter.dateFilter?.endFieldName || '']: date,
											});
										}}
									/>
								</Box>
							</TableCell>
						);
					}
					if (filter.type === 'Empty') {
						return <TableCell align="left" key={i} width={'100%'}></TableCell>;
					}
				})}
			</TableRow>
		</TableHead>
	);
};
