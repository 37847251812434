import { Option } from 'components/FormFields';

export interface UserStateInterface {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	partner: Option | null;
	createdAt: string;
	plainPassword: string;
	primaryContact: boolean;
}

export enum UserStateFields {
	id = 'id',
	firstName = 'firstName',
	lastName = 'lastName',
	email = 'email',
	phoneNumber = 'phoneNumber',
	partner = 'partner',
	createdAt = 'createdAt',
	plainPassword = 'plainPassword',
	primaryContact = 'primaryContact',
}

export enum TabId {
	General = 0,
	Password = 1,
	Logs = 2,
}

export interface UserInterfacePostAPI {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	primaryContact: boolean;
	partnerId: string;
}

export interface UserNewPasswordPostAPI {
	userId: string;
}

export interface UserNewPasswordResAPI {
	plainPassword: string;
}

export enum RequiredFields {
	FirstName = 'firstName',
	LastName = 'lastName',
	Email = 'email',
	Phone = 'phone',
	Company = 'company',
}
