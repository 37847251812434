import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';

import { FormTextFieldWithClickableIcon } from 'components/FormFields';
import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';

import { selectAuthenticationToken, selectPartnerId } from '../selectors';
import { PartnerStateFields } from '../types';
import { selectLocale } from 'features/LanguageSelector';
import { Routes } from 'types/types';
import { http } from 'core/ApiClient';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { Error } from 'core/types';
import { setPartnerAuthenticationToken } from '../reducer/partnerReducer';

export const ApiAccess: React.FC = () => {
	const intl = useIntl();
	const locale = useSelector(selectLocale);
	const formikProps = useFormikContext();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [apiError, setApiError] = useState<Record<string, string> | null>(null);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);
	const partnerId = useSelector(selectPartnerId);
	const dispatch = useDispatch();
	const authenticationToken = useSelector(selectAuthenticationToken);

	const handleClick = async () => {
		await navigator.clipboard.writeText(authenticationToken || '');
	};

	const handleRefreshToken = async (id: string) => {
		setIsLoading(true);
		await http
			.post<{ partnerId: string }, { accessToken: string }>(
				Routes.PartnerAuthToken,
				{
					partnerId: id,
				}
			)
			.then(({ accessToken }) => {
				formikProps.setFieldValue(
					PartnerStateFields.authenticationToken,
					accessToken
				);
				dispatch(setPartnerAuthenticationToken(accessToken));
			})
			.catch((e: Error) => {
				const resolvedErrors = resolveApiErrors(
					e.cause.violations,
					PartnerStateFields,
					locale
				);
				setApiError(resolvedErrors.globalErrors);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	if (apiError && !!Object.keys(apiError).length && !isLoading) {
		return (
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={isOpenSnack}
				handleClose={() => setIsOpenSnack(false)}
			/>
		);
	}

	return (
		<FormFieldsContainer>
			<Field
				disabled
				as={FormTextFieldWithClickableIcon}
				name={PartnerStateFields.authenticationToken}
				type="text"
				label={intl.formatMessage({
					id: 'partners.edit.create.authentication.token',
				})}
				endIcon={<ContentCopyIcon />}
				handleIconClick={handleClick}
			/>
			<Box sx={{ width: 'max-content' }}>
				<Button
					variant="contained"
					startIcon={<RefreshIcon />}
					onClick={() => void handleRefreshToken(partnerId)}
					disabled={isLoading}
				>
					<FormattedMessage id="partners.edit.create.generate.new" />
				</Button>
			</Box>
		</FormFieldsContainer>
	);
};
