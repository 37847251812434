import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';
import { Error } from 'core/types';

import { UserTabs } from 'features/UserCreateEdit';
import { http } from 'core/ApiClient';
import { Routes } from 'types/types';
import { selectLocale } from 'features/LanguageSelector';

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	handleUsersRefetch?: (value: boolean) => void;
}

export const UserViewEditModal: React.FC<Props> = ({
	isOpen,
	handleClose,
	handleUsersRefetch,
}) => {
	const [apiError, setApiError] = useState<Record<string, string> | null>();
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);

	const locale = useSelector(selectLocale);

	const handleUserDelete = async (id: string) => {
		setApiError(null);

		await http
			.delete(`${Routes.Users}/${id}`)
			.then(() => {
				handleClose();
				if (handleUsersRefetch) {
					handleUsersRefetch(true);
				}
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			});
	};

	return (
		<Dialog fullScreen open={isOpen} onClose={handleClose}>
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={isOpenSnack}
				handleClose={() => setIsOpenSnack(false)}
			/>
			<UserTabs
				onCancelClick={handleClose}
				onUserDelete={(id) => void handleUserDelete(id)}
			/>
		</Dialog>
	);
};
