import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';

import { User } from 'features/UserCreateEdit';

export const UserCreateEditPage: React.FC = () => (
	<Layout header={<Header />} main={<User />} />
);
