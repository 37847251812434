import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext, Field } from 'formik';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { selectPassword, selectUserId } from '../selectors';
import { Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Error } from 'core/types';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { FormTextFieldWithClickableIcon } from 'components/FormFields';
import { Routes } from 'types/types';
import { http } from 'core/ApiClient';
import {
	UserStateFields,
	UserNewPasswordPostAPI,
	UserNewPasswordResAPI,
} from '../types';
import { setPassword } from '../reducer/userReducer';
import { selectLocale } from 'features/LanguageSelector';
import { resolveApiErrors, ApiErrorsSnackbar } from 'features/ApiErrors';

export const Password: React.FC = () => {
	const intl = useIntl();
	const locale = useSelector(selectLocale);
	const formikProps = useFormikContext();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [apiError, setApiError] = useState<Record<string, string> | null>(null);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);

	const dispatch = useDispatch();
	const password = useSelector(selectPassword);
	const userId = useSelector(selectUserId);

	const handleClick = async () => {
		await navigator.clipboard.writeText(password);
	};

	const handleRefreshPassword = async () => {
		setIsLoading(true);
		await http
			.post<UserNewPasswordPostAPI, UserNewPasswordResAPI>(
				Routes.UserPassword,
				{
					userId,
				}
			)
			.then(({ plainPassword }) => {
				formikProps.setFieldValue(UserStateFields.plainPassword, plainPassword);
				dispatch(setPassword(plainPassword));
			})
			.catch((e: Error) => {
				const resolvedErrors = resolveApiErrors(
					e.cause.violations,
					UserStateFields,
					locale
				);
				setApiError(resolvedErrors.globalErrors);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	if (apiError && !!Object.keys(apiError).length && !isLoading) {
		return (
			<ApiErrorsSnackbar
				messages={apiError}
				type="error"
				isOpen={isOpenSnack}
				handleClose={() => setIsOpenSnack(false)}
			/>
		);
	}

	return (
		<>
			<Typography mb={2}>
				<FormattedMessage id="partner.user.edit.create.password.description" />
			</Typography>
			<FormFieldsContainer>
				<Field
					disabled
					as={FormTextFieldWithClickableIcon}
					name={UserStateFields.plainPassword}
					type="text"
					label={intl.formatMessage({
						id: 'partner.user.edit.create.password',
					})}
					endIcon={<ContentCopyIcon />}
					handleIconClick={handleClick}
				/>
				<Box sx={{ width: 'max-content' }}>
					<Button
						disabled={isLoading}
						variant="contained"
						startIcon={<RefreshIcon />}
						onClick={() => void handleRefreshPassword()}
					>
						<FormattedMessage id="partner.user.edit.create.generate.new" />
					</Button>
				</Box>
			</FormFieldsContainer>
		</>
	);
};
