import {
	PartnerState,
	PartnersPagination,
	Partner,
	PartnersTableFilterFields,
	PartnerAttribute,
} from './../types';
import {
	createSlice,
	createEntityAdapter,
	PayloadAction,
	EntityId,
} from '@reduxjs/toolkit';
import { RowsPerPageOptions } from 'components/Table';

export const partnersAdapter = createEntityAdapter<Partner>();

export const partnersSlice = createSlice({
	name: 'partners',
	initialState: partnersAdapter.getInitialState({
		pagination: {
			currentPage: 1,
			itemsPerPage: RowsPerPageOptions[0],
			totalItems: 1,
		},
		filters: {
			[PartnersTableFilterFields.id]: '',
			[PartnersTableFilterFields.partner]: '',
			[PartnersTableFilterFields.website]: '',
			[PartnersTableFilterFields.contactPerson]: '',
			[PartnersTableFilterFields.contactEmail]: '',
			[PartnersTableFilterFields.createdAtStart]: '',
			[PartnersTableFilterFields.createdAtEnd]: '',
			[PartnersTableFilterFields.state]: PartnerState.Active,
		},
	}),
	reducers: {
		setPartners: (state, action: PayloadAction<PartnerAttribute[]>) => {
			const partners = action.payload.map((partnerAttributes) => ({
				...partnerAttributes.attributes,
				id: partnerAttributes.attributes._id,
			}));
			partnersAdapter.setAll(state, partners);
		},
		updatePartnerState: (
			state,
			action: PayloadAction<{ id: EntityId; state: PartnerState }>
		) => {
			partnersAdapter.updateOne(state, {
				id: action.payload.id,
				changes: { state: action.payload.state },
			});
		},
		setPartnerCompanyNames: (
			state,
			action: PayloadAction<PartnerAttribute[]>
		) => {
			const partners = action.payload.map((partner: PartnerAttribute) => ({
				...partner.attributes,
				id: partner.id,
			}));
			partnersAdapter.setAll(state, partners);
		},
		addPartnerCompanyNames: (
			state,
			action: PayloadAction<PartnerAttribute[]>
		) => {
			const partners = action.payload.map((partner: PartnerAttribute) => ({
				...partner.attributes,
				id: partner.id,
			}));
			partnersAdapter.addMany(state, partners);
		},
		setPagination: (state, action: PayloadAction<PartnersPagination>) => {
			state.pagination.currentPage = action.payload.currentPage;
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
			state.pagination.totalItems = action.payload.totalItems;
		},

		setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
			state.pagination.currentPage = action.payload.currentPage;
		},
		setItemsPerPage: (
			state,
			action: PayloadAction<{ itemsPerPage: number }>
		) => {
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
		},
		setFilterState: (state, action: PayloadAction<PartnerState>) => {
			state.filters.state = action.payload;
		},
		setFilterValue: (
			state,
			action: PayloadAction<{
				filterName: PartnersTableFilterFields;
				value: string;
			}>
		) => {
			if (action.payload.filterName === 'state') {
				state.filters.state =
					action.payload.value === PartnerState.Active
						? PartnerState.Active
						: PartnerState.Deactivated;
			} else {
				state.filters[action.payload.filterName] = action.payload.value;
			}
		},
	},
});

export const {
	setPartners,
	setPagination,
	setCurrentPage,
	setItemsPerPage,
	setPartnerCompanyNames,
	addPartnerCompanyNames,
	updatePartnerState,
	setFilterState,
	setFilterValue,
} = partnersSlice.actions;

export default partnersSlice.reducer;
