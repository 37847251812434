import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RowsPerPageOptions } from 'components/Table';
import {
	PurchaseStateInterface,
	PurchasePagination,
	PurchasesTableFilterFields,
} from '../types';

const initialState: PurchaseStateInterface = {
	pagination: {
		currentPage: 1,
		itemsPerPage: RowsPerPageOptions[0],
		totalItems: 1,
	},
	filters: {
		[PurchasesTableFilterFields.claimCode]: '',
		[PurchasesTableFilterFields.soldBy]: '',
		[PurchasesTableFilterFields.totalPurchase]: '',
		[PurchasesTableFilterFields.skyknightCommission]: '',
		[PurchasesTableFilterFields.partnerCommission]: '',
		[PurchasesTableFilterFields.createdAtStart]: '',
		[PurchasesTableFilterFields.createdAtEnd]: '',
	},
};

export const purchasesSlice = createSlice({
	name: 'purchases',
	initialState,
	reducers: {
		setPagination: (state, action: PayloadAction<PurchasePagination>) => {
			state.pagination.currentPage = action.payload.currentPage;
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
			state.pagination.totalItems = action.payload.totalItems;
		},

		setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
			state.pagination.currentPage = action.payload.currentPage;
		},
		setItemsPerPage: (
			state,
			action: PayloadAction<{ itemsPerPage: number }>
		) => {
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
		},
		resetPagination: (state) => {
			state.pagination = {
				currentPage: 1,
				itemsPerPage: RowsPerPageOptions[0],
				totalItems: 1,
			};
		},
		setFilterValue: (
			state,
			action: PayloadAction<{
				filterName: PurchasesTableFilterFields;
				value: string;
			}>
		) => {
			state.filters[action.payload.filterName] = action.payload.value;
		},
	},
});

export const {
	setPagination,
	setCurrentPage,
	setItemsPerPage,
	resetPagination,
	setFilterValue,
} = purchasesSlice.actions;

export default purchasesSlice.reducer;
