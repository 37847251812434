import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Locales } from '../types';
// import { http } from 'core/ApiClient';
import { setLocale, setLanguages } from '../reducer/localeReducer';
import { selectLocale } from '../selectors';
import messages_en from 'translations/messages/en.json';
import messages_de from 'translations/messages/de.json';

import validations_en from 'translations/validations/en.json';
import validations_de from 'translations/validations/de.json';

interface Props {
	locale: string;
	messages: Record<string, string>;
	defaultLocale: string;
}

export const useInitialFetch = (): Props => {
	const langs = ['en', 'de'] as string[];
	const dispatch = useDispatch();

	const locale = useSelector(selectLocale);

	const messages: { [key: string]: Record<string, string> } = {
		[Locales.En]: { ...messages_en, ...validations_en },
		[Locales.De]: { ...messages_de, ...validations_de },
	};

	// Todo update when request exist
	useEffect(() => {
		dispatch(setLocale(Locales.En));
		dispatch(setLanguages(langs));
	}, []);
	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		const res = await http.get<string>('/');
	// 		console.log(data);
	// 		setData(res);
	// 		// update depends on BE
	// 		dispatch(setLocale(Locales.En));
	// 		setLoc(Locales.En);
	// 	};

	// 	fetchData().catch(() => {
	// 		setLoc(Locales.En);
	// 		dispatch(setLocale(Locales.En));
	// 	});
	// }, [loc]);

	return {
		locale: locale || Locales.En,
		messages: messages[locale] || messages[Locales.En],
		defaultLocale: Locales.En,
	};
};
