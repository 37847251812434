import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ListPaginationConfig } from 'core/constants';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { PartnerTabs } from './PartnerTabs';
import { PageRoutes } from 'types';
import { useFetch } from 'core/hooks';
import { Routes } from 'types/types';
import { setCountries, CountriesListAPI } from 'features/Country';
import { useFetchPartner } from '../hooks';
import { ProgressBox } from 'components/ProgressIndicator';
import { ApiErrorsSnackbar } from 'features/ApiErrors';

export const Partner: React.FC = () => {
	const { partnerId } = useParams();
	const dispatch = useDispatch();

	const countries = useFetch<CountriesListAPI>(
		`${Routes.Countries}?itemsPerPage=10`,
		ListPaginationConfig
	);

	useEffect(() => {
		if (countries.data) {
			dispatch(setCountries(countries.data?.data));
		}
	}, [countries]);

	const partner = useFetchPartner(partnerId);

	const isFormHasError =
		(!partner.isLoading && !!partner.error) ||
		(!countries.isLoading && !!countries.error);
	const errors = { ...(partner.error || {}), ...(countries.error || {}) };

	if (partner.isLoading || countries.isLoading) return <ProgressBox />;

	return (
		<Box m={2} sx={{ background: 'white' }}>
			<Box p={2}>
				<Link to={`/${PageRoutes.Partners}`}>
					<Button startIcon={<ArrowBackIcon fontSize="small" />}>
						<FormattedMessage id="navigation.back.to.partners.list" />
					</Button>
				</Link>
			</Box>
			{isFormHasError ? (
				<ApiErrorsSnackbar
					messages={errors}
					type="error"
					isOpen={isFormHasError}
				/>
			) : (
				<PartnerTabs />
			)}
		</Box>
	);
};
