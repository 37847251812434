import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { InitialData, Locales } from '../types';

const initialState: InitialData = {
	locale: Locales.En,
	languages: null,
};

export const localeSlice = createSlice({
	name: 'locale',
	initialState,
	reducers: {
		setLocale: (state: InitialData, action: PayloadAction<Locales>) => {
			state.locale = action.payload;
		},
		setLanguages: (
			state: InitialData,
			action: PayloadAction<string[] | null>
		) => {
			state.languages = action.payload;
		},
	},
});

export const { setLocale, setLanguages } = localeSlice.actions;

export default localeSlice.reducer;
