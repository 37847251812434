import React from 'react';

import Box from '@mui/material/Box';

interface Props {
	children: JSX.Element;
	index: number;
	value: number | string;
	p?: number;
}

export const TabPanel: React.FC<Props> = ({
	value,
	index,
	children,
	p = 3,
}) => (
	<div
		role="tabpanel"
		hidden={value !== index}
		id={`tabpanel-${index}`}
		aria-labelledby={`tab-${index}`}
	>
		{value === index && (
			<Box sx={{ p }}>
				<div>{children}</div>
			</Box>
		)}
	</div>
);
