import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';

import { FormFieldsContainer } from 'layouts/containers/FormFieldsContainer';
import { selectClaimFlightDetails } from '../selectors';

export const FlightDetails: React.FC = () => {
	const intl = useIntl();
	const flightDetails = useSelector(selectClaimFlightDetails);

	return (
		<>
			<FormFieldsContainer>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.claims.view.tab.flight.details.departure.airport',
					})}
					value={flightDetails.departureAirport}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.claims.view.tab.flight.details.arrival.airport',
					})}
					value={flightDetails.arrivalAirport}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.claims.view.tab.flight.details.what.happened',
					})}
					value={flightDetails.userReason}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.claims.view.tab.flight.details.reason.by.airline',
					})}
					value={flightDetails.airlineReason}
				/>
				<TextField
					multiline
					maxRows={5}
					disabled
					label={intl.formatMessage({
						id: 'partner.claims.view.tab.flight.details.details.by.customer',
					})}
					value={flightDetails.details}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.claims.view.tab.flight.details.when.informed',
					})}
					value={flightDetails.informed}
				/>
				<TextField
					disabled
					label={intl.formatMessage({
						id: 'partner.claims.view.tab.flight.details.how.late',
					})}
					value={flightDetails.delayed}
				/>
			</FormFieldsContainer>
		</>
	);
};
