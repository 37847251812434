import { States } from 'core/types';
import { ObjectValType } from 'components/Table';

export enum PurchasesTableFilterFields {
	claimCode = 'claimCode',
	soldBy = 'soldBy',
	totalPurchase = 'totalPurchase',
	skyknightCommission = 'skyknightCommission',
	partnerCommission = 'partnerCommission',
	createdAtStart = 'createdAtStart',
	createdAtEnd = 'createdAtEnd',
}

export type PurchasesTableFilterFieldsProps = {
	[PurchasesTableFilterFields.claimCode]: string;
	[PurchasesTableFilterFields.soldBy]: string;
	[PurchasesTableFilterFields.totalPurchase]: string;
	[PurchasesTableFilterFields.skyknightCommission]: string;
	[PurchasesTableFilterFields.partnerCommission]: string;
	[PurchasesTableFilterFields.createdAtStart]: string;
	[PurchasesTableFilterFields.createdAtEnd]: string;
};
export interface PurchaseStateInterface {
	pagination: {
		currentPage: number;
		itemsPerPage: number;
		totalItems: number;
	};
	filters: PurchasesTableFilterFieldsProps;
}

export interface PurchasePagination {
	currentPage: number;
	itemsPerPage: number;
	totalItems: number;
}

export type PurchaseAttribute = {
	attributes: PurchaseEntity;
	id: string;
	type: string;
};

export interface PurchaseListAPI {
	data: PurchaseAttribute[];
	links: {
		first: string;
		last: string;
		next: string;
		self: string;
	};
	meta: {
		currentPage: number;
		itemsPerPage: number;
		totalItems: number;
	};
}

export interface PurchaseEntity {
	id: string;
	_id: string;
	claimCode: {
		value: string;
	};
	claimInstruction: {
		linkToPdfInstruction: string;
		validTill: string;
	};
	createdAt: string;
	destinationCodes: string[];
	flights: Flights[];
	partner: {
		id: string;
		name: string;
	};
	partnerCommission: {
		amount: number;
		currency: {
			code: string;
		};
	};
	pricePerPassenger: {
		amount: number;
		currency: {
			code: string;
		};
	};
	priceTotal: {
		amount: number;
		currency: {
			code: string;
		};
	};
	skyknightCommission: {
		amount: number;
		currency: {
			code: string;
		};
	};
	ticketsPrice: {
		amount: number;
		currency: {
			code: string;
		};
	};
	trip: {
		numberOfLegs: number;
		numberOfPassengers: number;
		type: string;
	};
	updatedAt: string;
	// double check later
	state: States;
}

export interface Flights {
	[key: string]: ObjectValType;
	airline: {
		iata: string;
		icao: string;
	};
	arrivalAirport: {
		iata: string;
		icao: string;
	};
	createdAt: string;
	departureAirport: {
		iata: string;
		icao: string;
	};
	flightNumber: number;
	id: string;
	scheduledDepartureDateTime: string;
	scheduledArrivalDateTime: string;
	updatedAt: string;
}

export enum PurchasesTableFilterFieldsAPI {
	id = 'id',
}
