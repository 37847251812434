import React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface Props {
	label: string | React.ReactNode;
	index: number;
	value: number;
	onChange?: () => void;
	hasError?: boolean;
}

export const TabLabel: React.FC<Props> = ({
	label,
	index,
	onChange,
	value,
	hasError,
	...rest
}) => (
	<Tab
		value={value}
		tabIndex={index}
		label={<Box sx={{ color: hasError ? 'error.main' : 'unset' }}>{label}</Box>}
		id={`tab-${index}`}
		aria-controls={`tabpanel-${index}`}
		onChange={onChange}
		{...rest}
	/>
);
