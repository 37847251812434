import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import secureLocalStorage from 'react-secure-storage';

import { RequestConfig, JwtToken, ErrorResponse } from './types';
import { setupInterceptorsTo } from './AxiosInterceptors';
import { handleServiceError } from './helper';

export interface IApiClient {
	post<TRequest, TResponse>(
		path: string,
		object: TRequest,
		config?: RequestConfig
	): Promise<TResponse>;
	patch<TRequest, TResponse>(
		path: string,
		object: TRequest
	): Promise<TResponse>;
	put<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse>;
	get<TResponse>(path: string): Promise<TResponse>;
}
class ApiClient implements IApiClient {
	private client: AxiosInstance;

	private accessToken =
		secureLocalStorage.getItem(JwtToken.Token)?.toString() || '';

	protected createAxiosClient(accessToken: string): AxiosInstance {
		return Axios.create({
			baseURL: process.env.REACT_APP_BE_APP_BASE_URL,
			headers: {
				Accept: 'application/json',
				...(accessToken && {
					Authorization: `Bearer ${accessToken}`,
				}),
			},
		});
	}

	constructor() {
		this.client = this.createAxiosClient(this.accessToken);
		setupInterceptorsTo(this.client);
	}

	async post<TRequest, TResponse>(
		path: string,
		payload: TRequest,
		config?: RequestConfig
	): Promise<TResponse> {
		try {
			const response = config
				? await this.client.post<TResponse>(path, payload, config)
				: await this.client.post<TResponse>(path, payload);
			return response.data;
		} catch (error) {
			handleServiceError(error as ErrorResponse);
		}
		return {} as TResponse;
	}

	async patch<TRequest, TResponse>(
		path: string,
		payload: TRequest
	): Promise<TResponse> {
		try {
			const response = await this.client.patch<TResponse>(path, payload);
			return response.data;
		} catch (error) {
			handleServiceError(error as ErrorResponse);
		}
		return {} as TResponse;
	}

	async put<TRequest, TResponse>(
		path: string,
		payload: TRequest
	): Promise<TResponse> {
		try {
			const response = await this.client.put<TResponse>(path, payload);
			return response.data;
		} catch (error) {
			handleServiceError(error as ErrorResponse);
		}
		return {} as TResponse;
	}

	async get<TResponse>(
		path: string,
		config?: AxiosRequestConfig
	): Promise<TResponse> {
		try {
			const response = await this.client.get<TResponse>(path, config);
			return response.data;
		} catch (error) {
			handleServiceError(error as ErrorResponse);
		}
		return {} as TResponse;
	}

	async delete<TResponse>(path: string): Promise<TResponse> {
		try {
			const response = await this.client.delete<TResponse>(path);
			return response.data;
		} catch (error) {
			handleServiceError(error as ErrorResponse);
		}
		return {} as TResponse;
	}
}

export const http = new ApiClient();
