import React from 'react';

import { Header } from 'layouts/Header';
import { Layout } from 'layouts/Layout';

import { PurchaseView } from 'features/PurchaseView';

export const PurchaseViewPage: React.FC = () => (
	<Layout header={<Header />} main={<PurchaseView />} />
);
