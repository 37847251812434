import {
	createSlice,
	createEntityAdapter,
	PayloadAction,
} from '@reduxjs/toolkit';

import { RowsPerPageOptions } from 'components/Table';

import {
	User,
	UserPagination,
	UsersTableFilterFields,
	UserAttribute,
} from '../types';

export const usersAdapter = createEntityAdapter<User>();

export const usersSlice = createSlice({
	name: 'users',
	initialState: usersAdapter.getInitialState({
		pagination: {
			currentPage: 1,
			itemsPerPage: RowsPerPageOptions[0],
			totalItems: 1,
		},
		filters: {
			[UsersTableFilterFields.id]: '',
			[UsersTableFilterFields.firstName]: '',
			[UsersTableFilterFields.lastName]: '',
			[UsersTableFilterFields.email]: '',
			[UsersTableFilterFields.partner]: '',
			[UsersTableFilterFields.partnerId]: '',
			[UsersTableFilterFields.phoneNumber]: '',
			[UsersTableFilterFields.primaryContact]: '',
			[UsersTableFilterFields.createdAtStart]: '',
			[UsersTableFilterFields.createdAtEnd]: '',
		},
	}),
	reducers: {
		setUsers: (state, action: PayloadAction<UserAttribute[]>) => {
			const usersData = action.payload.map((attr) => ({
				...attr.attributes,
				id: attr.attributes._id,
			}));
			usersAdapter.setAll(state, usersData);
		},
		removeUser: (state, action: PayloadAction<{ id: string }>) => {
			usersAdapter.removeOne(state, action.payload.id);
		},
		setPagination: (state, action: PayloadAction<UserPagination>) => {
			state.pagination.currentPage = action.payload.currentPage;
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
			state.pagination.totalItems = action.payload.totalItems;
		},

		setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
			state.pagination.currentPage = action.payload.currentPage;
		},
		setItemsPerPage: (
			state,
			action: PayloadAction<{ itemsPerPage: number }>
		) => {
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
		},
		setFilterValue: (
			state,
			action: PayloadAction<{
				filterName: UsersTableFilterFields;
				value: string;
			}>
		) => {
			state.filters[action.payload.filterName] = action.payload.value;
		},
		setPartnerIdFilter: (state, action: PayloadAction<string>) => {
			state.filters.partnerId = action.payload;
		},
	},
});

export const {
	setUsers,
	removeUser,
	setPagination,
	setCurrentPage,
	setItemsPerPage,
	setFilterValue,
	setPartnerIdFilter,
} = usersSlice.actions;

export default usersSlice.reducer;
