import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useFetch } from 'core/hooks';

import {
	OrderValues,
	OrderType,
	HeadCells,
	Pagination,
	TableRowProgress,
} from 'components/Table';
import { http } from 'core/ApiClient';
import { ShowForRole } from 'utils/ShowForRole';

import { Error } from 'core/types';
import { Routes, ROLE } from 'types/types';

import { claimsTableHeadCells } from '../constants';
import { ListPaginationConfig } from 'core/constants';
import {
	ClaimAttribute,
	ClaimsListAPI,
	ClaimsTableFilterFieldsAPI,
	ClaimsTableFilterFields,
} from '../types';
import { selectPagination, selectFilterValues } from '../selectors';
import { selectLocale } from 'features/LanguageSelector';
import { ApiErrorsSnackbar, resolveApiErrors } from 'features/ApiErrors';
import {
	setCurrentPage,
	setItemsPerPage,
	setPagination,
	setFilterValue,
} from '../reducer/claimsReducer';
import { DateFormats } from 'components/FormFields';
import { ClaimsMoreActions } from './ClaimsMoreActions';
import { FiltersRow, FilterType } from 'components/Filters';
import { getFetchParams } from 'utils/helpers';
import { generateFilters } from '../helpers';

export const ClaimsTable: React.FC = () => {
	const [order, setOrder] = useState<OrderType>(OrderValues.Asc);
	const [orderBy, setOrderBy] = useState<string>('');
	const [apiError, setApiError] = useState<Record<string, string> | null>();
	const [loading, setLoading] = useState<boolean>(false);
	const [isOpenSnack, setIsOpenSnack] = useState<boolean>(false);
	const [data, setData] = useState<ClaimAttribute[] | null>(null);
	const [tableFilters, setTableFilters] = useState<
		FilterType<ClaimsTableFilterFields>[]
	>([]);

	const pagination = useSelector(selectPagination);
	const locale = useSelector(selectLocale);
	const filterValues = useSelector(selectFilterValues);
	const intl = useIntl();

	const dispatch = useDispatch();

	const claims = useFetch<ClaimsListAPI>(
		`${Routes.Claims}${getFetchParams(
			filterValues,
			order,
			orderBy,
			pagination.currentPage,
			pagination.itemsPerPage,
			ClaimsTableFilterFieldsAPI
		)}`,
		ListPaginationConfig
	);

	useEffect(() => {
		const generatedFilters = generateFilters(filterValues, intl);
		setTableFilters(generatedFilters);
	}, [filterValues]);

	useEffect(() => {
		if (claims.data) {
			setData(claims.data.data);
			dispatch(setPagination(claims.data.meta));
		}
	}, [claims.isLoading]);

	const handlePageChange = async (newPageVal: number) => {
		setLoading(true);

		await http
			.get<ClaimsListAPI>(
				`${Routes.Claims}${getFetchParams(
					filterValues,
					order,
					orderBy,
					newPageVal + 1,
					pagination.itemsPerPage,
					ClaimsTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				setData(res.data);
				dispatch(setCurrentPage({ currentPage: newPageVal + 1 }));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRowsPerPage = async (rowsPerP: number) => {
		setLoading(true);
		await http
			.get<ClaimsListAPI>(
				`${Routes.Claims}${getFetchParams(
					filterValues,
					order,
					orderBy,
					1,
					rowsPerP,
					ClaimsTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				setData(res.data);
				dispatch(setItemsPerPage({ itemsPerPage: rowsPerP }));
				dispatch(setCurrentPage({ currentPage: 1 }));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRequestSort = async (property: string) => {
		const isAsc = orderBy === property && order === OrderValues.Asc;
		const orderV = isAsc ? OrderValues.Desc : OrderValues.Asc;
		setOrder(orderV);
		setOrderBy(property);

		setLoading(true);
		await http
			.get<ClaimsListAPI>(
				`${Routes.Claims}${getFetchParams(
					filterValues,
					property,
					orderV,
					pagination.currentPage,
					pagination.itemsPerPage,
					ClaimsTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				setData(res.data);
				dispatch(setPagination(res.meta));
			})
			.catch((e: Error) => {
				setIsOpenSnack(true);
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleFilters = async (name: string, value: string) => {
		if (!name) return;
		setApiError(null);
		setLoading(true);

		dispatch(
			setFilterValue({ filterName: name as ClaimsTableFilterFields, value })
		);

		await http
			.get<ClaimsListAPI>(
				`${Routes.Claims}${getFetchParams(
					{ ...filterValues, [name]: value },
					order,
					orderBy,
					pagination.currentPage,
					pagination.itemsPerPage,
					ClaimsTableFilterFieldsAPI
				)}`,
				ListPaginationConfig
			)
			.then((res) => {
				setData(res.data);
				dispatch(setPagination(res.meta));
			})
			.catch((e: Error) => {
				setApiError(
					resolveApiErrors(e.cause.violations, {}, locale).globalErrors
				);
				setIsOpenSnack(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (!claims.isLoading && claims.error) {
		return (
			<ApiErrorsSnackbar
				messages={claims.error}
				type="error"
				isOpen={!claims.isLoading && !!claims.error}
			/>
		);
	}

	return (
		<ShowForRole roles={[ROLE.Admin, ROLE.User]}>
			{apiError ? (
				<ApiErrorsSnackbar
					messages={apiError}
					type="error"
					isOpen={isOpenSnack}
					handleClose={() => setIsOpenSnack(false)}
				/>
			) : (
				<Box>
					<TableContainer>
						<Table aria-labelledby="claimsTable" size="small">
							<FiltersRow<ClaimsTableFilterFields>
								filters={tableFilters}
								handleFilter={(name, value) => void handleFilters(name, value)}
							/>
							<HeadCells
								headCells={claimsTableHeadCells}
								orderBy={orderBy}
								order={order}
								handleRequestSort={(sortVal) => void handleRequestSort(sortVal)}
							/>
							{claims.isLoading || loading ? (
								<TableRowProgress headCellsCount={8} />
							) : (
								<TableBody>
									{data &&
										data.map((row) => (
											<TableRow
												hover
												tabIndex={-1}
												key={row.attributes._id}
												sx={{ width: '100%' }}
											>
												{/* <TableCell align="center">
													{row.attributes._id}
												</TableCell> */}
												<TableCell align="left">
													{row.attributes.state}
												</TableCell>
												<TableCell align="left">
													{row.attributes.purchaseCode}
												</TableCell>
												<TableCell align="left">
													{row.attributes.destination.departureAirport}
												</TableCell>
												<TableCell align="left">
													{row.attributes.destination.arrivalAirport}
												</TableCell>
												<TableCell align="left">{`${row.attributes.disruption.userReason} / ${row.attributes.disruption.airlineReason}`}</TableCell>
												<TableCell align="left">{`${row.attributes.contact.firstName} ${row.attributes.contact.lastName}`}</TableCell>

												<TableCell align="center">
													{moment(row.attributes.createdAt)
														.format(DateFormats.YYYY_MM_DD_HH_MM)
														.toString()}
												</TableCell>
												<TableCell align="left">
													<ClaimsMoreActions claimId={row.attributes._id} />
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
					<Pagination
						rowsLength={pagination.totalItems}
						page={pagination.currentPage - 1}
						rowsPerPage={pagination.itemsPerPage}
						handleChangePage={(_, newPage) => {
							void handlePageChange(newPage);
						}}
						handleChangeRowsPerPage={(rowsPP) => {
							void handleRowsPerPage(rowsPP);
						}}
					/>
				</Box>
			)}
		</ShowForRole>
	);
};
