import React from 'react';
import { ProgressBox } from 'components/ProgressIndicator';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface Props {
	headCellsCount: number;
}

export const TableRowProgress: React.FC<Props> = ({ headCellsCount }) => {
	const arr = Array.from(Array(headCellsCount));
	return (
		<TableBody>
			<TableRow sx={{ width: '100%' }}>
				{arr.map((a: number, i) => (
					<TableCell key={i} sx={{ width: '100%' }}>
						{Math.floor(arr.length / 2) === i && <ProgressBox />}
					</TableCell>
				))}
			</TableRow>
			<TableRow sx={{ width: '100%' }}>
				<></>
			</TableRow>
		</TableBody>
	);
};
