import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { PageRoutes } from 'types';
import { UserTabs } from './UserTabs';
import { useFetch } from 'core/hooks';
import { Routes } from 'types/types';
import {
	Partner,
	PartnersListAPI,
	setPartnerCompanyNames,
} from 'features/Partners';
import { ProgressBox } from 'components/ProgressIndicator';
import { useFetchUser } from '../hooks';
import { ApiErrorsSnackbar } from 'features/ApiErrors';
import { ListPaginationConfig } from 'core/constants';

export const User: React.FC = () => {
	const { userId } = useParams();
	const dispatch = useDispatch();

	const partners = useFetch<PartnersListAPI>(
		`${Routes.Partners}?groups[]=select&itemsPerPage=10`,
		ListPaginationConfig
	);
	const user = useFetchUser(userId);

	useEffect(() => {
		if (partners.data) {
			dispatch(setPartnerCompanyNames(partners.data.data));
		}
	}, [partners]);

	const isFormHasError =
		(!user.isLoading && !!user.error) ||
		(!partners.isLoading && !!partners.error);
	const errors = { ...(user.error || {}), ...(partners.error || {}) };

	if (partners.isLoading || user.isLoading) return <ProgressBox />;

	return (
		<Box m={2} sx={{ background: 'white' }}>
			<Box p={2}>
				<Link to={`/${PageRoutes.Users}`}>
					<Button startIcon={<ArrowBackIcon fontSize="small" />}>
						<FormattedMessage id="navigation.back.to.users.list" />
					</Button>
				</Link>
			</Box>
			{isFormHasError ? (
				<ApiErrorsSnackbar
					messages={errors}
					type="error"
					isOpen={isFormHasError}
				/>
			) : (
				<UserTabs />
			)}
		</Box>
	);
};
