import {
	createSlice,
	createEntityAdapter,
	PayloadAction,
} from '@reduxjs/toolkit';

import {
	AirportInterface,
	AirportsPagination,
	AirportAttribute,
} from '../types';
import { RowsPerPageOptions } from 'components/Table';

export const airportAdapter = createEntityAdapter<AirportInterface>();

export const airportSlice = createSlice({
	name: 'airport',
	initialState: airportAdapter.getInitialState({
		pagination: {
			currentPage: 1,
			itemsPerPage: RowsPerPageOptions[0],
			totalItems: 1,
		},
	}),
	reducers: {
		setAirports: (state, action: PayloadAction<AirportAttribute[]>) => {
			const airports = action.payload.map((airport: AirportAttribute) => ({
				...airport.attributes,
				id: airport.id,
			}));
			airportAdapter.setAll(state, airports);
		},
		addAirports: (state, action: PayloadAction<AirportAttribute[]>) => {
			const airports = action.payload.map((airport: AirportAttribute) => ({
				...airport.attributes,
				id: airport.id,
			}));
			airportAdapter.addMany(state, airports);
		},
		setPagination: (state, action: PayloadAction<AirportsPagination>) => {
			state.pagination.currentPage = action.payload.currentPage;
			state.pagination.itemsPerPage = action.payload.itemsPerPage;
			state.pagination.totalItems = action.payload.totalItems;
		},
		setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
			state.pagination.currentPage = action.payload.currentPage;
		},
	},
});

export const { setAirports, addAirports, setPagination, setCurrentPage } =
	airportSlice.actions;

export default airportSlice.reducer;
