import React from 'react';
import Box from '@mui/material/Box';

interface Props {
	children: React.ReactElement;
}

export const CenterContainer: React.FC<Props> = ({ children }) => (
	<Box
		display="flex"
		justifyContent="center"
		alignItems="center"
		minHeight="100vh"
	>
		{children}
	</Box>
);
