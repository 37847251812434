import React from 'react';
import { ROLE } from 'types/types';

import { Routes, Route } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ClaimFormPage } from './pages/ClaimFormPage';
import { UsersDashboardPage } from './pages/UsersDashboardPage';
import { InvoicesPage } from './pages/InvoicesPage';
import { PartnersPage } from './pages/PartnersPage';
import { UsersPage } from './pages/UsersPage';
import { PartnerCreateEditPage } from './pages/PartnerCreateEditPage';
import { UserCreateEditPage } from './pages/UserCreateEditPage';
import { PurchasesPage } from './pages/PurchasesPage';
import { PurchaseViewPage } from './pages/PurchaseViewPage';
import { ClaimsPage } from './pages/ClaimsPage';
import { ClaimViewPage } from './pages/ClaimViewPage';
import { MyCompanyPage } from './pages/MyCompanyPage';
import { AdminsDashboardPage } from './pages/AdminDashboardPage';
import { PageRoutes } from './types';
import { PrivateRoute } from './utils/PrivateRoute';

export const AppRouter: React.FC = () => (
	<Routes>
		<Route path={PageRoutes.Login} element={<LoginPage />} />
		<Route path={PageRoutes.ClaimForm} element={<ClaimFormPage />} />

		{/* Private routes */}
		<Route element={<PrivateRoute requiredRoles={[ROLE.Admin]} />}>
			<Route path={PageRoutes.Partners} element={<PartnersPage />} />
			<Route
				path={PageRoutes.PartnerEdit}
				element={<PartnerCreateEditPage />}
			/>
			<Route
				path={PageRoutes.PartnersCreate}
				element={<PartnerCreateEditPage />}
			/>
			<Route path={PageRoutes.Users} element={<UsersPage />} />
			<Route
				path={PageRoutes.AdminDashboard}
				element={<AdminsDashboardPage />}
			/>
		</Route>

		<Route element={<PrivateRoute requiredRoles={[ROLE.Admin, ROLE.User]} />}>
			<Route path={PageRoutes.MyCompany} element={<MyCompanyPage />} />

			<Route path={PageRoutes.Purchases} element={<PurchasesPage />} />
			<Route path={PageRoutes.PurchaseView} element={<PurchaseViewPage />} />
			<Route path={PageRoutes.Claims} element={<ClaimsPage />} />
			<Route path={PageRoutes.ClaimView} element={<ClaimViewPage />} />

			<Route path={PageRoutes.UserCreate} element={<UserCreateEditPage />} />
			<Route path={PageRoutes.UserEdit} element={<UserCreateEditPage />} />
		</Route>

		<Route element={<PrivateRoute strict requiredRoles={[ROLE.User]} />}>
			<Route path={PageRoutes.Dashboard} element={<UsersDashboardPage />} />
		</Route>

		<Route element={<PrivateRoute requiredRoles={[ROLE.Admin, ROLE.User]} />}>
			<Route path={PageRoutes.Invoices} element={<InvoicesPage />} />
		</Route>
		<Route path="*" element={<NotFoundPage />} />
	</Routes>
);
