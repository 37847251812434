import React from 'react';
import Box from '@mui/material/Box';

interface Props {
	children: React.ReactElement;
	p?: number;
}

export const BoxWrapper: React.FC<Props> = ({ children, p = 2 }) => (
	<Box
		sx={{
			width: { md: 750, xs: '100%' },
			p,
			backgroundColor: 'white',
			borderRadius: '8px',
		}}
	>
		{children}
	</Box>
);
