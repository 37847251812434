import React, { useEffect, useState, useRef } from 'react';
import Cookie from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { initGA } from 'utils/ga-utils';

const useStyles = makeStyles({
	dialogBox: {
		maxWidth: '900px',
		backgroundColor: '#ffffff',
		borderRadius: '8px',
		boxShadow: '0 32px 68px rgba(0,0,0,.3)',
		height: 'auto',
		left: '50%',
		maxHeight: 'calc(100% - 16px)',
		overflow: 'hidden',
		position: 'fixed',
		textAlign: 'initial',
		top: '50%',
		transform: 'translate(-50%,-50%)',
		width: 'calc(100% - 16px)',
		zIndex: '999999',
	},
});

export const CookieContainer: React.FC = () => {
	const [visible, setVisible] = useState<boolean>(false);
	const mounted = useRef() as React.MutableRefObject<boolean>;
	const location = useLocation();
	const classes = useStyles();

	const accept = () => {
		Cookie.set('consent', 'true', { sameSite: 'strict', expires: 365 });
		if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
			initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
		}
		setVisible(false);
	};

	const decline = () => {
		Cookie.set('consent', 'false', { sameSite: 'strict', expires: 365 });
		Cookie.remove('_ga');
		Cookie.remove('_gat');
		Cookie.remove('_gid');
		setVisible(false);
	};

	useEffect(() => {
		if (Cookie.get('consent') === 'true') {
			accept();
		}
	}, []);

	useEffect(() => {
		if (!mounted.current) {
			// componentDidMount
			if (Cookie.get('consent') === undefined) {
				setVisible(true);
			}
			mounted.current = true;
		} else {
			if (
				window.location.href.includes('privacy-policy') ||
				window.location.href.includes('site-notice')
			) {
				document.body.style.overflow = 'scroll';
			} else if (Cookie.get('consent') === undefined) {
				setVisible(true);
			}
		}
	}, [location.pathname]);

	if (
		!visible ||
		window.location.href.includes('privacy-policy') ||
		window.location.href.includes('site-notice') ||
		window.location.href.includes('sitemap')
	) {
		return null;
	}

	return (
		<Box className={classes.dialogBox}>
			<Box>
				<Grid container spacing={2} wrap="wrap">
					<Grid item xs={12}>
						<Box m={4}>
							<Typography variant="h6" fontWeight={600}>
								<FormattedMessage id="cookie.consent.dialog.title" />
							</Typography>
							<Typography variant="subtitle1">
								<FormattedMessage id="cookie.consent.dialog.description" />
							</Typography>
						</Box>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Box mx={4} display="flex" justifyContent="center">
							<Button
								fullWidth
								variant="outlined"
								color="secondary"
								onClick={() => decline()}
								sx={{
									maxWidth: '200px',
									marginRight: '10px',
									marginBottom: '10px',
								}}
							>
								<FormattedMessage id="cookie.consent.dialog.deny.cookie" />
							</Button>
							<Button
								fullWidth
								onClick={() => accept()}
								variant="contained"
								sx={{
									maxWidth: '200px',
									marginBottom: '10px',
								}}
							>
								<FormattedMessage id="cookie.consent.dialog.accept.cookie" />
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
