import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { UsersTable } from '../components/UsersTable';
import { PageRoutes } from 'types';

export const UserTableWithToolbar: React.FC = () => {
	const navigate = useNavigate();

	return (
		<Box>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end',
					mb: 2,
				}}
			>
				<Button
					onClick={() => navigate(`/${PageRoutes.UserCreate}`)}
					sx={{ minWidth: 'max-content', height: 'fit-content' }}
					variant="outlined"
				>
					<FormattedMessage id="partner.users.table.add.new.user" />
				</Button>
			</Box>
			<UsersTable />
		</Box>
	);
};
