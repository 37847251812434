import { Option } from 'components/FormFields';
import { PartnerState } from 'features/Partners/types';

export enum TabId {
	General = 0,
	Address = 1,
	Users = 2,
	ApiAccess = 3,
	BankDetails = 4,
	Logs = 5,
}

export interface MyPartnerForm {
	id: string;
	legalEntityName: string;
	legalEntityCode: string;
	vatNumber: string;
	website: string;
	commissionAmount: number;
	commissionType: Option | null;
	createdAt: string;
	state: boolean;
	addressLine1: string;
	addressLine2: string;
	country: Option | null;
	city: string;
	postalCode: string;
	authenticationToken: string;
	bankTitle: string;
	swiftCode: string;
	iban: string;
	bankAddress: string;
	bankCountry: Option | null;
}

export interface CompanyStateInterface {
	myPartner: MyPartner;
	authenticationToken: string;
}

export interface MyPartner {
	id: string;
	address: {
		city: string;
		country: Option | null;
		firstLine: string;
		postalCode: string;
		secondLine: string;
	};
	bankDetails: {
		address: string;
		country: Option | null;
		ibanAccount: string;
		swiftCode: string;
		title: string;
	};
	commission: {
		amount: number;
		type: Option | null;
	};
	company: {
		entityCode: string;
		name: string;
		website: string;
	};
	createdAt: string;
	state: PartnerState;
	vat: {
		number: string;
		exists: boolean;
	};
}

export interface PureMyPartner {
	id: string;
	address: {
		city: string;
		country: string;
		firstLine: string;
		postalCode: string;
		secondLine: string;
	};
	bankDetails: {
		address: string;
		country: string;
		ibanAccount: string;
		swiftCode: string;
		title: string;
	};
	commission: {
		amount: number;
		type: string;
	};
	company: {
		entityCode: string;
		name: string;
		website: string;
	};
	createdAt: string;
	state: PartnerState;
	vat: {
		number: string;
		exists: boolean;
	};
}

export interface ApiMyPartnerState {
	id?: string;
	address: {
		city: string;
		country: string;
		firstLine: string;
		postalCode: string;
		secondLine: string;
	};
	bankDetails: {
		address: string;
		country: string;
		ibanAccount: string;
		swiftCode: string;
		title: string;
	};
	commission: {
		amount: number;
		type: string;
	};
	company: {
		entityCode: string;
		name: string;
		website: string;
	};
	createdAt: string;
	state: PartnerState;
	vat: {
		number: string;
		exists: boolean;
	};
}

export enum MyPartnerStateFields {
	id = 'id',
	legalEntityName = 'legalEntityName',
	legalEntityCode = 'legalEntityCode',
	vatNumber = 'vatNumber',
	website = 'website',
	commissionAmount = 'commissionAmount',
	commissionType = 'commissionType',
	createdAt = 'createdAt',
	state = 'state',
	addressLine1 = 'addressLine1',
	addressLine2 = 'addressLine2',
	country = 'country',
	city = 'city',
	postalCode = 'postalCode',
	authenticationToken = 'authenticationToken',
	bankTitle = 'bankTitle',
	swiftCode = 'swiftCode',
	iban = 'iban',
	bankAddress = 'bankAddress',
	bankCountry = 'bankCountry',
}
