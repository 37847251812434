import React from 'react';
import Box from '@mui/material/Box';

interface Props {
	header: React.ReactElement;
	main: React.ReactElement;
	footer?: React.ReactElement;
}

export const Layout: React.FC<Props> = ({ header, main, footer }) => {
	return (
		<Box display="flex" sx={{ flexDirection: 'column', height: '100vh' }}>
			<Box component="nav">{header}</Box>
			<Box component="main" sx={{ flexGrow: 1 }}>
				{main}
			</Box>
			{footer && <Box component="footer">{footer}</Box>}
		</Box>
	);
};
